import React, { useMemo, useState } from "react";
import styles from "./styles.module.scss";
import Modal from "components/common/Modal";
import { SaleChannel } from "types/saleChannelsTypes";
import CheckboxArray, { CheckboxOption } from "components/common/CheckboxArray";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit: (salesChannelIds: string[], isActive: boolean) => void;
  salesChannels: SaleChannel[];
  type: "activate" | "deactivate";
  isLoading?: boolean;
};

const SalesChannelsModal = ({ active, setActive, onSubmit, ...props }: PropsType) => {
  const { type, salesChannels, isLoading } = props;
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([]);

  const title = type === "activate" ? "Activate Sales Channels" : "Deactivate Sales Channels";

  const handleSubmit = () => {
    onSubmit(selectedChannelIds, type === "activate");
  };

  const options: CheckboxOption[] = useMemo(() => {
    return salesChannels.map(channel => ({
      label: channel.title,
      value: channel.id,
      disabled: channel.type === "arteza" || channel.type === "сhannelAdvisor",
    }));
  }, [salesChannels]);

  return (
    <Modal
      title={title}
      active={active}
      setActive={setActive}
      size="small"
      disabled={!selectedChannelIds.length}
      onSubmit={handleSubmit}
      saveButtonText="Apply"
      saveButtonClassName={styles.saveButton}
      loading={isLoading}
    >
      <div className={styles.modal}>
        <div className={styles.description}>&#9757; Select checkboxes that you want to {type}.</div>

        <div className={styles.title}>Sales Channels</div>

        <CheckboxArray
          className={styles.checkboxes}
          options={options}
          selected={selectedChannelIds}
          onChange={setSelectedChannelIds}
        />
      </div>
    </Modal>
  );
};

export default SalesChannelsModal;

import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as qs from "qs";
import { ReactComponent as SearchIcon } from "../../../assets/icons/shared/search.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/shared/filters.svg";
import { ReactComponent as SlidersIcon } from "../../../assets/icons/shared/sliders.svg";
import ClickAwayListener from "../../../helpers/ClickAwayListener";
import InputText from "../InputText";
import Button from "../Button";
import Filters from "./components/FiltersPanel";
// import FiltersLabels from "./components/Filters/FiltersLabels";
import { FilterComponentProps, FilterType } from "./components/FiltersPanel/types";
import styles from "./styles.module.scss";
import Select, { SelectOption } from "../Select";
import { TableColumnType } from "../../../types/tableColumnType";
import ColumnsModal from "./components/ColumnsModal";
import { ReactComponent as FilterActiveIcon } from "assets/icons/shared/filtersActive.svg";
import classNames from "classnames";

type PropsType = {
  searchParam: string;
  activeFilters: boolean;
  setActiveFilters: React.Dispatch<React.SetStateAction<boolean>>;
  filterParams: any;
  initFilters?: (params: FilterComponentProps) => Array<FilterType>;
  disabled?: boolean;
  sortOptions?: Array<SelectOption>;
  columns?: Array<TableColumnType>;
  onColumnsChanged?: (columns: Array<TableColumnType>) => void;
  searchPlaceholder?: string;
  onColumnsReset?: () => void;
  pageId?: string;
};

const SearchFiltersPanel = ({
  searchParam,
  activeFilters,
  setActiveFilters,
  filterParams,
  initFilters,
  disabled = false,
  ...props
}: PropsType) => {
  const {
    sortOptions,
    columns = [],
    onColumnsChanged = () => undefined,
    onColumnsReset = () => undefined,
    pageId,
  } = props;
  const { searchPlaceholder = "Search SKU, Title, ASIN, UPC" } = props;
  const history = useHistory();
  const [searchQuery, setSearchQuery] = useState<string>(filterParams[searchParam] ?? "");
  const [localFilters, setLocalFilters] = useState(filterParams);
  const [activeColumnsModal, setActiveColumnsModal] = useState(false);

  const params = {
    handleChange: (key: string, value: any) =>
      // we reset the filterId because it is no longer this filter if the user has changed something
      setLocalFilters((prev: any) => ({ ...prev, [key]: value, filterId: "" })),
    getValue: (key: string) => localFilters[key],
  };
  const appliedFilters = initFilters ? initFilters(params) : [];

  const handleChangeSort = (value: string, name: string) => {
    history.replace(
      history.location.pathname + "?" + qs.stringify({ ...filterParams, [name]: value }),
    );
  };

  const handleChangeSearch = (value: string, name: string) => {
    setSearchQuery(value);
    history.replace(
      history.location.pathname + "?" + qs.stringify({ ...filterParams, [name]: value, page: 1 }),
    );
  };

  const handleColumnsModalSubmit = (columns: TableColumnType[]) => {
    setActiveColumnsModal(false);
    onColumnsChanged(columns);
  };

  const handleResetColumnsToDefault = () => {
    setActiveColumnsModal(false);
    onColumnsReset();
  };

  const isFiltersSelected: boolean = useMemo(() => {
    if (!filterParams) {
      return false;
    }
    return !!Object.keys(filterParams).filter(
      key => key !== searchParam && key !== "sort" && key !== "page" && key !== "pageSize",
    ).length;
  }, [filterParams]);

  const onClickAway = (e: any) => {
    const el = e.target as HTMLElement;
    // we prevent closing of filtersPanel when users clicks somewhere in modal window or in datepicker
    const isActive = !!(el.closest("#modal-container") || el.closest(".datePopper"));
    setActiveFilters(prev => prev && isActive);
  };

  return (
    <div className={styles.header}>
      <div className={styles.header__actions}>
        <InputText
          name={searchParam}
          placeholder={searchPlaceholder}
          marginOff
          icon={<SearchIcon className={styles.searchIcon} />}
          size="large"
          value={searchQuery}
          onChange={handleChangeSearch}
          clearButton
          disabled={disabled}
        />
        {sortOptions && (
          <Select
            selected={sortOptions?.find(sort => sort.value === filterParams.sort)?.value}
            options={sortOptions}
            className={styles.sortButton}
            onSelect={value => handleChangeSort(value, "sort")}
            placeholder="Sort"
            size="large"
            disabled={disabled}
          />
        )}
        {columns && !!columns.length && (
          <div>
            <Button
              onClick={() => setActiveColumnsModal(true)}
              icon={<SlidersIcon className={styles.slidersIcon} />}
              className={styles.columnsButton}
            >
              Columns
            </Button>
            <ColumnsModal
              active={activeColumnsModal}
              setActive={setActiveColumnsModal}
              onSubmit={handleColumnsModalSubmit}
              columns={columns}
              onResetToDefault={handleResetColumnsToDefault}
            />
          </div>
        )}
        {appliedFilters && !!appliedFilters.length && (
          <ClickAwayListener onClickAway={onClickAway}>
            <Button
              onClick={() => setActiveFilters(true)}
              icon={
                isFiltersSelected ? (
                  <FilterActiveIcon className={styles.filtersActiveIcon} />
                ) : (
                  <FilterIcon className={styles.filtersIcon} />
                )
              }
              className={classNames(styles.filterButton, {
                [styles.active]: isFiltersSelected,
              })}
              disabled={disabled}
            >
              Filters
            </Button>
            <Filters
              filterParams={filterParams}
              active={activeFilters}
              setActiveFilters={setActiveFilters}
              localFilters={localFilters}
              appliedFilters={appliedFilters}
              setLocalFilters={setLocalFilters}
              setSearchQuery={setSearchQuery}
              pageId={pageId}
            />
          </ClickAwayListener>
        )}
      </div>
      {/*<FiltersLabels appliedFilters={appliedFilters}/>*/}
    </div>
  );
};

export default SearchFiltersPanel;

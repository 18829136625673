import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import Button from "../Button";

type PropsType = {
  onSubmit: () => void;
  onDiscard: () => void;
  active: boolean;
  disabled: boolean;
};

const ChangesBanner = ({ onSubmit, onDiscard, active, disabled }: PropsType) => {
  return (
    <div className={classNames(styles.banner, { [styles.active]: active })}>
      <p>Unsaved changes</p>
      <div className={styles.banner__actions}>
        <Button onClick={onDiscard}>Discard</Button>
        <Button onClick={onSubmit} type="primary" disabled={disabled}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ChangesBanner;

import React, { useEffect, useState } from "react";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import InputText from "components/common/InputText";
import * as Yup from "yup";
import classNames from "classnames";

type PropsType = {
  params: FilterComponentProps;
  title?: string;
  nameFilterFrom: string;
  nameFilterTo: string;
  disabled?: boolean;
  className?: string;
};

const RangeFilter = (props: PropsType) => {
  const { params, title, nameFilterFrom, nameFilterTo, disabled, className } = props;
  const valueFrom = params.getValue(nameFilterFrom) ?? "";
  const valueTo = params.getValue(nameFilterTo) ?? "";

  const [errValueFrom, setErrValueFrom] = useState("");
  const [errValueTo, setErrValueTo] = useState("");

  useEffect(() => {
    const valueFromSchema = Yup.number()
      .min(0)
      .max(valueTo ? Number(valueTo) : Infinity);
    const valueToSchema = Yup.number().min(valueFrom ? Number(valueFrom) : 0);
    if (valueFrom) {
      valueFromSchema
        .validate(Number(valueFrom))
        .then(() => {
          setErrValueFrom("");
        })
        .catch(err => {
          setErrValueFrom(err.errors[0]);
        });
    }
    if (valueTo) {
      valueToSchema
        .validate(Number(valueTo))
        .then(() => {
          setErrValueTo("");
        })
        .catch(err => {
          setErrValueTo(err.errors[0]);
        });
    }
  }, [valueFrom, valueTo]);

  return (
    <div className={classNames(styles.rangeFilter, className)}>
      {title && <p className={styles.rangeFilter__title}>{title}</p>}
      <div className={styles.rangeFilter__wrapper}>
        <div className={styles.rangeFilter__fieldBlock}>
          <span className={styles.rangeFilter__label}>from</span>
          <InputText
            className={styles.rangeFilter__field}
            name={nameFilterFrom}
            type="number"
            value={valueFrom}
            onChange={value => params.handleChange(nameFilterFrom, value)}
            error={errValueFrom}
            disabled={disabled}
          />
        </div>
        <div className={styles.rangeFilter__fieldBlock}>
          <span className={styles.rangeFilter__label}>to</span>
          <InputText
            className={styles.rangeFilter__field}
            name={nameFilterTo}
            type="number"
            value={valueTo}
            onChange={value => params.handleChange(nameFilterTo, value)}
            error={errValueTo}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default RangeFilter;

import styles from "../styles.module.scss";
import React, { useState } from "react";
import { SidebarLinkType } from "../index";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "assets/icons/shared/arrowDown.svg";

type PropsType = {
  item: SidebarLinkType;
  sidebarCollapsed?: boolean;
};

const ItemWithChildren = ({ item, sidebarCollapsed }: PropsType) => {
  const [opened, setOpened] = useState(false);

  const onParentNavClick = (e: any) => {
    e.preventDefault();
    if (sidebarCollapsed) {
      return;
    }
    setOpened(prev => !prev);
  };

  const navChildrenWrapperClass = classNames(
    styles.navChildrenWrapper,
    sidebarCollapsed && styles.floating,
    opened && !sidebarCollapsed && styles.opened,
  );
  return (
    <div className={classNames(styles.navBlock)}>
      <NavLink
        className={classNames(styles.navItem, styles.parentNavItem)}
        to={item.url}
        activeClassName={styles.active}
        onClick={onParentNavClick}
      >
        <item.icon className={styles.navItem__icon} />
        {!sidebarCollapsed && <span className={styles.navItem__text}>{item.title}</span>}
        <ArrowIcon
          className={classNames(
            styles.chevron,
            opened && styles.expanded,
            sidebarCollapsed && styles.right,
          )}
        />
      </NavLink>

      <div className={navChildrenWrapperClass}>
        {item.children?.map(child => (
          <NavLink
            key={child.url}
            className={styles.navItem}
            to={child.url}
            activeClassName={styles.active}
          >
            <span className={styles.navItem__text}>{child.title}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default ItemWithChildren;

import React from "react";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import RangeFilter from "components/common/SearchFiltersPanel/components/Filters/RangeFilter";
import CheckboxArray from "components/common/CheckboxArray";

type PropsType = {
  params: FilterComponentProps;
};

const providers = [
  { label: "Amazon US", value: "amazonUS" },
  { label: "Amazon UK", value: "amazonUK" },
  { label: "Amazon EU", value: "amazonEU" },
  { label: "Amazon CA", value: "amazonCA" },
  { label: "Amazon MX", value: "amazonMX" },
  { label: "Deliverr", value: "deliverr" },
  { label: "CloudFulfillment", value: "cloudfulfillment" },
  { label: "GPW", value: "gpw" },
  { label: "CKW", value: "ckw" },
];

const paramName = "inventory.providers";

const InventoryFilter = ({ params }: PropsType) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Inventory Channels</p>

      <CheckboxArray
        selected={params.getValue(paramName) ?? []}
        options={providers}
        onChange={selected => params.handleChange(paramName, selected)}
        className={styles.providers}
        checkboxClassName={styles.checkbox}
      />

      <p className={styles.title}>Inventory Quantity</p>

      <RangeFilter
        params={params}
        nameFilterFrom="inventory.inventoryMin"
        nameFilterTo="inventory.inventoryMax"
        disabled={!params.getValue(paramName)}
        className={styles.range}
      />
    </div>
  );
};

export default InventoryFilter;

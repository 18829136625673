import React from "react";
import Select, { SelectOption, SelectPropsType } from "components/common/Select";
import { CategoryLevel } from "types/categoryLevelTypes";
import { Product } from "types/catalogTypes";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { LineFieldType } from "../../../../types";

type PropsType = {
  lineField: LineFieldType;
  categoryLevels: Array<CategoryLevel>;
  product: Product;
  lineFieldName: string;
  onChange: (value: any, name: string) => void;
  value: any;
};

type SelectProps = PropsType &
  Omit<SelectPropsType, "options" | "onSelect" | "selected" | "disabled">;

const CategorySelect = ({
  lineField,
  categoryLevels,
  product,
  lineFieldName,
  onChange,
  value,
  ...props
}: SelectProps) => {
  const { className, fullWidth = true } = props;

  const getChildCategoryLevel = (
    parent: Array<CategoryLevel>,
    parentValue: string,
    childValue?: string | null,
  ): Array<SelectOption> => {
    const parentCat = parent.find(cat => cat.id === parentValue);
    if (childValue) {
      const childCat = parentCat && parentCat.items?.find(cat => cat.id === childValue);
      return childCat && childCat.items
        ? childCat.items.map(categoryLevel => ({
            label: categoryLevel.title,
            value: categoryLevel.id,
          }))
        : [];
    } else {
      return parentCat && parentCat.items
        ? parentCat.items.map(categoryLevel => ({
            label: categoryLevel.title,
            value: categoryLevel.id,
          }))
        : [];
    }
  };

  const catLevelTwo: Array<SelectOption> = getChildCategoryLevel(
    categoryLevels,
    product.categoryLevelId1,
  );
  const catLevelThree: Array<SelectOption> = product.categoryLevelId2
    ? getChildCategoryLevel(categoryLevels, product.categoryLevelId1, product.categoryLevelId2)
    : [];

  const getSelectOptionByFieldName = (name: string): Array<SelectOption> => {
    const options = {
      categoryLevel1: [
        { label: "Select", value: "0" },
        ...categoryLevels.map(categoryLevel => ({
          label: categoryLevel.title,
          value: categoryLevel.id,
        })),
      ],
      categoryLevel2: [{ label: "Select", value: "0" }, ...catLevelTwo],
      categoryLevel3: [{ label: "Select", value: "0" }, ...catLevelThree],
    };
    return options[name as keyof typeof options];
  };

  return (
    <Select
      {...props}
      options={getSelectOptionByFieldName(lineFieldName)}
      onSelect={value => onChange(value, lineField.formName)}
      selected={value !== "0" ? value : undefined}
      disabled={lineField.disabled || lineField.isReadOnly}
      fullWidth={fullWidth}
      className={classNames(styles.categorySelect, className)}
    />
  );
};

export default CategorySelect;

import React, { useState } from "react";
import styles from "./styles.module.scss";
import Modal from "components/common/Modal";
import Select, { SelectOption } from "components/common/Select";
import Button from "components/common/Button";
import { ReactComponent as DownloadIcon } from "assets/icons/shared/import.svg";
import { ReactComponent as InfoIcon } from "assets/icons/shared/info.svg";
import Uploader from "components/common/Uploader";
import UploadedFile from "components/common/Uploader/UploadedFile";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit: (file: File, type: string, methods: SubmitImportMethods) => void;
  onTemplateDownload: (type: string) => void;
  templateOptions: SelectOption[];
  defaultTemplate?: string;
};

export type SubmitImportMethods = {
  setIsLoading: (v: boolean) => void;
  setValidationErrors: (errors: ValidationErrors) => void;
  setProcessingError: (error: string) => void;
};

type ValidationErrors = Record<number, Record<string, string>>;

const ImportModal = ({ active, setActive, onSubmit, ...props }: PropsType) => {
  const { onTemplateDownload, templateOptions, defaultTemplate = "" } = props;
  const [selectedTemplate, setSelectedTemplate] = useState(defaultTemplate);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors | null>(null);
  const [processingError, setProcessingError] = useState("");

  const onUpload = (files: File[]) => {
    setUploadedFile(files[0]);
  };

  const onDelete = () => {
    setUploadedFile(null);
    setProcessingError("");
    setValidationErrors(null);
  };

  const handleSubmit = () => {
    if (uploadedFile) {
      onSubmit(uploadedFile, selectedTemplate, {
        setIsLoading,
        setValidationErrors,
        setProcessingError,
      });
    }
  };

  return (
    <Modal
      title="Import"
      active={active}
      setActive={setActive}
      size="small"
      onSubmit={handleSubmit}
      saveButtonText="Import .csv"
      disabled={!uploadedFile || !selectedTemplate}
      secondaryButton={<></>}
      loading={isLoading}
      saveButtonClassName={styles.saveButton}
    >
      <div className={styles.modal}>
        <span className={styles.modal__description}>
          Select the Import group to generate and upload the template.
        </span>

        <div className={styles.modal__content}>
          <div className={styles.template}>
            <span className={styles.template__title}>Template</span>
            <div className={styles.template__content}>
              <div className={styles.template__select}>
                <span className={styles.modal__description}>Select Import group</span>
                <Select
                  options={templateOptions}
                  onSelect={setSelectedTemplate}
                  selected={selectedTemplate}
                  size="large"
                  fullWidth
                />
              </div>
              <Button
                onClick={() => onTemplateDownload(selectedTemplate)}
                disabled={!selectedTemplate}
                icon={<DownloadIcon className={styles.template__downloadIcon} />}
                className={styles.template__downloadButton}
                type="primary"
              />
            </div>

            <span className={styles.modal__description}>
              <InfoIcon className={styles.modal__infoIcon} />
              Do not change the template structure.
            </span>
          </div>

          {uploadedFile ? (
            <UploadedFile
              file={uploadedFile}
              onDelete={onDelete}
              error={!!(validationErrors || processingError)}
            />
          ) : (
            <Uploader onUpload={onUpload} acceptFiles=".csv" />
          )}

          {processingError && (
            <div className={styles.processingError}>
              Something went wrong. Error message: {processingError}
            </div>
          )}

          {validationErrors && (
            <div className={styles.validationErrors}>
              <div className={styles.validationErrors__content}>
                {Object.keys(validationErrors).map(key =>
                  Object.keys(validationErrors[Number(key)]).map(field => (
                    <p key={`${key}_${field}`}>
                      Line {Number(key) + 1} {field}: {validationErrors[Number(key)][field]}
                    </p>
                  )),
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ImportModal;

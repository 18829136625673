import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import AppContext from "./components/common/Context";
import { authService } from "./api/auth";
import { UserType } from "./types/authTypes";
import { getDisabledByPermissions } from "./helpers/getDisabledByPermissions";
import TemplateRoute from "./components/layout/TemplateRoute";
import LoginPage from "./pages/LoginPage";
import CatalogPage from "./pages/CatalogPage";
import GlobalLoader from "./components/common/GlobalLoader";
const SettingsPage = React.lazy(() => import("./pages/SettingsPage/index"));
const SettingsCategoriesPage = React.lazy(() => import("./pages/SettingsCategoriesPage/index"));
const SettingsSuppliersPage = React.lazy(() => import("./pages/SettingsSuppliersPage/index"));
const HistoryPage = React.lazy(() => import("./pages/HistoryPage/index"));
const ProductPage = React.lazy(() => import("./pages/ProductPage/index"));
const MasterCostPage = React.lazy(() => import("./pages/MasterCostPage/index"));
const PriceSchedulesPage = React.lazy(() => import("./pages/PriceSchedulesPage/index"));
const PriceSchedulePage = React.lazy(() => import("./pages/PriceSchedulePage/index"));
const SalesChannelFeedsPage = React.lazy(() => import("./pages/SalesChannelFeedsPage/index"));
const SalesChannelFeedPage = React.lazy(() => import("./pages/SalesChannelFeedPage/index"));
const UsersPage = React.lazy(() => import("./pages/PermissionsPage/UsersPage/index"));
const EditUserPage = React.lazy(
  () => import("./pages/PermissionsPage/UsersPage/EditUserPage/index"),
);
const GroupsPage = React.lazy(() => import("./pages/PermissionsPage/GroupsPage/index"));
const EditGroupPage = React.lazy(
  () => import("./pages/PermissionsPage/GroupsPage/EditGroupPage/index"),
);
import styles from "./styles.module.scss";
import Sidebar from "./components/layout/Sidebar";

const App = () => {
  const history = useHistory();
  const [user, setUser] = useState<string | null | UserType>(
    (() => {
      // Remove old user data to force user login via new storefront login API
      // Need to remove this after several month
      const user = localStorage.getItem("user");
      if (user) {
        const userData = JSON.parse(user);
        if (userData.access_token) {
          localStorage.removeItem("user");
        } else {
          return user;
        }
      }
      return null;
    })(),
  );

  const [mounted, setMounted] = useState<boolean>(false);
  const [disabled, setIsDisabled] = useState<boolean>(true);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  useEffect(() => {
    const checkUser = async () => {
      if (!user) {
        history.push("/login");
      } else {
        const permissions = await authService.getPermissions();
        setIsDisabled(getDisabledByPermissions(permissions));
        if (history.location.pathname === "/") {
          history.push("/catalog");
        }
      }
      setMounted(true);
    };
    checkUser();
  }, [user]);

  if (!mounted) {
    return <GlobalLoader />;
  }

  return (
    <AppContext.Provider value={{ disabled, sidebarExpanded, setSidebarExpanded }}>
      <div className={styles.app}>
        <Sidebar />

        <Route exact path="/login" component={() => <LoginPage setUser={setUser} />} />

        <TemplateRoute exact path="/catalog" component={<CatalogPage />} />
        <TemplateRoute
          path="/catalog/products/:id"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <ProductPage />
            </Suspense>
          }
        />
        <TemplateRoute
          path="/master-costs/costs"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <MasterCostPage />
            </Suspense>
          }
        />
        <TemplateRoute
          path="/master-costs/pricing"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <MasterCostPage />
            </Suspense>
          }
        />
        <TemplateRoute
          path="/master-costs/price"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <MasterCostPage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/master-costs/price-schedules/:id"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <PriceSchedulePage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/master-costs/price-schedules"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <PriceSchedulesPage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/sales-channel-feeds/:id"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <SalesChannelFeedPage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/sales-channel-feeds"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <SalesChannelFeedsPage />
            </Suspense>
          }
        />
        <TemplateRoute
          path="/history"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <HistoryPage />
            </Suspense>
          }
        />
        <TemplateRoute
          path="/settings/currency"
          exact
          component={
            <Suspense fallback={<GlobalLoader />}>
              <SettingsPage />
            </Suspense>
          }
        />
        <TemplateRoute
          path="/settings/categories"
          exact
          component={
            <Suspense fallback={<GlobalLoader />}>
              <SettingsCategoriesPage />
            </Suspense>
          }
        />
        <TemplateRoute
          path="/settings/suppliers"
          exact
          component={
            <Suspense fallback={<GlobalLoader />}>
              <SettingsSuppliersPage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/permissions/users/:id"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <EditUserPage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/permissions/users"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <UsersPage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/permissions/groups/:id"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <EditGroupPage />
            </Suspense>
          }
        />
        <TemplateRoute
          exact
          path="/permissions/groups"
          component={
            <Suspense fallback={<GlobalLoader />}>
              <GroupsPage />
            </Suspense>
          }
        />
      </div>
    </AppContext.Provider>
  );
};

const AppContainer = () => (
  <Router>
    <App />
  </Router>
);

export default AppContainer;

import React, { useEffect, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import Modal from "components/common/Modal";
import useDelayedState from "helpers/useDelayedState";
import useDebounce from "helpers/useDebounce";
import { catalogService } from "api/catalog";
import { ProductVariant } from "types/catalogTypes";
import Table from "components/common/Table";
import CheckBox from "components/common/CheckBox";
import InputText from "components/common/InputText";
import { ReactComponent as SearchIcon } from "assets/icons/shared/search.svg";
import { ReactComponent as InfoIcon } from "assets/icons/shared/info.svg";
import styles from "./styles.module.scss";
import Tag from "components/common/Tag";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSelect: (selected: ProductVariant[]) => void;
  selected: ProductVariant[];
  withBundleWarning?: boolean;
};

const ProductVariantsModal = ({ active, setActive, onSelect, ...props }: PropsType) => {
  const { selected, withBundleWarning = true } = props;
  const source: CancelTokenSource = axios.CancelToken.source();
  const [variants, setVariants] = useState<ProductVariant[]>([]);
  const [selectedVariants, setSelectedVariants] = useState<ProductVariant[]>(selected);
  const [delayedLoading, setDelayedLoading] = useDelayedState(false);
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [variantsCount, setVariantsCount] = useState<number>(0);
  const pageSize = 20;
  const debounceQuery = useDebounce(query, 500);

  useEffect(() => {
    const params = { page, pageSize, query: debounceQuery, productTypes: ["simple", "gift_card"] };
    if (page === 1) {
      setVariants([]);
      setPage(1);
    }
    const fetchVariants = async () => {
      try {
        setDelayedLoading(true);
        const data = await catalogService.getVariants(params, {
          token: source.token,
        });
        setVariants(prev => (page === 1 ? data.items : [...prev, ...data.items]));
        setVariantsCount(data.totalCount);
        setDelayedLoading(false);
      } catch (e) {
        setDelayedLoading(false);
        console.log("Fetch variants error: ", e);
      }
    };
    fetchVariants();
  }, [debounceQuery, page]);

  const handleChangeSearch = (value: string) => {
    setPage(1);
    setQuery(value);
  };

  const handleSelectVariant = (value: ProductVariant) => {
    setSelectedVariants(prev =>
      prev.map(item => item.defaultVariant.id).includes(value.defaultVariant.id)
        ? prev.filter(item => item.defaultVariant.id !== value.defaultVariant.id)
        : [...prev, value],
    );
  };

  const handleDeleteVariant = (value: string) => {
    setSelectedVariants(prev => prev.filter(item => item.defaultVariant.id !== value));
  };

  const handleScroll = (inView: any) => {
    if (inView && !!variants.length && !delayedLoading && variantsCount > pageSize * page) {
      setPage(prev => prev + 1);
    }
  };

  const isDisabled = withBundleWarning ? selectedVariants.length < 1 : false;

  useEffect(() => {
    setSelectedVariants(selected);
  }, [selected]);

  return (
    <Modal
      title="Add SKU"
      active={active}
      setActive={setActive}
      size="large"
      onSubmit={() => onSelect(selectedVariants)}
      saveButtonText="Add Products"
      disabled={isDisabled}
    >
      <div className={styles.modal__header}>
        {withBundleWarning && (
          <p className={styles.modal__description}>
            <InfoIcon className={styles.modal__infoIcon} />
            Bundle should have 2 SKUs minimum. Search for the SKU and checkbox it to add to the
            bundle. To remove the SKU chosen you can uncheck or remove the tag
          </p>
        )}
        <InputText
          name="search"
          size="large"
          onChange={value => handleChangeSearch(value)}
          value={query}
          className={styles.modal__searchInput}
          placeholder="Search SKU, Title, ASIN, UPC"
          icon={<SearchIcon className={styles.modal__searchIcon} />}
          clearButton
        />
        <div className={styles.modal__selected}>
          {selectedVariants.map(variant => (
            <Tag
              label={variant.defaultVariant.sku}
              key={variant.defaultVariant.id}
              onClose={() => handleDeleteVariant(variant.defaultVariant.id)}
            />
          ))}
        </div>
      </div>

      <div className={styles.modal__content}>
        <Table
          items={variants}
          fixedHeader
          contentClassName={styles.modal__tableContent}
          onScrollToBottom={handleScroll}
          loading={delayedLoading}
          columns={[
            { name: "", columnWidth: "40px" },
            { name: "SKU", columnWidth: "140px" },
            { name: "Title", columnWidth: "2fr" },
            { name: "Category Level 1", columnWidth: "1fr" },
            { name: "Category Level 2", columnWidth: "1fr" },
            { name: "Category Level 3", columnWidth: "1fr" },
          ]}
          rows={(item: ProductVariant, index: number) => ({
            key: index.toString(),
            fields: [
              <CheckBox
                onChange={value => handleSelectVariant(value)}
                selected={selectedVariants
                  .map(item => item.defaultVariant.id)
                  .includes(item.defaultVariant.id)}
                value={item}
                marginOff
              />,
              item.defaultVariant.sku,
              `${item.product?.title}, ${item.title}`,
              item.product?.categoryLevel1?.title,
              item.product?.categoryLevel2?.title,
              item.product?.categoryLevel3?.title,
            ],
          })}
        />
      </div>
    </Modal>
  );
};

export default ProductVariantsModal;

import classNames from "classnames";
import React, { useMemo } from "react";
import styles from "./styles.module.scss";

type PropsType = {
  options: Array<CheckboxOption>;
  selected: Array<any>;
  onChange: (values: Array<any>) => void;
  className?: any;
  checkboxClassName?: any;
};

export type CheckboxOption = {
  label: React.ReactNode;
  value: any;
  disabled?: boolean;
};

const CheckboxArray = ({
  options,
  selected,
  onChange,
  className,
  checkboxClassName,
}: PropsType) => {
  const localOptions = useMemo(
    () =>
      options.map(option => ({
        ...option,
        selected: selected.includes(option.value),
      })),
    [selected, options],
  );
  const handleChange = (value: any) => {
    const changed = localOptions.map(option => ({
      ...option,
      selected: option.value === value ? !option.selected : option.selected,
    }));
    onChange(changed.filter(option => option.selected).map(option => option.value));
  };

  return (
    <div className={classNames(styles.checkboxes, { [className]: className })}>
      {localOptions.map((option, index) => (
        <button
          key={index}
          onClick={() => handleChange(option.value)}
          disabled={option.disabled}
          className={classNames(
            styles.checkbox,
            { [styles.active]: option.selected },
            { [styles.disabled]: option.disabled },
            { [checkboxClassName]: checkboxClassName },
          )}
        >
          <span className={styles.checkbox__field} />
          <span className={styles.checkbox__text}>{option.label}</span>
        </button>
      ))}
    </div>
  );
};

export default CheckboxArray;

import React, { useState, FC } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface PaginatorProps {
  totalItemsCount: number;
  pageSize: number;
  onPageChanged: (page: number) => void;
  currentPage: number;
  portionSize?: number;
  disabled?: boolean;
}

const Paginator: FC<PaginatorProps> = ({ totalItemsCount, onPageChanged, ...props }) => {
  const { currentPage = 1, portionSize = 5, pageSize, disabled } = props;
  const [portionNumber, setPortionNumber] = useState<number>(Math.ceil(currentPage / portionSize));
  const pagesCount: number = Math.ceil(totalItemsCount / pageSize);
  const pages: Array<number> =
    pagesCount > 1 ? new Array(pagesCount).fill(true).map((v, i) => i + 1) : [];
  const portionCount: number = Math.ceil(pagesCount / portionSize);
  const leftPortionPageNumber: number = (portionNumber - 1) * portionSize + 1;
  const rightPortionPageNumber: number =
    portionNumber * portionSize <= pagesCount ? portionNumber * portionSize : pagesCount;

  return (
    <div className={styles.pagination}>
      {totalItemsCount > pageSize && (
        <>
          <button
            className={styles.pagination__prev}
            disabled={!(portionNumber > 1) || disabled}
            onClick={() => setPortionNumber(portionNumber - 1)}
          />
          {pages
            .filter(p => p >= leftPortionPageNumber && p <= rightPortionPageNumber)
            .map(p => (
              <button
                onClick={() => onPageChanged(p)}
                className={classNames(styles.pagination__btn, {
                  [styles.active]: currentPage === p,
                })}
                key={p}
                disabled={disabled}
              >
                {p}
              </button>
            ))}
          <button
            className={styles.pagination__next}
            disabled={portionNumber >= portionCount || disabled}
            onClick={() => setPortionNumber(portionNumber + 1)}
          />
        </>
      )}
    </div>
  );
};

export default Paginator;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { authService } from "../../api/auth";
import Button from "../../components/common/Button";
import FormInput from "../../components/formik/FormInput";
import { UserType } from "../../types/authTypes";
import styles from "./styles.module.scss";

const validationSchema = Yup.object().shape({
  password: Yup.string().min(6, "Password too short!").required("Password is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

type PropsType = {
  setUser: (user: UserType) => void;
};

const LoginPage = ({ setUser }: PropsType) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const login = async (values: { email: string; password: string }, { setErrors }: any) => {
    setLoading(true);
    try {
      const data = await authService.login(values);
      setUser(data);
      history.push("/");
    } catch (e: any) {
      if (e.response.data && e.response.data.details && e.response.data.details[0].messages) {
        setErrors(e.response.data.details[0].messages);
      }
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={login}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm }) => (
        <div className={styles.login}>
          <div className={styles.login__wrap}>
            <p className={styles.login__title}>Login</p>
            <div className={styles.login__container}>
              <FormInput name="email" label="Email" size="large" />
              <FormInput name="password" label="Password" type="password" size="large" />
              <Button onClick={submitForm} type="primary" size="large" loading={loading}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default LoginPage;

import React from "react";
import styles from "./styles.module.scss";

type PropsType = {
  setProductType: (type: string) => void;
  options: Array<OptionType>;
  onDiscard: () => void;
};

type OptionType = {
  label: string;
  value: string;
  icon: React.ReactNode;
};

const TypeProductActions = ({ setProductType, options, onDiscard }: PropsType) => {
  return (
    <div className={styles.list}>
      {options.map((option, index) => (
        <button
          className={styles.list__item}
          onClick={() => setProductType(option.value)}
          key={index}
        >
          {option.icon}
          {option.label}
        </button>
      ))}
      <button className={styles.list__item} onClick={onDiscard}>
        Discard
      </button>
    </div>
  );
};

export default TypeProductActions;

import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "assets/icons/shared/close.svg";
import { ReactComponent as FileIcon } from "assets/icons/shared/file.svg";
import Button from "../../Button";
import classNames from "classnames";

interface PropsType {
  className?: any;
  file: File;
  onDelete: () => void;
  error?: boolean;
  errorMessage?: string;
}

const UploadedFile = ({ file, className, onDelete, error, errorMessage }: PropsType) => {
  return (
    <>
      <div
        className={classNames(styles.file, {
          [className]: className,
          [styles.error]: error,
        })}
      >
        <div className={styles.file__info}>
          <FileIcon className={styles.file__icon} />
          <span className={styles.file__title}>{file.name}</span>
        </div>
        <Button
          onClick={onDelete}
          type="transparent"
          icon={<CloseIcon className={styles.file__closeIcon} />}
        />
      </div>

      {errorMessage && <div className={styles.file__errorMessage}>{errorMessage}</div>}
    </>
  );
};

export default UploadedFile;

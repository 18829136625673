import classNames from "classnames";
import React from "react";
import styles from "../styles.module.scss";

type PropsType = {
  className?: string;
  children?: any;
  title?: string;
};

const TableCell = ({ className, children, title }: PropsType) => {
  return (
    <div
      className={classNames(styles.item, className)}
      title={title ? title : typeof children == "string" ? children : ""}
    >
      {children}
    </div>
  );
};

export default TableCell;

import { Product } from "types/catalogTypes";
import { TableColumnType } from "types/tableColumnType";
import { LineFieldType, LineType } from "../types";
import moment from "moment";

export const getFieldWidth = (name: string): string => {
  switch (name) {
    case "sku":
      return "130px";
    case "abcClass":
      return "90px";
    case "upc":
    case "asin":
    case "itemFulfillmentType":
    case "coo":
      return "160px";
    case "categoryLevel1":
    case "categoryLevel2":
    case "categoryLevel3":
    case "productGroup":
      return "180px";
    case "title":
      return "420px";
    case "isActive":
      return "120px";
    case "newOld":
      return "114px";
    case "discontinuedEU":
    case "discontinuedNA":
    case "unitCostUS":
    case "unitCostUK":
    case "unitCostEU":
      return "120px";
    case "discontinuedReason":
    case "replacementSku":
      return "140px";
    case "firstSaleDate":
      return "140px";
    case "supplierName":
      return "340px";
    case "paymentTerms":
    case "moq":
      return "140px";
    case "totalTransUS":
    case "totalTransUK":
    case "totalTransEU":
      return "140px";
    case "costTrans":
    case "unitsCarton":
    case "landedCostNsUS":
      return "140px";
    case "cartonLength":
    case "cartonWidth":
    case "cartonHeight":
    case "cartonWeight":
      return "130px";
    case "eachLength":
    case "eachWidth":
    case "eachHeight":
    case "eachWeight":
    case "unitsInner":
      return "130px";
    case "comments":
      return "260px";
    case "fba":
    case "publishDate":
    case "removeFromNewArrivalsDate":
      return "160px";
    case "fees":
    case "msrp":
    case "asp":
    case "map":
      return "140px";
    case "removedUS":
    case "removedUK":
    case "removedEU":
      return "100px";
    case "supplierKey":
      return "120px";
    case "rfCA":
    case "rfMX":
    case "oversized":
      return "100px";
    case "fbaStatusCA":
    case "fbaStatusMX":
      return "160px";
    case "invAmazonUS":
    case "invAmazonCA":
    case "invAmazonMX":
    case "invAmazonEU":
    case "invAmazonUK":
    case "invDeliverr":
    case "invCloudfulfillment":
      return "130px";
    case "gpw":
    case "ckw":
      return "120px";
    case "publishDateUS":
    case "publishDateUK":
    case "publishDateEU":
      return "140px";
    case "removeFromNewArrivalsDateUS":
    case "removeFromNewArrivalsDateUK":
    case "removeFromNewArrivalsDateEU":
      return "160px";
    case "taxRateUS":
    case "taxRateUK":
    case "taxRateEU":
      return "130px";
    case "customDutiesUS":
    case "customDutiesUK":
    case "customDutiesEU":
      return "130px";
    case "dutiesUnitUS":
    case "dutiesUnitUK":
    case "dutiesUnitEU":
      return "130px";
    case "internationalUnitUS":
    case "internationalUnitUK":
    case "internationalUnitEU":
      return "140px";
    case "domesticUnitUS":
    case "domesticUnitUK":
    case "domesticUnitEU":
      return "130px";
    case "totalItemCostUS":
    case "oceanFreightCostInsurance":
    case "dutyCharges":
    case "totalDuty":
    case "otherImportingReceivingHandlingCharges":
    case "domesticTransportationFcs":
    case "totalImportingCost":
    case "totalEstimatedLandedCost":
    case "deltaAmzUsCompetitivePriceToAmzUsPrice":
    case "deltaAmzUsPriceToCaPrice":
    case "deltaAmzUsPriceToMap":
    case "deltaAmzUsSyncPriceToAmzUsPrice":
    case "totalItemCostUK":
    case "totalItemCostEU":
      return "130px";
    case "price":
    case "businessPrice":
    case "syncPrice":
    case "syncCompareAtPrice":
    case "productionLeadTime":
      return "120px";
    case "compareAtPrice":
      return "130px";
    case "competitivePrice":
      return "140px";
    case "holdNA":
    case "holdEU":
    case "holdUK":
      return "60px";
    case "ltmMerchSales":
      return "170px";
    case "cartToDetail30d":
    case "buyToDetail30d":
      return "160px";
    case "pdpViews30d":
      return "140px";
    case "deliverrCostStandard1":
    case "customMargin":
    case "customDiscount":
      return "110px";
    case "recommendedPrice":
    case "marginPrice":
      return "140px";
    case "htsUS":
      return "140px";
    default:
      return "1fr";
  }
};

const formatters = {
  firstSaleDate: (value: string) => {
    return value ? moment(value.replace("Z", "")).format("MM/DD/YYYY") : value;
  },
};

export const getLineFields = (product: Product, fields: Array<TableColumnType>): any => {
  const defaultLine: LineFieldType = {
    productId: product.id,
    type: "input",
    formName: "",
    saleChannelId: "0",
    alignment: "left",
  };
  const defaultBundleLine: LineFieldType = { ...defaultLine, disabled: true };

  const fieldSalesChannels = fields.map(field => field.saleChannelId);

  const defaultVariantSalesChannels = product.defaultVariant
    ? product.defaultVariant.saleChannels.filter(saleChannel =>
        fieldSalesChannels.includes(saleChannel.saleChannelId),
      )
    : [];

  const fieldsObj = getFieldsObj(fields);

  const hasVariants = !!product.variants.length;

  const sharedFields = {
    categoryLevel1: {
      ...defaultLine,
      type: "selectCategoryLevels" as LineType,
      formName: "categoryLevelId1",
      isReadOnly: isReadOnly(fieldsObj, "categoryLevel1"),
    },
    categoryLevel2: {
      ...defaultLine,
      type: "selectCategoryLevels" as LineType,
      formName: "categoryLevelId2",
      isReadOnly: isReadOnly(fieldsObj, "categoryLevel2"),
    },
    categoryLevel3: {
      ...defaultLine,
      type: "selectCategoryLevels" as LineType,
      formName: "categoryLevelId3",
      isReadOnly: isReadOnly(fieldsObj, "categoryLevel3"),
    },
  };

  const productFields = {
    ...sharedFields,
    title: {
      ...defaultLine,
      formName: "title",
      isReadOnly: isReadOnly(fieldsObj, "title"),
    },
    sku: {
      ...defaultLine,
      formName: "defaultVariant.sku",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "sku"),
    },
    productGroup: {
      ...defaultLine,
      formName: "defaultVariant.productGroup",
      disabled: hasVariants,
    },
    abcClass: {
      ...defaultLine,
      formName: "defaultVariant.abcClass",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "abcClass"),
    },
    upc: {
      ...defaultLine,
      formName: "defaultVariant.upc",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "upc"),
    },
    asin: {
      ...defaultLine,
      formName: "defaultVariant.asin",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "asin"),
    },
    holdNA: {
      ...defaultLine,
      type: "holdIconToggle" as LineType,
      formName: "defaultVariant.holdNA",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "holdNA"),
    },
    holdEU: {
      ...defaultLine,
      type: "holdIconToggle" as LineType,
      formName: "defaultVariant.holdEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "holdEU"),
    },
    holdUK: {
      ...defaultLine,
      type: "holdIconToggle" as LineType,
      formName: "defaultVariant.holdUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "holdUK"),
    },
    itemFulfillmentType: {
      ...defaultLine,
      type: "select" as LineType,
      formName: "defaultVariant.itemFulfillmentType",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "itemFulfillmentType"),
    },
    discontinuedNA: {
      ...defaultLine,
      type: "select" as LineType,
      formName: "defaultVariant.discontinuedNA",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "discontinuedNA"),
    },
    discontinuedEU: {
      ...defaultLine,
      type: "select" as LineType,
      formName: "defaultVariant.discontinuedEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "discontinuedEU"),
    },
    discontinuedReason: {
      ...defaultLine,
      formName: "defaultVariant.discontinuedReason",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "discontinuedReason"),
    },
    replacementSku: {
      ...defaultLine,
      formName: "defaultVariant.replacementSku",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "replacementSku"),
    },
    firstSaleDate: {
      ...defaultLine,
      formName: "defaultVariant.firstSaleDate",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "firstSaleDate"),
      formatter: formatters["firstSaleDate"],
    },
    newOld: {
      ...defaultLine,
      formName: "defaultVariant.newOld",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "newOld"),
    },
    supplierKey: {
      ...defaultLine,
      formName: "defaultVariant.supplier.key",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "supplierKey"),
    },
    supplierName: {
      ...defaultLine,
      type: "select" as LineType,
      formName: "defaultVariant.supplierId",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "supplierName"),
    },
    productionLeadTime: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.productionLeadTime",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "productionLeadTime"),
    },
    paymentTerms: {
      ...defaultLine,
      formName: "defaultVariant.paymentTerms",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "paymentTerms"),
    },
    moq: {
      ...defaultLine,
      formName: "defaultVariant.moq",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "moq"),
    },
    htsUS: {
      ...defaultLine,
      formName: "defaultVariant.htsUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "htsUS"),
    },
    coo: {
      ...defaultLine,
      type: "select" as LineType,
      formName: "defaultVariant.coo",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "coo"),
    },
    landedCostNsUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.landedCostNsUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "landedCostNsUS"),
    },
    costTrans: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.costTrans",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "costTrans"),
    },
    unitsCarton: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.unitsCarton",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "unitsCarton"),
    },
    oversized: {
      ...defaultLine,
      type: "select" as LineType,
      formName: "defaultVariant.oversized",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "oversized"),
    },
    cartonLength: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.cartonLength",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "cartonLength"),
    },
    cartonWidth: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.cartonWidth",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "cartonWidth"),
    },
    cartonHeight: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.cartonHeight",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "cartonHeight"),
    },
    cartonWeight: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.cartonWeight",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "cartonWeight"),
    },
    eachLength: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.eachLength",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "eachLength"),
    },
    eachWidth: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.eachWidth",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "eachWidth"),
    },
    eachHeight: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.eachHeight",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "eachHeight"),
    },
    eachWeight: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.eachWeight",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "eachWeight"),
    },
    unitsInner: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.dimensions.unitsInner",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "unitsInner"),
    },
    comments: {
      ...defaultLine,
      formName: "comments",
      isReadOnly: isReadOnly(fieldsObj, "comments"),
    },
    rfCA: {
      ...defaultLine,
      formName: "defaultVariant.rfCA",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "rfCA"),
    },
    rfMX: {
      ...defaultLine,
      formName: "defaultVariant.rfMX",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "rfMX"),
    },
    fbaStatusCA: {
      ...defaultLine,
      formName: "defaultVariant.fbaStatusCA",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "fbaStatusCA"),
    },
    fbaStatusMX: {
      ...defaultLine,
      formName: "defaultVariant.fbaStatusMX",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "fbaStatusMX"),
    },
    invAmazonUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.inventory.invAmazonUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "invAmazonUS"),
    },
    invAmazonCA: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.inventory.invAmazonCA",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "invAmazonCA"),
    },
    invAmazonMX: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.inventory.invAmazonMX",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "invAmazonMX"),
    },
    invAmazonEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.inventory.invAmazonEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "invAmazonEU"),
    },
    invAmazonUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.inventory.invAmazonUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "invAmazonUK"),
    },
    invDeliverr: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.inventory.invDeliverr",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "invDeliverr"),
    },
    invCloudfulfillment: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.inventory.invCloudfulfillment",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "invCloudfulfillment"),
    },
    gpw: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.gpw",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "gpw"),
    },
    ckw: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.ckw",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "ckw"),
    },
    msrp: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.msrp",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "msrp"),
    },
    asp: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.asp",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "asp"),
    },
    map: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.map",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "map"),
    },
    publishDateEU: {
      ...defaultLine,
      type: "datepicker" as LineType,
      formName: "defaultVariant.publishDateEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "publishDateEU"),
    },
    removeFromNewArrivalsDateEU: {
      ...defaultLine,
      type: "datepicker" as LineType,
      formName: "defaultVariant.removeFromNewArrivalsDateEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "removeFromNewArrivalsDateEU"),
    },
    removedEU: {
      ...defaultLine,
      type: "toggle" as LineType,
      formName: "defaultVariant.removedEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "removedEU"),
    },
    unitCostUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.unitCostUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "unitCostUS"),
    },
    taxRateUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.taxRateUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "taxRateUS"),
    },
    customDutiesUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.customDutiesUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "customDutiesUS"),
    },
    dutiesUnitUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.dutiesUnitUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "dutiesUnitUS"),
    },
    internationalUnitUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.internationalUnitUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "internationalUnitUS"),
    },
    domesticUnitUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.domesticUnitUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "domesticUnitUS"),
    },
    totalTransUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalTransUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalTransUS"),
    },
    totalItemCostUS: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalItemCostUS",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalItemCostUS"),
    },
    oceanFreightCostInsurance: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.oceanFreightCostInsurance",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "oceanFreightCostInsurance"),
    },
    dutyCharges: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.dutyCharges",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "dutyCharges"),
    },
    totalDuty: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalDuty",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalDuty"),
    },
    otherImportingReceivingHandlingCharges: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.otherImportingReceivingHandlingCharges",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "otherImportingReceivingHandlingCharges"),
    },
    domesticTransportationFcs: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.domesticTransportationFcs",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "domesticTransportationFcs"),
    },
    totalImportingCost: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalImportingCost",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalImportingCost"),
    },
    totalEstimatedLandedCost: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalEstimatedLandedCost",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalEstimatedLandedCost"),
    },
    deltaAmzUsCompetitivePriceToAmzUsPrice: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.deltaAmzUsCompetitivePriceToAmzUsPrice",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsCompetitivePriceToAmzUsPrice"),
    },
    deltaAmzUsPriceToCaPrice: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.deltaAmzUsPriceToCaPrice",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsPriceToCaPrice"),
    },
    deltaAmzUsPriceToMap: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.deltaAmzUsPriceToMap",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsPriceToMap"),
    },
    deltaAmzUsSyncPriceToAmzUsPrice: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.deltaAmzUsSyncPriceToAmzUsPrice",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsSyncPriceToAmzUsPrice"),
    },
    ltmMerchSales: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.ltmMerchSales",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "ltmMerchSales"),
    },
    pdpViews30d: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.pdpViews30d",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "pdpViews30d"),
    },
    cartToDetail30d: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.cartToDetail30d",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "cartToDetail30d"),
    },
    buyToDetail30d: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.buyToDetail30d",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "buyToDetail30d"),
    },
    deliverrCostStandard1: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.deliverrCostStandard1",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "deliverrCostStandard1"),
    },
    customMargin: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.customMargin",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "customMargin"),
    },
    customDiscount: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.customDiscount",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "customDiscount"),
    },
    unitCostUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.unitCostUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "unitCostUK"),
    },
    taxRateUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.taxRateUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "taxRateUK"),
    },
    customDutiesUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.customDutiesUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "customDutiesUK"),
    },
    dutiesUnitUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.dutiesUnitUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "dutiesUnitUK"),
    },
    internationalUnitUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.internationalUnitUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "internationalUnitUK"),
    },
    domesticUnitUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.domesticUnitUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "domesticUnitUK"),
    },
    totalTransUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalTransUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalTransUK"),
    },
    totalItemCostUK: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalItemCostUK",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalItemCostUK"),
    },
    unitCostEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.unitCostEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "unitCostEU"),
    },
    taxRateEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.taxRateEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "taxRateEU"),
    },
    customDutiesEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.customDutiesEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "customDutiesEU"),
    },
    dutiesUnitEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.dutiesUnitEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "dutiesUnitEU"),
    },
    internationalUnitEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.internationalUnitEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "internationalUnitEU"),
    },
    domesticUnitEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.domesticUnitEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "domesticUnitEU"),
    },
    totalTransEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalTransEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalTransEU"),
    },
    totalItemCostEU: {
      ...defaultLine,
      type: "number" as LineType,
      formName: "defaultVariant.costs.totalItemCostEU",
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "totalItemCostEU"),
    },
    isActive: product.saleChannels
      .filter(saleChannel => fieldSalesChannels.includes(saleChannel.saleChannelId))
      .map(saleChannel => ({
        ...defaultLine,
        type: "toggle" as LineType,
        formName: hasVariants
          ? `saleChannels[${product.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].isActive`
          : `defaultVariant.saleChannels[${
              product.defaultVariant
                ? product.defaultVariant.saleChannels.findIndex(
                    sc => sc.saleChannelId === saleChannel.saleChannelId,
                  )
                : false
            }].isActive`,
        saleChannelId: saleChannel.saleChannelId,
        disabled: hasVariants,
        isReadOnly: isReadOnly(fieldsObj, "isActive", saleChannel.saleChannelId),
      })),
    fba: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].fba`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "fba", saleChannel.saleChannelId),
    })),
    price: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].price`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "price", saleChannel.saleChannelId),
    })),
    businessPrice: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].businessPrice`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "businessPrice", saleChannel.saleChannelId),
    })),
    syncPrice: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].syncPrice`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "syncPrice", saleChannel.saleChannelId),
    })),
    syncCompareAtPrice: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].syncCompareAtPrice`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "syncCompareAtPrice", saleChannel.saleChannelId),
    })),
    compareAtPrice: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].compareAtPrice`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "compareAtPrice", saleChannel.saleChannelId),
    })),
    competitivePrice: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].competitivePrice`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "competitivePrice", saleChannel.saleChannelId),
    })),
    recommendedPrice: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].recommendedPrice`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "recommendedPrice", saleChannel.saleChannelId),
    })),
    marginPrice: defaultVariantSalesChannels.map(saleChannel => ({
      ...defaultLine,
      type: "number" as LineType,
      formName: `defaultVariant.saleChannels[${
        product.defaultVariant
          ? product.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )
          : 0
      }].marginPrice`,
      saleChannelId: saleChannel.saleChannelId,
      disabled: hasVariants,
      isReadOnly: isReadOnly(fieldsObj, "marginPrice", saleChannel.saleChannelId),
    })),
  };

  const variantsFields = product.variants
    ? product.variants.map((variant, indexNumber) => {
        const variantSalesChannels = variant.defaultVariant.saleChannels.filter(saleChannel =>
          fieldSalesChannels.includes(saleChannel.saleChannelId),
        );

        return {
          ...Object.fromEntries(
            Object.keys(sharedFields).map(key => [
              key,
              {
                ...sharedFields[key as keyof typeof sharedFields],
                disabled: true,
              },
            ]),
          ),
          title: {
            ...defaultLine,
            formName: `variants[${indexNumber}].title`,
            isReadOnly: isReadOnly(fieldsObj, "title"),
          },
          sku: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.sku`,
            isReadOnly: isReadOnly(fieldsObj, "sku"),
          },
          productGroup: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.productGroup`,
            isReadOnly: isReadOnly(fieldsObj, "productGroup"),
          },
          abcClass: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.abcClass`,
            isReadOnly: isReadOnly(fieldsObj, "abcClass"),
          },
          upc: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.upc`,
            isReadOnly: isReadOnly(fieldsObj, "upc"),
          },
          asin: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.asin`,
            isReadOnly: isReadOnly(fieldsObj, "asin"),
          },
          holdNA: {
            ...defaultLine,
            type: "holdIconToggle" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.holdNA`,
            isReadOnly: isReadOnly(fieldsObj, "holdNA"),
          },
          holdEU: {
            ...defaultLine,
            type: "holdIconToggle" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.holdEU`,
            isReadOnly: isReadOnly(fieldsObj, "holdEU"),
          },
          holdUK: {
            ...defaultLine,
            type: "holdIconToggle" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.holdUK`,
            isReadOnly: isReadOnly(fieldsObj, "holdUK"),
          },
          itemFulfillmentType: {
            ...defaultLine,
            type: "select" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.itemFulfillmentType`,
            isReadOnly: isReadOnly(fieldsObj, "itemFulfillmentType"),
          },
          discontinuedNA: {
            ...defaultLine,
            type: "select" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.discontinuedNA`,
            isReadOnly: isReadOnly(fieldsObj, "discontinuedNA"),
          },
          discontinuedEU: {
            ...defaultLine,
            type: "select" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.discontinuedEU`,
            isReadOnly: isReadOnly(fieldsObj, "discontinuedEU"),
          },
          discontinuedReason: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.discontinuedReason`,
            isReadOnly: isReadOnly(fieldsObj, "discontinuedReason"),
          },
          replacementSku: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.replacementSku`,
            isReadOnly: isReadOnly(fieldsObj, "replacementSku"),
          },
          firstSaleDate: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.firstSaleDate`,
            isReadOnly: isReadOnly(fieldsObj, "firstSaleDate"),
            formatter: formatters["firstSaleDate"],
          },
          newOld: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.newOld`,
            isReadOnly: isReadOnly(fieldsObj, "newOld"),
          },
          supplierKey: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.supplier.key`,
            isReadOnly: isReadOnly(fieldsObj, "supplierKey"),
          },
          supplierName: {
            ...defaultLine,
            type: "select" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.supplierId`,
            isReadOnly: isReadOnly(fieldsObj, "supplierName"),
          },
          productionLeadTime: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.productionLeadTime`,
            isReadOnly: isReadOnly(fieldsObj, "productionLeadTime"),
          },
          paymentTerms: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.paymentTerms`,
            isReadOnly: isReadOnly(fieldsObj, "paymentTerms"),
          },
          moq: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.moq`,
            isReadOnly: isReadOnly(fieldsObj, "moq"),
          },
          htsUS: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.htsUS`,
            isReadOnly: isReadOnly(fieldsObj, "htsUS"),
          },
          coo: {
            ...defaultLine,
            type: "select" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.coo`,
            isReadOnly: isReadOnly(fieldsObj, "coo"),
          },
          landedCostNsUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.landedCostNsUS`,
            isReadOnly: isReadOnly(fieldsObj, "landedCostNsUS"),
          },
          costTrans: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.costTrans`,
            isReadOnly: isReadOnly(fieldsObj, "costTrans"),
          },
          unitsCarton: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.unitsCarton`,
            isReadOnly: isReadOnly(fieldsObj, "unitsCarton"),
          },
          oversized: {
            ...defaultLine,
            type: "select" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.oversized`,
            isReadOnly: isReadOnly(fieldsObj, "oversized"),
          },
          cartonLength: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.cartonLength`,
            isReadOnly: isReadOnly(fieldsObj, "cartonLength"),
          },
          cartonWidth: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.cartonWidth`,
            isReadOnly: isReadOnly(fieldsObj, "cartonWidth"),
          },
          cartonHeight: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.cartonHeight`,
            isReadOnly: isReadOnly(fieldsObj, "cartonHeight"),
          },
          cartonWeight: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.cartonWeight`,
            isReadOnly: isReadOnly(fieldsObj, "cartonWeight"),
          },
          eachLength: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.eachLength`,
            isReadOnly: isReadOnly(fieldsObj, "eachLength"),
          },
          eachWidth: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.eachWidth`,
            isReadOnly: isReadOnly(fieldsObj, "eachWidth"),
          },
          eachHeight: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.eachHeight`,
            isReadOnly: isReadOnly(fieldsObj, "eachHeight"),
          },
          eachWeight: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.eachWeight`,
            isReadOnly: isReadOnly(fieldsObj, "eachWeight"),
          },
          unitsInner: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.dimensions.unitsInner`,
            isReadOnly: isReadOnly(fieldsObj, "unitsInner"),
          },
          comments: {
            ...defaultLine,
            formName: `variants[${indexNumber}].comments`,
            isReadOnly: isReadOnly(fieldsObj, "comments"),
          },
          rfCA: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.rfCA`,
            isReadOnly: isReadOnly(fieldsObj, "rfCA"),
          },
          rfMX: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.rfMX`,
            isReadOnly: isReadOnly(fieldsObj, "rfMX"),
          },
          fbaStatusCA: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.fbaStatusCA`,
            isReadOnly: isReadOnly(fieldsObj, "fbaStatusCA"),
          },
          fbaStatusMX: {
            ...defaultLine,
            formName: `variants[${indexNumber}].defaultVariant.fbaStatusMX`,
            isReadOnly: isReadOnly(fieldsObj, "fbaStatusMX"),
          },
          invAmazonUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.inventory.invAmazonUS`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonUS"),
          },
          invAmazonCA: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.inventory.invAmazonCA`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonCA"),
          },
          invAmazonMX: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.inventory.invAmazonMX`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonMX"),
          },
          invAmazonEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.inventory.invAmazonEU`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonEU"),
          },
          invAmazonUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.inventory.invAmazonUK`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonUK"),
          },
          invDeliverr: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.inventory.invDeliverr`,
            isReadOnly: isReadOnly(fieldsObj, "invDeliverr"),
          },
          invCloudfulfillment: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.inventory.invCloudfulfillment`,
            isReadOnly: isReadOnly(fieldsObj, "invCloudfulfillment"),
          },
          gpw: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.gpw`,
            isReadOnly: isReadOnly(fieldsObj, "gpw"),
          },
          ckw: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.ckw`,
            isReadOnly: isReadOnly(fieldsObj, "ckw"),
          },
          msrp: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.msrp`,
            isReadOnly: isReadOnly(fieldsObj, "msrp"),
          },
          asp: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.asp`,
            isReadOnly: isReadOnly(fieldsObj, "asp"),
          },
          map: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.map`,
            isReadOnly: isReadOnly(fieldsObj, "map"),
          },
          publishDateEU: {
            ...defaultLine,
            type: "datepicker" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.publishDateEU`,
            isReadOnly: isReadOnly(fieldsObj, "publishDateEU"),
          },
          removeFromNewArrivalsDateEU: {
            ...defaultLine,
            type: "datepicker" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.removeFromNewArrivalsDateEU`,
            isReadOnly: isReadOnly(fieldsObj, "removeFromNewArrivalsDateEU"),
          },
          removedEU: {
            ...defaultLine,
            type: "toggle" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.removedEU`,
            isReadOnly: isReadOnly(fieldsObj, "removedEU"),
          },
          unitCostUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.unitCostUS`,
            isReadOnly: isReadOnly(fieldsObj, "unitCostUS"),
          },
          taxRateUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.taxRateUS`,
            isReadOnly: isReadOnly(fieldsObj, "taxRateUS"),
          },
          customDutiesUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.customDutiesUS`,
            isReadOnly: isReadOnly(fieldsObj, "customDutiesUS"),
          },
          dutiesUnitUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.dutiesUnitUS`,
            isReadOnly: isReadOnly(fieldsObj, "dutiesUnitUS"),
          },
          internationalUnitUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.internationalUnitUS`,
            isReadOnly: isReadOnly(fieldsObj, "internationalUnitUS"),
          },
          domesticUnitUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.domesticUnitUS`,
            isReadOnly: isReadOnly(fieldsObj, "domesticUnitUS"),
          },
          totalTransUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalTransUS`,
            isReadOnly: isReadOnly(fieldsObj, "totalTransUS"),
          },
          totalItemCostUS: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalItemCostUS`,
            isReadOnly: isReadOnly(fieldsObj, "totalItemCostUS"),
          },
          oceanFreightCostInsurance: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.oceanFreightCostInsurance`,
            isReadOnly: isReadOnly(fieldsObj, "oceanFreightCostInsurance"),
          },
          dutyCharges: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.dutyCharges`,
            isReadOnly: isReadOnly(fieldsObj, "dutyCharges"),
          },
          totalDuty: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalDuty`,
            isReadOnly: isReadOnly(fieldsObj, "totalDuty"),
          },
          otherImportingReceivingHandlingCharges: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.otherImportingReceivingHandlingCharges`,
            isReadOnly: isReadOnly(fieldsObj, "otherImportingReceivingHandlingCharges"),
          },
          domesticTransportationFcs: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.domesticTransportationFcs`,
            isReadOnly: isReadOnly(fieldsObj, "domesticTransportationFcs"),
          },
          totalImportingCost: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalImportingCost`,
            isReadOnly: isReadOnly(fieldsObj, "totalImportingCost"),
          },
          totalEstimatedLandedCost: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalEstimatedLandedCost`,
            isReadOnly: isReadOnly(fieldsObj, "totalEstimatedLandedCost"),
          },
          deltaAmzUsCompetitivePriceToAmzUsPrice: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.deltaAmzUsCompetitivePriceToAmzUsPrice`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsCompetitivePriceToAmzUsPrice"),
          },
          deltaAmzUsPriceToCaPrice: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.deltaAmzUsPriceToCaPrice`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsPriceToCaPrice"),
          },
          deltaAmzUsPriceToMap: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.deltaAmzUsPriceToMap`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsPriceToMap"),
          },
          deltaAmzUsSyncPriceToAmzUsPrice: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.deltaAmzUsSyncPriceToAmzUsPrice`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsSyncPriceToAmzUsPrice"),
          },
          ltmMerchSales: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.ltmMerchSales`,
            isReadOnly: isReadOnly(fieldsObj, "ltmMerchSales"),
          },
          pdpViews30d: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.pdpViews30d`,
            isReadOnly: isReadOnly(fieldsObj, "pdpViews30d"),
          },
          cartToDetail30d: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.cartToDetail30d`,
            isReadOnly: isReadOnly(fieldsObj, "cartToDetail30d"),
          },
          buyToDetail30d: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.buyToDetail30d`,
            isReadOnly: isReadOnly(fieldsObj, "buyToDetail30d"),
          },
          deliverrCostStandard1: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.deliverrCostStandard1`,
            isReadOnly: isReadOnly(fieldsObj, "deliverrCostStandard1"),
          },
          customMargin: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.customMargin`,
            isReadOnly: isReadOnly(fieldsObj, "customMargin"),
          },
          customDiscount: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.customDiscount`,
            isReadOnly: isReadOnly(fieldsObj, "customDiscount"),
          },
          unitCostUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.unitCostUK`,
            isReadOnly: isReadOnly(fieldsObj, "unitCostUK"),
          },
          taxRateUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.taxRateUK`,
            isReadOnly: isReadOnly(fieldsObj, "taxRateUK"),
          },
          customDutiesUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.customDutiesUK`,
            isReadOnly: isReadOnly(fieldsObj, "customDutiesUK"),
          },
          dutiesUnitUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.dutiesUnitUK`,
            isReadOnly: isReadOnly(fieldsObj, "dutiesUnitUK"),
          },
          internationalUnitUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.internationalUnitUK`,
            isReadOnly: isReadOnly(fieldsObj, "internationalUnitUK"),
          },
          domesticUnitUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.domesticUnitUK`,
            isReadOnly: isReadOnly(fieldsObj, "domesticUnitUK"),
          },
          totalTransUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalTransUK`,
            isReadOnly: isReadOnly(fieldsObj, "totalTransUK"),
          },
          totalItemCostUK: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalItemCostUK`,
            isReadOnly: isReadOnly(fieldsObj, "totalItemCostUK"),
          },
          unitCostEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.unitCostEU`,
            isReadOnly: isReadOnly(fieldsObj, "unitCostEU"),
          },
          taxRateEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.taxRateEU`,
            isReadOnly: isReadOnly(fieldsObj, "taxRateEU"),
          },
          customDutiesEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.customDutiesEU`,
            isReadOnly: isReadOnly(fieldsObj, "customDutiesEU"),
          },
          dutiesUnitEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.dutiesUnitEU`,
            isReadOnly: isReadOnly(fieldsObj, "dutiesUnitEU"),
          },
          internationalUnitEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.internationalUnitEU`,
            isReadOnly: isReadOnly(fieldsObj, "internationalUnitEU"),
          },
          domesticUnitEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.domesticUnitEU`,
            isReadOnly: isReadOnly(fieldsObj, "domesticUnitEU"),
          },
          totalTransEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalTransEU`,
            isReadOnly: isReadOnly(fieldsObj, "totalTransEU"),
          },
          totalItemCostEU: {
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.costs.totalItemCostEU`,
            isReadOnly: isReadOnly(fieldsObj, "totalItemCostEU"),
          },
          isActive: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "toggle" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].isActive`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "isActive", saleChannel.saleChannelId),
          })),
          fba: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].fba`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "fba", saleChannel.saleChannelId),
          })),
          price: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].price`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "price", saleChannel.saleChannelId),
          })),
          businessPrice: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].businessPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "businessPrice", saleChannel.saleChannelId),
          })),
          syncPrice: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].syncPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "syncPrice", saleChannel.saleChannelId),
          })),
          syncCompareAtPrice: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].syncCompareAtPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "syncCompareAtPrice", saleChannel.saleChannelId),
          })),
          compareAtPrice: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].compareAtPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "compareAtPrice", saleChannel.saleChannelId),
          })),
          competitivePrice: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].competitivePrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "competitivePrice", saleChannel.saleChannelId),
          })),
          recommendedPrice: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].recommendedPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "recommendedPrice", saleChannel.saleChannelId),
          })),
          marginPrice: variantSalesChannels.map(saleChannel => ({
            ...defaultLine,
            type: "number" as LineType,
            formName: `variants[${indexNumber}].defaultVariant.saleChannels[${variant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].marginPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "marginPrice", saleChannel.saleChannelId),
          })),
        };
      })
    : null;

  const bundleFields = product.bundleVariants
    ? product.bundleVariants.map((bundleVariant, indexNumber) => {
        const bundleSalesChannels = bundleVariant.defaultVariant.saleChannels.filter(saleChannel =>
          fieldSalesChannels.includes(saleChannel.saleChannelId),
        );

        return {
          sku: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.sku`,
            isReadOnly: isReadOnly(fieldsObj, "sku"),
          },
          productGroup: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.productGroup`,
            isReadOnly: isReadOnly(fieldsObj, "productGroup"),
          },
          abcClass: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.abcClass`,
            isReadOnly: isReadOnly(fieldsObj, "abcClass"),
          },
          upc: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.upc`,
            isReadOnly: isReadOnly(fieldsObj, "upc"),
          },
          asin: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.asin`,
            isReadOnly: isReadOnly(fieldsObj, "asin"),
          },
          title: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].fullTitle`,
            isReadOnly: isReadOnly(fieldsObj, "title"),
          },
          categoryLevel1: {
            ...defaultBundleLine,
            type: "selectCategoryLevels" as LineType,
            formName: `bundleVariants[${indexNumber}].product.categoryLevelId1`,
            isReadOnly: isReadOnly(fieldsObj, "categoryLevel1"),
          },
          categoryLevel2: {
            ...defaultBundleLine,
            type: "selectCategoryLevels" as LineType,
            formName: `bundleVariants[${indexNumber}].product.categoryLevelId2`,
            isReadOnly: isReadOnly(fieldsObj, "categoryLevel2"),
          },
          categoryLevel3: {
            ...defaultBundleLine,
            type: "selectCategoryLevels" as LineType,
            formName: `bundleVariants[${indexNumber}].product.categoryLevelId3`,
            isReadOnly: isReadOnly(fieldsObj, "categoryLevel3"),
          },
          holdNA: {
            ...defaultBundleLine,
            type: "holdIconToggle" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.holdNA`,
            isReadOnly: isReadOnly(fieldsObj, "holdNA"),
          },
          holdEU: {
            ...defaultBundleLine,
            type: "holdIconToggle" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.holdEU`,
            isReadOnly: isReadOnly(fieldsObj, "holdEU"),
          },
          holdUK: {
            ...defaultBundleLine,
            type: "holdIconToggle" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.holdUK`,
            isReadOnly: isReadOnly(fieldsObj, "holdUK"),
          },
          supplierKey: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.supplier.key`,
            isReadOnly: isReadOnly(fieldsObj, "supplierKey"),
          },
          supplierName: {
            ...defaultBundleLine,
            type: "select" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.supplierId`,
            isReadOnly: isReadOnly(fieldsObj, "supplierName"),
          },
          productionLeadTime: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.productionLeadTime`,
            isReadOnly: isReadOnly(fieldsObj, "productionLeadTime"),
          },
          paymentTerms: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.paymentTerms`,
            isReadOnly: isReadOnly(fieldsObj, "paymentTerms"),
          },
          itemFulfillmentType: {
            ...defaultBundleLine,
            type: "select" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.itemFulfillmentType`,
            isReadOnly: isReadOnly(fieldsObj, "itemFulfillmentType"),
          },
          discontinuedNA: {
            ...defaultBundleLine,
            type: "select" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.discontinuedNA`,
            isReadOnly: isReadOnly(fieldsObj, "discontinuedNA"),
          },
          discontinuedEU: {
            ...defaultBundleLine,
            type: "select" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.discontinuedEU`,
            isReadOnly: isReadOnly(fieldsObj, "discontinuedEU"),
          },
          discontinuedReason: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.discontinuedReason`,
            isReadOnly: isReadOnly(fieldsObj, "discontinuedReason"),
          },
          replacementSku: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.replacementSku`,
            isReadOnly: isReadOnly(fieldsObj, "replacementSku"),
          },
          firstSaleDate: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.firstSaleDate`,
            isReadOnly: isReadOnly(fieldsObj, "firstSaleDate"),
            formatter: formatters["firstSaleDate"],
          },
          newOld: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.newOld`,
            isReadOnly: isReadOnly(fieldsObj, "newOld"),
          },
          moq: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.moq`,
            isReadOnly: isReadOnly(fieldsObj, "moq"),
          },
          htsUS: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.htsUS`,
            isReadOnly: isReadOnly(fieldsObj, "htsUS"),
          },
          coo: {
            ...defaultBundleLine,
            type: "select" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.coo`,
            isReadOnly: isReadOnly(fieldsObj, "coo"),
          },
          landedCostNsUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.landedCostNsUS`,
            isReadOnly: isReadOnly(fieldsObj, "landedCostNsUS"),
          },
          costTrans: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.costTrans`,
            isReadOnly: isReadOnly(fieldsObj, "costTrans"),
          },
          unitsCarton: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.unitsCarton`,
            isReadOnly: isReadOnly(fieldsObj, "unitsCarton"),
          },
          oversized: {
            ...defaultBundleLine,
            type: "select" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.oversized`,
            isReadOnly: isReadOnly(fieldsObj, "oversized"),
          },
          cartonLength: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.cartonLength`,
            isReadOnly: isReadOnly(fieldsObj, "cartonLength"),
          },
          cartonWidth: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.cartonWidth`,
            isReadOnly: isReadOnly(fieldsObj, "cartonWidth"),
          },
          cartonHeight: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.cartonHeight`,
            isReadOnly: isReadOnly(fieldsObj, "cartonHeight"),
          },
          cartonWeight: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.cartonWeight`,
            isReadOnly: isReadOnly(fieldsObj, "cartonWeight"),
          },
          eachLength: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.eachLength`,
            isReadOnly: isReadOnly(fieldsObj, "eachLength"),
          },
          eachWidth: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.eachWidth`,
            isReadOnly: isReadOnly(fieldsObj, "eachWidth"),
          },
          eachHeight: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.eachHeight`,
            isReadOnly: isReadOnly(fieldsObj, "eachHeight"),
          },
          eachWeight: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.eachWeight`,
            isReadOnly: isReadOnly(fieldsObj, "eachWeight"),
          },
          unitsInner: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.dimensions.unitsInner`,
            isReadOnly: isReadOnly(fieldsObj, "unitsInner"),
          },
          comments: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].product.comments`,
            isReadOnly: isReadOnly(fieldsObj, "comments"),
          },
          rfCA: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.rfCA`,
            isReadOnly: isReadOnly(fieldsObj, "rfCA"),
          },
          rfMX: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.rfMX`,
            isReadOnly: isReadOnly(fieldsObj, "rfMX"),
          },
          fbaStatusCA: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.fbaStatusCA`,
            isReadOnly: isReadOnly(fieldsObj, "fbaStatusCA"),
          },
          fbaStatusMX: {
            ...defaultBundleLine,
            formName: `bundleVariants[${indexNumber}].defaultVariant.fbaStatusMX`,
            isReadOnly: isReadOnly(fieldsObj, "fbaStatusMX"),
          },
          invAmazonUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.inventory.invAmazonUS`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonUS"),
          },
          invAmazonCA: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.inventory.invAmazonCA`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonCA"),
          },
          invAmazonMX: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.inventory.invAmazonMX`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonMX"),
          },
          invAmazonEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.inventory.invAmazonEU`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonEU"),
          },
          invAmazonUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.inventory.invAmazonUK`,
            isReadOnly: isReadOnly(fieldsObj, "invAmazonUK"),
          },
          invDeliverr: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.inventory.invDeliverr`,
            isReadOnly: isReadOnly(fieldsObj, "invDeliverr"),
          },
          invCloudfulfillment: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.inventory.invCloudfulfillment`,
            isReadOnly: isReadOnly(fieldsObj, "invCloudfulfillment"),
          },
          gpw: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.gpw`,
            isReadOnly: isReadOnly(fieldsObj, "gpw"),
          },
          ckw: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.ckw`,
            isReadOnly: isReadOnly(fieldsObj, "ckw"),
          },
          msrp: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.msrp`,
            isReadOnly: isReadOnly(fieldsObj, "msrp"),
          },
          asp: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.asp`,
            isReadOnly: isReadOnly(fieldsObj, "asp"),
          },
          map: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.map`,
            isReadOnly: isReadOnly(fieldsObj, "map"),
          },
          publishDateEU: {
            ...defaultBundleLine,
            type: "datepicker" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.publishDateEU`,
            isReadOnly: isReadOnly(fieldsObj, "publishDateEU"),
          },
          removeFromNewArrivalsDateEU: {
            ...defaultBundleLine,
            type: "datepicker" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.removeFromNewArrivalsDateEU`,
            isReadOnly: isReadOnly(fieldsObj, "removeFromNewArrivalsDateEU"),
          },
          removedEU: {
            ...defaultBundleLine,
            type: "toggle" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.removedEU`,
            isReadOnly: isReadOnly(fieldsObj, "removedEU"),
          },
          unitCostUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.unitCostUS`,
            isReadOnly: isReadOnly(fieldsObj, "unitCostUS"),
          },
          taxRateUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.taxRateUS`,
            isReadOnly: isReadOnly(fieldsObj, "taxRateUS"),
          },
          customDutiesUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.customDutiesUS`,
            isReadOnly: isReadOnly(fieldsObj, "customDutiesUS"),
          },
          dutiesUnitUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.dutiesUnitUS`,
            isReadOnly: isReadOnly(fieldsObj, "dutiesUnitUS"),
          },
          internationalUnitUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.internationalUnitUS`,
            isReadOnly: isReadOnly(fieldsObj, "internationalUnitUS"),
          },
          domesticUnitUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.domesticUnitUS`,
            isReadOnly: isReadOnly(fieldsObj, "domesticUnitUS"),
          },
          totalTransUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalTransUS`,
            isReadOnly: isReadOnly(fieldsObj, "totalTransUS"),
          },
          totalItemCostUS: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalItemCostUS`,
            isReadOnly: isReadOnly(fieldsObj, "totalItemCostUS"),
          },
          oceanFreightCostInsurance: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.oceanFreightCostInsurance`,
            isReadOnly: isReadOnly(fieldsObj, "oceanFreightCostInsurance"),
          },
          dutyCharges: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.dutyCharges`,
            isReadOnly: isReadOnly(fieldsObj, "dutyCharges"),
          },
          totalDuty: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalDuty`,
            isReadOnly: isReadOnly(fieldsObj, "totalDuty"),
          },
          otherImportingReceivingHandlingCharges: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.otherImportingReceivingHandlingCharges`,
            isReadOnly: isReadOnly(fieldsObj, "otherImportingReceivingHandlingCharges"),
          },
          domesticTransportationFcs: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.domesticTransportationFcs`,
            isReadOnly: isReadOnly(fieldsObj, "domesticTransportationFcs"),
          },
          totalImportingCost: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalImportingCost`,
            isReadOnly: isReadOnly(fieldsObj, "totalImportingCost"),
          },
          totalEstimatedLandedCost: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalEstimatedLandedCost`,
            isReadOnly: isReadOnly(fieldsObj, "totalEstimatedLandedCost"),
          },
          deltaAmzUsCompetitivePriceToAmzUsPrice: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.deltaAmzUsCompetitivePriceToAmzUsPrice`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsCompetitivePriceToAmzUsPrice"),
          },
          deltaAmzUsPriceToCaPrice: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.deltaAmzUsPriceToCaPrice`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsPriceToCaPrice"),
          },
          deltaAmzUsPriceToMap: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.deltaAmzUsPriceToMap`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsPriceToMap"),
          },
          deltaAmzUsSyncPriceToAmzUsPrice: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.deltaAmzUsSyncPriceToAmzUsPrice`,
            isReadOnly: isReadOnly(fieldsObj, "deltaAmzUsSyncPriceToAmzUsPrice"),
          },
          ltmMerchSales: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.ltmMerchSales`,
            isReadOnly: isReadOnly(fieldsObj, "ltmMerchSales"),
          },
          pdpViews30d: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.pdpViews30d`,
            isReadOnly: isReadOnly(fieldsObj, "pdpViews30d"),
          },
          cartToDetail30d: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.cartToDetail30d`,
            isReadOnly: isReadOnly(fieldsObj, "cartToDetail30d"),
          },
          buyToDetail30d: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.buyToDetail30d`,
            isReadOnly: isReadOnly(fieldsObj, "buyToDetail30d"),
          },
          deliverrCostStandard1: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.deliverrCostStandard1`,
            isReadOnly: isReadOnly(fieldsObj, "deliverrCostStandard1"),
          },
          customMargin: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.customMargin`,
            isReadOnly: isReadOnly(fieldsObj, "customMargin"),
          },
          customDiscount: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.customDiscount`,
            isReadOnly: isReadOnly(fieldsObj, "customDiscount"),
          },
          unitCostUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.unitCostUK`,
            isReadOnly: isReadOnly(fieldsObj, "unitCostUK"),
          },
          taxRateUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.taxRateUK`,
            isReadOnly: isReadOnly(fieldsObj, "taxRateUK"),
          },
          customDutiesUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.customDutiesUK`,
            isReadOnly: isReadOnly(fieldsObj, "customDutiesUK"),
          },
          dutiesUnitUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.dutiesUnitUK`,
            isReadOnly: isReadOnly(fieldsObj, "dutiesUnitUK"),
          },
          internationalUnitUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.internationalUnitUK`,
            isReadOnly: isReadOnly(fieldsObj, "internationalUnitUK"),
          },
          domesticUnitUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.domesticUnitUK`,
            isReadOnly: isReadOnly(fieldsObj, "domesticUnitUK"),
          },
          totalTransUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalTransUK`,
            isReadOnly: isReadOnly(fieldsObj, "totalTransUK"),
          },
          totalItemCostUK: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalItemCostUK`,
            isReadOnly: isReadOnly(fieldsObj, "totalItemCostUK"),
          },
          unitCostEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.unitCostEU`,
            isReadOnly: isReadOnly(fieldsObj, "unitCostEU"),
          },
          taxRateEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.taxRateEU`,
            isReadOnly: isReadOnly(fieldsObj, "taxRateEU"),
          },
          customDutiesEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.customDutiesEU`,
            isReadOnly: isReadOnly(fieldsObj, "customDutiesEU"),
          },
          dutiesUnitEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.dutiesUnitEU`,
            isReadOnly: isReadOnly(fieldsObj, "dutiesUnitEU"),
          },
          internationalUnitEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.internationalUnitEU`,
            isReadOnly: isReadOnly(fieldsObj, "internationalUnitEU"),
          },
          domesticUnitEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.domesticUnitEU`,
            isReadOnly: isReadOnly(fieldsObj, "domesticUnitEU"),
          },
          totalTransEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalTransEU`,
            isReadOnly: isReadOnly(fieldsObj, "totalTransEU"),
          },
          totalItemCostEU: {
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.costs.totalItemCostEU`,
            isReadOnly: isReadOnly(fieldsObj, "totalItemCostEU"),
          },
          isActive: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "toggle" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].isActive`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "isActive", saleChannel.saleChannelId),
          })),
          fba: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].fba`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "fba", saleChannel.saleChannelId),
          })),
          price: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].price`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "price", saleChannel.saleChannelId),
          })),
          businessPrice: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].businessPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "businessPrice", saleChannel.saleChannelId),
          })),
          syncPrice: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].syncPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "syncPrice", saleChannel.saleChannelId),
          })),
          syncCompareAtPrice: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].syncCompareAtPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "syncCompareAtPrice", saleChannel.saleChannelId),
          })),
          compareAtPrice: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].compareAtPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "compareAtPrice", saleChannel.saleChannelId),
          })),
          competitivePrice: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].competitivePrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "competitivePrice", saleChannel.saleChannelId),
          })),
          recommendedPrice: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].recommendedPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "recommendedPrice", saleChannel.saleChannelId),
          })),
          marginPrice: bundleSalesChannels.map(saleChannel => ({
            ...defaultBundleLine,
            type: "number" as LineType,
            formName: `bundleVariants[${indexNumber}].defaultVariant.saleChannels[${bundleVariant.defaultVariant.saleChannels.findIndex(
              sc => sc.saleChannelId === saleChannel.saleChannelId,
            )}].marginPrice`,
            saleChannelId: saleChannel.saleChannelId,
            isReadOnly: isReadOnly(fieldsObj, "marginPrice", saleChannel.saleChannelId),
          })),
        };
      })
    : null;

  return {
    product: productFields,
    variants: variantsFields,
    bundleVariants: bundleFields,
  };
};

export const getLineFieldByName = (
  productFormFields: any,
  field: TableColumnType,
  index?: number,
  type?: "variant" | "bundle",
): LineFieldType | undefined => {
  return index !== undefined && type === "variant"
    ? Array.isArray(productFormFields.variants[index][field.name])
      ? productFormFields.variants[index][field.name].find(
          (lineField: LineFieldType) => lineField.saleChannelId === field.saleChannelId,
        )
      : productFormFields.variants[index][field.name]
    : index !== undefined && type === "bundle"
    ? Array.isArray(productFormFields.bundleVariants[index][field.name])
      ? productFormFields.bundleVariants[index][field.name].find(
          (lineField: LineFieldType) => lineField.saleChannelId === field.saleChannelId,
        )
      : productFormFields.bundleVariants[index][field.name]
    : Array.isArray(productFormFields.product[field.name])
    ? productFormFields.product[field.name].find(
        (lineField: LineFieldType) => lineField.saleChannelId === field.saleChannelId,
      )
    : productFormFields.product[field.name];
};

const getFieldsObj = (fields: Array<TableColumnType>) => {
  const fieldsObj: any = {};
  fields.forEach(field => {
    if (!fieldsObj[field.name]) {
      fieldsObj[field.name] = field;
    } else {
      if (Array.isArray(fieldsObj[field.name])) {
        fieldsObj[field.name].push(field);
      } else {
        fieldsObj[field.name] = [fieldsObj[field.name], field];
      }
    }
  });
  return fieldsObj;
};

const isReadOnly = (
  fieldsObj: any,
  fieldName: string,
  saleChannelId: string | undefined = undefined,
) => {
  if (!fieldsObj[fieldName]) {
    return false;
  }
  if (!Array.isArray(fieldsObj[fieldName])) {
    return fieldsObj[fieldName].isDisable;
  }

  const field = fieldsObj[fieldName].find(
    (field: TableColumnType) => field.saleChannelId === saleChannelId,
  );
  if (!field) {
    return false;
  }
  return field.isDisable;
};

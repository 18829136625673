import classNames from "classnames";
import React from "react";
import styles from "../styles.module.scss";
import SpinnerLoader from "../../SpinnerLoader";
import { DragDropContext, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { InView } from "react-intersection-observer";

type PropsType = {
  onScrollToBottom?: (inView: boolean) => void;
  loading?: boolean;
  className?: string;
  draggable?: boolean;
  onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
  children?: React.ReactNode;
};

const TableBody = (props: PropsType) => {
  const {
    onDragEnd = () => undefined,
    draggable = false,
    onScrollToBottom,
    loading,
    className,
    children,
  } = props;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppableTable" isDropDisabled={!draggable}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classNames(styles.rows, className)}
          >
            {children}

            {onScrollToBottom && (
              <InView as="div" onChange={onScrollToBottom} threshold={[0.1]}>
                <span />
              </InView>
            )}
            {loading && <SpinnerLoader />}

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TableBody;

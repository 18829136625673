import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

type PropsType = {
  fixedHeader?: boolean;
  horizontalScroll?: boolean;
  children?: React.ReactNode;
};

const DataTable = (props: PropsType) => {
  const { horizontalScroll, fixedHeader, children } = props;

  return (
    <div
      className={classNames(styles.table, {
        [styles.fixed]: fixedHeader,
        [styles.horizontalScroll]: horizontalScroll,
      })}
    >
      <div className={styles.content}>
        <div className={styles.wrapper}>{children}</div>
      </div>
    </div>
  );
};

export default DataTable;

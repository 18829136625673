import { CategoryLevel } from "../../../../types/categoryLevelTypes";
import { CheckboxOption } from "../../../common/CheckboxArray";

export const getChildCategoryLevel = (
  parent: Array<CategoryLevel>,
  parentIds: Array<string> | undefined,
  childIds?: Array<string> | null,
): Array<CheckboxOption> => {
  const childCategories: any = [];
  if (parentIds && parentIds.length) {
    parentIds.map(parentId => {
      const parentCat = parent.find(cat => cat.id === parentId);
      if (childIds && childIds.length) {
        childIds.map(childId => {
          const childCat = parentCat && parentCat.items?.find(cat => cat.id === childId);
          const newArr =
            childCat && childCat.items
              ? [
                  ...childCat.items.map(categoryLevel => ({
                    label: categoryLevel.title,
                    value: categoryLevel.id,
                  })),
                ]
              : [];
          childCategories.push(...newArr);
        });
      } else {
        const newArr =
          parentCat && parentCat.items
            ? parentCat.items.map(categoryLevel => ({
                label: categoryLevel.title,
                value: categoryLevel.id,
              }))
            : [];
        childCategories.push(...newArr);
      }
    });
  }
  return childCategories;
};

import React, { useEffect, useState } from "react";
import * as qs from "qs";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { FilterType } from "./types";
import Button from "../../../Button";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "assets/icons/shared/close.svg";
import Select, { CloseSelect, SelectOption } from "../../../Select";
import { ReactComponent as DeleteIcon } from "assets/icons/shared/trash.svg";
import InputText from "../../../InputText";
import { ReactComponent as SearchIcon } from "assets/icons/shared/search.svg";
import { savedFiltersService } from "api/savedFilters";
import axios, { CancelTokenSource } from "axios";
import Modal from "../../../Modal";
import { SavedFilterType } from "types/savedFilterType";
import FormInput from "components/formik/FormInput";
import { Formik } from "formik";
import * as Yup from "yup";

type PropsType = {
  active: boolean;
  appliedFilters: Array<FilterType>;
  setActiveFilters: (active: boolean) => void;
  filterParams: any;
  localFilters: any;
  setLocalFilters: (filters: any) => void;
  setSearchQuery: (query: string) => void;
  pageId?: string;
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
});

const FiltersPanel = ({
  active,
  appliedFilters,
  setActiveFilters,
  filterParams,
  localFilters,
  setLocalFilters,
  setSearchQuery,
  pageId,
}: PropsType) => {
  const [savedFilters, setSavedFilters] = useState<SavedFilterType[]>([]);
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<SavedFilterType | null>(null);
  const [createModalActive, setCreateModalActive] = useState(false);
  const [savedFiltersRequest, setSavedFiltersRequest] = useState<SavedFilterType | null>(null);
  const history = useHistory();
  const source: CancelTokenSource = axios.CancelToken.source();

  const handleApplyFilters = () => {
    // we need to reset the page after choosing the new filters, but we need to save pageSize and sort
    const params = {
      ...localFilters,
      page: 1,
      pageSize: filterParams.pageSize,
      sort: filterParams.sort,
    };
    const notNullParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value));
    history.replace(history.location.pathname + "?" + qs.stringify(notNullParams));
    setActiveFilters(false);
  };

  const handleCancelFilters = () => {
    setLocalFilters({});
    setSearchQuery("");
    history.replace(history.location.pathname + "?" + qs.stringify({}));
    setActiveFilters(false);
  };

  const closeFiltersPanel = () => {
    setActiveFilters(false);
  };

  useEffect(() => {
    if (!pageId) {
      return;
    }

    const fetchSavedFilters = async () => {
      try {
        const data = await savedFiltersService.getList(pageId, { token: source.token });
        setSavedFilters(data);
      } catch (e) {
        console.log(e);
      }
    };

    fetchSavedFilters();
  }, []);

  const handleSaveFilter = () => {
    if (!pageId) {
      return;
    }
    setSavedFiltersRequest({
      id: "0",
      title: "",
      type: pageId,
      filters: {
        ...localFilters,
        sort: undefined,
        filterId: undefined,
        page: undefined,
        pageSize: undefined,
      },
    });
    setCreateModalActive(true);
  };

  const createFilter = async (submitData: any, { setErrors }: any) => {
    try {
      const data = await savedFiltersService.save(submitData);
      setSavedFilters(prev => [...prev, data]);
      setLocalFilters((prev: any) => ({ ...prev, filterId: data.id }));
      setCreateModalActive(false);
    } catch (e: any) {
      if (e?.response?.data?.details && e?.response?.data?.details[0]?.message) {
        setErrors(e.response.data.details[0].message);
      }
    }
  };

  const handleDeleteFilter = (id: string) => {
    const filter = savedFilters.find(filter => filter.id === id);
    if (filter) {
      setFilterToDelete(filter);
      setDeleteModalActive(true);
    }
  };

  const deleteSavedFilter = async () => {
    if (!filterToDelete) {
      return;
    }

    try {
      await savedFiltersService.deleteById(filterToDelete.id);
      setSavedFilters(savedFilters.filter(filter => filter.id !== filterToDelete.id));
      setDeleteModalActive(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onSavedFilterSelect = (id: string) => {
    const selectedFilter = savedFilters.find(filter => filter.id === id);
    if (selectedFilter) {
      setLocalFilters({ ...selectedFilter.filters, filterId: id });
    }
  };

  const optionLabelRenderer = (option: SelectOption, closeSelect: CloseSelect) => {
    return (
      <div className={styles.selectOption}>
        <span>{option.label}</span>
        <Button
          type="transparent"
          icon={<DeleteIcon />}
          onClick={e => {
            e.stopPropagation();
            handleDeleteFilter(option.value);
            closeSelect();
          }}
          className={styles.deleteButton}
          disabled={option.value === localFilters.filterId}
        />
      </div>
    );
  };

  const contentRenderer = (options: React.ReactElement) => {
    return (
      <div className={styles.selectContent}>
        <div className={styles.filterSearch}>
          <InputText
            name="filterSearch"
            size="large"
            placeholder="Search Saved Filter"
            value={searchFilterValue}
            onChange={setSearchFilterValue}
            className={styles.input}
            onClick={e => e.stopPropagation()}
            icon={<SearchIcon className={styles.searchIcon} />}
          />
        </div>
        {options}
      </div>
    );
  };

  const options = savedFilters
    .filter(({ title }) => {
      const filterRegex = new RegExp(searchFilterValue, "i");
      return filterRegex.test(title);
    })
    .map(({ title, id }) => ({ label: title, value: id }));

  return (
    <div className={classNames(styles.filters, { [styles.active]: active })}>
      <div className={styles.filters__header}>
        <h3 className={styles.filters__title}>Filter Products</h3>
        <Button
          icon={<CloseIcon />}
          onClick={closeFiltersPanel}
          className={styles.closeButton}
          type="transparent"
        />
      </div>
      <div className={styles.filters__content}>
        {pageId && (
          <div className={styles.savedFilters}>
            <Select
              size="large"
              className={styles.select}
              selected={localFilters.filterId}
              options={options}
              onSelect={onSavedFilterSelect}
              label={
                <div className={styles.selectLabel}>
                  Saved filters <div className={styles.badge}>new</div>
                </div>
              }
              optionLabelRenderer={optionLabelRenderer}
              contentRenderer={contentRenderer}
            />

            <span className={styles.text}>
              <span>Advice:</span>
              <span>You have opportunity to tune filters and save for next time</span>
            </span>
          </div>
        )}

        {appliedFilters.map((filter, index) => (
          <div className={classNames(styles.filter, filter.className)} key={index}>
            {filter.component}
          </div>
        ))}
      </div>
      <div className={styles.filters__actions}>
        <div className={styles.leftBlock}>
          <Button onClick={handleApplyFilters} type="primary">
            Apply Filters
          </Button>
          <Button onClick={handleCancelFilters}>Clear</Button>
        </div>

        {pageId && (
          <Button onClick={handleSaveFilter} type="link" className={styles.saveButton}>
            Save filter
          </Button>
        )}
      </div>

      {savedFiltersRequest && (
        <Formik
          onSubmit={createFilter}
          validationSchema={validationSchema}
          initialValues={savedFiltersRequest}
        >
          {({ submitForm }) => (
            <Modal
              active={createModalActive}
              setActive={setCreateModalActive}
              title="Create Saved Filter"
              onSubmit={submitForm}
              saveButtonText="Save"
              saveButtonClassName={styles.saveButtonModal}
              overlayClassName={styles.modalOverlay}
              cancelButtonClassName={styles.cancelButtonModal}
            >
              <div className={styles.modal}>
                <FormInput name="title" size="large" placeholder="Title" label="Title" />
              </div>
            </Modal>
          )}
        </Formik>
      )}

      <Modal
        active={deleteModalActive}
        setActive={setDeleteModalActive}
        title="Delete Filter"
        onSubmit={deleteSavedFilter}
        saveButtonText="Delete"
        saveButtonClassName={styles.saveButtonModal}
        overlayClassName={styles.modalOverlay}
        cancelButtonClassName={styles.cancelButtonModal}
      >
        <div className={styles.modal}>
          <p className={styles.title}>Are You sure about deleting this filter?</p>
          <p className={styles.description}>{filterToDelete && filterToDelete.title}</p>
        </div>
      </Modal>
    </div>
  );
};

export default FiltersPanel;

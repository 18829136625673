import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/icons/shared/close.svg";
import Button from "../Button";
import ClickAwayListener from "../../../helpers/ClickAwayListener";
import ReactDOM from "react-dom";

type PropsType = {
  children: React.ReactNode;
  title: string;
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit?: () => void;
  saveButtonText?: string;
  cancelButtonText?: string;
  size?: "large" | "medium" | "small";
  className?: string;
  disabled?: boolean;
  onClose?: () => void;
  secondaryButton?: React.ReactNode;
  loading?: boolean;
  saveButtonClassName?: string;
  headerClassName?: string;
  overlayClassName?: string;
  cancelButtonClassName?: string;
};

const node = document.getElementById("modal-container");

const Modal = ({
  children,
  title,
  active,
  setActive,
  onSubmit,
  className,
  disabled,
  ...props
}: PropsType) => {
  const {
    saveButtonText = "Save",
    cancelButtonText = "Cancel",
    size = "small",
    secondaryButton,
    loading,
    saveButtonClassName,
    headerClassName,
    overlayClassName,
    cancelButtonClassName,
  } = props;

  const { onClose = () => setActive(false) } = props;
  const modalId = "modalWrapper";

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.modal, overlayClassName, { [styles.active]: active })}
      id={modalId}
    >
      <ClickAwayListener
        onClickAway={e => {
          (e.target as HTMLElement).id === modalId && onClose();
        }}
        className={classNames(styles.modal__wrapper, styles[size], className)}
      >
        <div className={classNames(styles.modal__title, headerClassName)}>
          {title}
          <Button
            onClick={onClose}
            className={styles.modal__titleClose}
            icon={<CloseIcon className={styles.icon} />}
            type="transparent"
          />
        </div>
        <div>{children}</div>
        {onSubmit && (
          <div className={styles.modal__actions}>
            <Button
              onClick={onSubmit}
              type="primary"
              size="large"
              className={classNames(styles.modal__save, saveButtonClassName)}
              disabled={disabled}
              loading={loading}
            >
              {saveButtonText}
            </Button>
            {secondaryButton ? (
              secondaryButton
            ) : (
              <Button onClick={onClose} size="large" className={cancelButtonClassName}>
                {cancelButtonText}
              </Button>
            )}
          </div>
        )}
      </ClickAwayListener>
    </div>,
    node as Element,
  );
};

export default Modal;

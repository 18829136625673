import React from "react";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import RangeFilter from "components/common/SearchFiltersPanel/components/Filters/RangeFilter";
import CheckboxArray from "components/common/CheckboxArray";
import { SaleChannel } from "types/saleChannelsTypes";

type PropsType = {
  params: FilterComponentProps;
  salesChannels: SaleChannel[];
};

const priceParamName = "price.saleChannelIds";
const deltaPriceParamName = "deltaPrice.types";

const PriceFilter = ({ params, salesChannels }: PropsType) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p className={styles.title}>Retail Prices</p>

        <CheckboxArray
          selected={params.getValue(priceParamName) ?? []}
          options={salesChannels.map(channel => ({ value: channel.id, label: channel.title }))}
          onChange={selected => params.handleChange(priceParamName, selected)}
          className={styles.checkboxes}
          checkboxClassName={styles.checkbox}
        />

        <RangeFilter
          params={params}
          nameFilterFrom="price.priceMin"
          nameFilterTo="price.priceMax"
          disabled={!params.getValue(priceParamName)}
          className={styles.range}
        />
      </div>

      <div className={styles.wrapper}>
        <p className={styles.title}>Pricing Delta</p>
        <CheckboxArray
          selected={params.getValue(deltaPriceParamName) ?? []}
          options={[
            {
              value: "deltaAmzUsSyncPriceToAmzUsPrice",
              label: "Delta Price Amazon US",
            },
            {
              value: "deltaAmzUsCompetitivePriceToAmzUsPrice",
              label: "Competitive vs Amazon US",
            },
            {
              value: "deltaAmzUsPriceToCaPrice",
              label: "Amazon US vs CAdvisor",
            },
            { value: "deltaAmzUsPriceToMap", label: "Amazon US vs MAP" },
          ]}
          onChange={selected => params.handleChange(deltaPriceParamName, selected)}
          className={styles.checkboxesDeltaPrice}
          checkboxClassName={styles.checkboxDeltaPrice}
        />

        <RangeFilter
          params={params}
          nameFilterFrom="deltaPrice.min"
          nameFilterTo="deltaPrice.max"
          disabled={!params.getValue(deltaPriceParamName)}
          className={styles.range}
        />
      </div>

      {/*todo: uncomment when margin filter will be available in back-end*/}
      {/*<div className={styles.wrapper}>*/}
      {/*  <p className={styles.title}>Margin</p>*/}

      {/*  <RangeFilter*/}
      {/*    params={params}*/}
      {/*    nameFilterFrom="price.marginMin"*/}
      {/*    nameFilterTo="price.marginMax"*/}
      {/*    disabled={!params.getValue(paramName)}*/}
      {/*    className={styles.range}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  );
};

export default PriceFilter;

import jwt_decode from "jwt-decode";
import customAxios from "../api";
import { PermissionType, UserTokenPayload } from "../../types/authTypes";

/*const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_API_KEY
    : process.env.REACT_APP_DEV_API_KEY;*/

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_SERVICE_API_KEY
    : process.env.REACT_APP_DEV_SERVICE_API_KEY;

const login = async (params: { email: string; password: string }) => {
  const { data } = await customAxios.post(`${apiUrl}/master-catalog/permission/login`, params);
  const userData: any = jwt_decode(data.token);

  const userPayload = {
    name: `${userData.firstName} ${userData.lastName}`,
    jwt: data.token,
  };

  if (data) {
    localStorage.setItem("user", JSON.stringify(userPayload));
  }

  return userPayload;
};

const getPermissions = async (): Promise<PermissionType> => {
  const user = JSON.parse(localStorage.getItem("user") ?? "");
  const { userId }: UserTokenPayload = jwt_decode(user.jwt);

  const { data } = await customAxios.get(`${apiUrl}/master-catalog/permission/user-permissions`, {
    params: {
      id: userId,
    },
  });
  return data;
};

export const authService = {
  login,
  getPermissions,
};

import * as Yup from "yup";
import { Product } from "types/catalogTypes";
import { ErrorType } from "types/errorsTypes";

const defaultVariantValidationSchema = Yup.object().shape({
  sku: Yup.string().required("SKU can't be blank"),
  productionLeadTime: Yup.number().test("productionLeadTime", "must be integer", value =>
    Number.isInteger(Number(value)),
  ),
});

export const ProductLineValidationSchema = Yup.object().shape({
  title: Yup.string().required("Name can't be blank"),
  defaultVariant: Yup.object()
    .nullable()
    .when("variants", {
      is: (variants: any) => !variants.length,
      then: defaultVariantValidationSchema,
    }),
  variants: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required("Name can't be blank"),
      defaultVariant: defaultVariantValidationSchema,
    }),
  ),
  bundleVariantIds: Yup.array().when("type", {
    is: (type: any) => type === "bundle",
    then: Yup.array().min(1),
  }),
});

export const validateProduct = (product: Product, setErrors: any) => {
  ProductLineValidationSchema.validate(product, { abortEarly: false })
    .then(() => {
      setErrors((prev: Array<ErrorType>) =>
        prev && prev.filter(error => error.productId !== product.id).length
          ? prev.filter(error => error.productId !== product.id)
          : null,
      );
    })
    .catch(err => {
      const localErrors = {
        productId: product.id,
        productErrors: err.inner.map((error: any) => ({
          path: error.path,
          message: error.errors[0],
        })),
      };
      setErrors((prev: Array<ErrorType>) =>
        prev
          ? prev.find(error => error.productId === localErrors.productId)
            ? prev.map(error => (error.productId === localErrors.productId ? localErrors : error))
            : [...prev, localErrors]
          : [localErrors],
      );
    });
};

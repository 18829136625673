import React, { useMemo } from "react";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import Radio from "components/common/Radio";
import CheckboxArray from "components/common/CheckboxArray";

type PropsType = {
  params: FilterComponentProps;
};

const regionOptions = [
  { label: "NA", value: "holdNA" },
  { label: "EU", value: "holdEU" },
  { label: "UK", value: "holdUK" },
];

const HoldFilter = ({ params }: PropsType) => {
  const radioValue = useMemo(() => {
    if (params.getValue("holdNA")) {
      return params.getValue("holdNA");
    } else if (params.getValue("holdEU")) {
      return params.getValue("holdEU");
    } else if (params.getValue("holdUK")) {
      return params.getValue("holdUK");
    }
    return "";
  }, [params.getValue("holdNA"), params.getValue("holdEU"), params.getValue("holdUK")]);

  const selectedRegions = useMemo(() => {
    const regions = [];
    if (params.getValue("holdNA")) {
      regions.push("holdNA");
    }
    if (params.getValue("holdEU")) {
      regions.push("holdEU");
    }
    if (params.getValue("holdUK")) {
      regions.push("holdUK");
    }
    return regions;
  }, [params.getValue("holdNA"), params.getValue("holdEU"), params.getValue("holdUK")]);

  const onRadioChange = (value: any) => {
    params.handleChange("holdNA", value);
    params.handleChange("holdEU", value);
    params.handleChange("holdUK", value);
  };

  const onRegionsChange = (value: any[]) => {
    regionOptions.forEach(option => {
      if (value.includes(option.value)) {
        params.handleChange(option.value, radioValue);
      } else {
        params.handleChange(option.value, null);
      }
    });
  };

  return (
    <div className={styles.holdFilter}>
      <p className={styles.holdFilter__title}>Hold</p>
      <div className={styles.holdFilter__wrapper}>
        <Radio
          className={styles.holdFilter__radio}
          onChange={onRadioChange}
          label="All"
          checked={radioValue === ""}
          value=""
          name="all"
        />
        <Radio
          className={styles.holdFilter__radio}
          onChange={onRadioChange}
          label="Not hold"
          checked={radioValue === "inactive"}
          value="inactive"
          name="inactive"
        />
        <Radio
          className={styles.holdFilter__radio}
          onChange={onRadioChange}
          label="Hold"
          checked={radioValue === "active"}
          value="active"
          name="active"
        />
      </div>
      {(radioValue == "active" || radioValue == "inactive") && (
        <CheckboxArray
          options={regionOptions}
          selected={selectedRegions}
          className={styles.holdFilter__checkboxes}
          onChange={onRegionsChange}
          checkboxClassName={styles.holdFilter__checkbox}
        />
      )}
    </div>
  );
};

export default HoldFilter;

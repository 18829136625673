import React from "react";
import { DropResult, ResponderProvided } from "react-beautiful-dnd";
import DataTable from "../DataTable";
import TableHeader from "../DataTable/TableHeader";
import TableCell from "../DataTable/TableCell";
import TableBody from "../DataTable/TableBody";
import TableRow from "../DataTable/TableRow";

type TableLineType = {
  key: string;
  fields: any[];
};

type PropsType = {
  columns: Array<{
    name: string;
    columnWidth: string;
    children?: React.ReactNode;
  }>;
  rows: (item: any, index: number) => TableLineType;
  items: Array<any>;
  fixedHeader?: boolean;
  onScrollToBottom?: (inView: boolean) => void;
  loading?: boolean;
  contentClassName?: string;
  draggable?: boolean;
  onDragEnd?: (result: DropResult, provided: ResponderProvided) => void;
  horizontalScroll?: boolean;
  headerClassName?: string;
  fieldClassName?: string;
  rowClassName?: string;
};

const Table = ({
  rows,
  columns,
  items,
  fixedHeader,
  onScrollToBottom,
  loading,
  contentClassName,
  ...props
}: PropsType) => {
  const {
    onDragEnd = () => undefined,
    draggable = false,
    horizontalScroll,
    headerClassName,
    fieldClassName,
    rowClassName,
  } = props;
  const localRows = items.map((item, index) => rows(item, index));
  const rowStyle = {
    gridTemplateColumns: columns.map(column => column.columnWidth).join(" "),
  };

  return (
    <DataTable fixedHeader={fixedHeader} horizontalScroll={horizontalScroll}>
      <TableHeader rowStyle={rowStyle} className={headerClassName}>
        {columns.map((row, index) => (
          <TableCell key={index} className={fieldClassName}>
            {row.name}
            {row.children && row.children}
          </TableCell>
        ))}
      </TableHeader>

      <TableBody
        onDragEnd={onDragEnd}
        draggable={draggable}
        className={contentClassName}
        loading={loading}
        onScrollToBottom={onScrollToBottom}
      >
        {localRows.map((item, index: number) => (
          <TableRow
            key={item.key}
            draggableId={item.key}
            index={index}
            draggable={draggable}
            className={rowClassName}
            rowStyle={rowStyle}
          >
            {item.fields.map((field, index) => (
              <TableCell key={index} className={fieldClassName}>
                {field}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </DataTable>
  );
};

export default Table;

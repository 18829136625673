import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

type PropsType = {
  children: React.ReactNode;
  className?: string;
};

const Card = ({ children, className }: PropsType) => {
  return <div className={classNames(styles.card, className)}>{children}</div>;
};

export default Card;

import React, { useState } from "react";
import classNames from "classnames";
import LineField from "../LineField";
import { getFieldWidth, getLineFieldByName, getLineFields } from "../../helpers/fields";
import { CategoryLevel } from "types/categoryLevelTypes";
import {
  Supplier,
  getInitialProductVariantWithSaleChannels,
  getInitialProductWithSaleChannels,
  InitialVariant,
  Product,
  ProductVariant,
} from "types/catalogTypes";
import { ErrorType } from "types/errorsTypes";
import TypeProductActions from "./components/TypeProductActions";
import ActionList from "components/common/ActionList";
import ProductVariantsModal from "../../../ProductVariantsModal";
import { ReactComponent as PlusIcon } from "assets/icons/shared/plus.svg";
import { ReactComponent as SimpleIcon } from "assets/icons/productsType/simple.svg";
import { ReactComponent as BundleIcon } from "assets/icons/productsType/bundle.svg";
import { ReactComponent as GiftIcon } from "assets/icons/productsType/gift.svg";
import { ReactComponent as ActionsIcon } from "assets/icons/shared/actionsIcon.svg";
import rowStyles from "../../styles.module.scss";
import styles from "./styles.module.scss";
import { TableColumnType } from "types/tableColumnType";
import _ from "lodash";
import { validateProduct } from "../../helpers/validation";
import { getFieldError } from "../../helpers/errors";
import { SaleChannel } from "types/saleChannelsTypes";

type PropsType = {
  setProductsData: (products: (prev: Array<Product>) => Product[]) => void;
  categoryLevels: Array<CategoryLevel>;
  fields: Array<TableColumnType>;
  errors: ErrorType | null | undefined;
  setErrors: any;
  onDiscard: () => void;
  suppliers: Supplier[];
  saleChannels: SaleChannel[];
  withCheckbox?: boolean;
};

const CreateProductForm = ({
  fields,
  categoryLevels,
  setProductsData,
  errors,
  setErrors,
  onDiscard,
  suppliers,
  saleChannels,
  withCheckbox,
}: PropsType) => {
  const [initialProduct, setInitialProduct] = useState<Product>(
    getInitialProductWithSaleChannels(saleChannels),
  );
  const [productType, setProductType] = useState<string | null>(null);
  const [bundleModalActive, setBundleModalActive] = useState<boolean>(false);
  const productFormFields = getLineFields(initialProduct, fields);

  const handleChangeProductData = (product: Product) => {
    validateProduct(product, setErrors);
    setProductsData(prev =>
      prev.find(prevProduct => prevProduct.id === "0")
        ? prev.map(prevProduct => (prevProduct.id === product.id ? product : prevProduct))
        : [product, ...prev],
    );
  };

  const handleSelectProductType = (value: string) => {
    setProductType(value);
    setInitialProduct({ ...initialProduct, type: value });
    handleChangeProductData({ ...initialProduct, type: value });
  };

  const handleAddNewVariant = (product: Product) => {
    const newVariant = _.cloneDeep(getInitialProductVariantWithSaleChannels(saleChannels));
    const newVariants = product.variants.length
      ? [...product.variants, newVariant]
      : product.defaultVariant
      ? [{ ...InitialVariant, defaultVariant: { ...product.defaultVariant } }, newVariant]
      : [getInitialProductVariantWithSaleChannels(saleChannels), newVariant];
    const productWithNewVariants = {
      ...initialProduct,
      defaultVariant: null,
      variants: newVariants,
    };
    setInitialProduct(productWithNewVariants);
    handleChangeProductData(productWithNewVariants);
  };

  const handleChangeProduct = (product: Product) => {
    handleChangeProductData(product);
    setInitialProduct(product);
  };

  const handleDeleteVariant = (index: number, product: Product) => {
    const newVariants = product.variants.filter((variant, i) => i !== index);
    setInitialProduct({ ...initialProduct, variants: newVariants });
    handleChangeProductData({ ...initialProduct, variants: newVariants });
  };

  const handleDeleteBundleVariant = (index: number, product: Product) => {
    const newBundleVariants = product.bundleVariants.filter((variant, i) => i !== index);
    const newBundleVariantsIds = newBundleVariants.map(
      bundleVariant => bundleVariant.defaultVariant.id,
    );
    setInitialProduct({
      ...initialProduct,
      bundleVariants: newBundleVariants,
      bundleVariantIds: newBundleVariantsIds,
    });
    handleChangeProductData({
      ...initialProduct,
      bundleVariants: newBundleVariants,
      bundleVariantIds: newBundleVariantsIds,
    });
  };

  const handleSelectToBundle = (selected: ProductVariant[]) => {
    setBundleModalActive(false);
    const newProduct = {
      ...initialProduct,
      bundleVariantIds: selected.map(item => item.defaultVariant.id),
    };
    handleChangeProductData(newProduct);
    setInitialProduct({
      ...newProduct,
      bundleVariants: [...selected],
    });
  };

  return (
    <>
      <div className={classNames(rowStyles.table__row, rowStyles.create, rowStyles.active)}>
        {productType ? (
          <>
            <div
              className={classNames(rowStyles.table__item, rowStyles.actions, {
                [rowStyles.big]: withCheckbox,
              })}
            >
              <ActionList
                className={rowStyles.actions__list}
                options={[
                  {
                    text: productType === "bundle" ? "Add product" : "Add variant",
                    onAction:
                      productType === "bundle"
                        ? () => setBundleModalActive(true)
                        : () => handleAddNewVariant(initialProduct),
                  },
                ]}
              >
                <PlusIcon className={rowStyles.icon} />
              </ActionList>
            </div>
            {fields.map((field, idx) => (
              <div
                key={idx}
                style={{ width: getFieldWidth(field.name) }}
                className={classNames(
                  rowStyles.table__item,
                  withCheckbox && idx === 0 && rowStyles.leftOffset,
                  field.alignment === "center" && rowStyles.alignCenter,
                  field.alignment === "right" && rowStyles.alignRight,
                )}
              >
                {productFormFields.product.hasOwnProperty(field.name) && (
                  <LineField
                    lineField={getLineFieldByName(productFormFields, field)}
                    categoryLevels={categoryLevels}
                    lineFieldName={field.name}
                    product={initialProduct}
                    setProduct={product => handleChangeProduct(product)}
                    error={
                      errors
                        ? getFieldError(
                            errors.productErrors,
                            getLineFieldByName(productFormFields, field),
                          )
                        : ""
                    }
                    suppliers={suppliers}
                  />
                )}
              </div>
            ))}
          </>
        ) : (
          <div className={styles.actionList}>
            <TypeProductActions
              options={[
                {
                  label: "Product",
                  value: "simple",
                  icon: <SimpleIcon className={styles.actionList__icon} />,
                },
                {
                  label: "Bundle",
                  value: "bundle",
                  icon: <BundleIcon className={styles.actionList__icon} />,
                },
                {
                  label: "Gift Card",
                  value: "gift_card",
                  icon: <GiftIcon className={styles.actionList__icon} />,
                },
              ]}
              setProductType={value => handleSelectProductType(value)}
              onDiscard={onDiscard}
            />
            <div className={styles.actionList__globalBg} />
          </div>
        )}
      </div>

      {!!initialProduct.variants.length &&
        initialProduct.variants.map((variant, variantIndex) => (
          <div
            className={classNames(rowStyles.table__row, rowStyles.create, rowStyles.active)}
            key={variantIndex}
          >
            <div
              className={classNames(rowStyles.table__item, rowStyles.actions, {
                [rowStyles.big]: withCheckbox,
              })}
            >
              <ActionList
                className={rowStyles.actions__list}
                options={[
                  {
                    text: "Delete",
                    onAction: () => handleDeleteVariant(variantIndex, initialProduct),
                    disable: initialProduct.variants.length <= 2,
                  },
                ]}
              >
                <ActionsIcon className={rowStyles.icon} />
              </ActionList>
            </div>

            {fields.map((field, idx) => (
              <div
                key={idx}
                style={{ width: getFieldWidth(field.name) }}
                className={classNames(
                  rowStyles.table__item,
                  withCheckbox && idx === 0 && rowStyles.leftOffset,
                  field.alignment === "center" && rowStyles.alignCenter,
                  field.alignment === "right" && rowStyles.alignRight,
                )}
              >
                {productFormFields.variants[variantIndex].hasOwnProperty(field.name) && (
                  <LineField
                    lineField={getLineFieldByName(
                      productFormFields,
                      field,
                      variantIndex,
                      "variant",
                    )}
                    categoryLevels={categoryLevels}
                    lineFieldName={field.name}
                    product={initialProduct}
                    setProduct={product => handleChangeProduct(product)}
                    error={
                      errors
                        ? getFieldError(
                            errors.productErrors,
                            getLineFieldByName(productFormFields, field, variantIndex, "variant"),
                          )
                        : ""
                    }
                    suppliers={suppliers}
                  />
                )}
              </div>
            ))}
          </div>
        ))}

      {!!initialProduct.bundleVariants.length &&
        initialProduct.bundleVariants.map((bundleVariant, variantIndex) => (
          <div
            className={classNames(rowStyles.table__row, rowStyles.active, rowStyles.create)}
            key={variantIndex}
          >
            <div
              className={classNames(rowStyles.table__item, rowStyles.actions, {
                [rowStyles.big]: withCheckbox,
              })}
            >
              <ActionList
                className={rowStyles.actions__list}
                options={[
                  {
                    text: "Delete",
                    onAction: () => handleDeleteBundleVariant(variantIndex, initialProduct),
                    disable: initialProduct.bundleVariants.length <= 2,
                  },
                ]}
              >
                <ActionsIcon className={rowStyles.icon} />
              </ActionList>
            </div>

            {fields.map((field, idx) => (
              <div
                key={idx}
                style={{ width: getFieldWidth(field.name) }}
                className={classNames(
                  rowStyles.table__item,
                  withCheckbox && idx === 0 && rowStyles.leftOffset,
                  field.alignment === "center" && rowStyles.alignCenter,
                  field.alignment === "right" && rowStyles.alignRight,
                )}
              >
                {productFormFields.bundleVariants[variantIndex].hasOwnProperty(field.name) && (
                  <LineField
                    lineField={getLineFieldByName(productFormFields, field, variantIndex, "bundle")}
                    categoryLevels={categoryLevels}
                    lineFieldName={field.name}
                    product={initialProduct}
                    setProduct={product => handleChangeProduct(product)}
                    error=""
                    productForCategoryLevels={initialProduct.bundleVariants[variantIndex].product}
                    suppliers={suppliers}
                  />
                )}
              </div>
            ))}
          </div>
        ))}

      {bundleModalActive && (
        <ProductVariantsModal
          active={bundleModalActive}
          setActive={setBundleModalActive}
          onSelect={handleSelectToBundle}
          selected={initialProduct.bundleVariants}
        />
      )}
    </>
  );
};

export default CreateProductForm;

import React, { useState } from "react";
import VariantFormLine from "./VariantFormLine";
import VariantPaginationFormLine from "./VariantPaginationFormLine";
import { Product, Supplier } from "../../../../../types/catalogTypes";
import { ErrorType } from "../../../../../types/errorsTypes";
import { CategoryLevel } from "../../../../../types/categoryLevelTypes";
import { TableColumnType } from "../../../../../types/tableColumnType";

type PropsType = {
  disable: boolean;
  showChildren: boolean;
  fields: Array<TableColumnType>;
  productFormFields: any;
  categoryLevels: Array<CategoryLevel>;
  product: Product;
  errors: ErrorType | null | undefined;
  handleDeleteVariant: (index: number, product: Product) => void;
  handleChangeProductValue: (product: Product) => void;
  suppliers: Supplier[];
  limitedMode?: boolean;
  selectedVariants: Set<string>;
  withCheckbox?: boolean;
  onVariantSelect: (variantId: string) => void;
  checkboxesDisabled?: boolean;
};

const variantsPageSize = 10;

const VariantsSection = ({
  disable,
  showChildren,
  fields,
  productFormFields,
  categoryLevels,
  product,
  errors,
  selectedVariants,
  withCheckbox,
  onVariantSelect,
  checkboxesDisabled,
  ...props
}: PropsType) => {
  const { handleDeleteVariant, handleChangeProductValue, suppliers, limitedMode } = props;
  const [currentVariantsPage, setCurrentVariantsPage] = useState(1);
  const firstVariantIndex = (currentVariantsPage - 1) * variantsPageSize;
  const lastVariantIndex = currentVariantsPage * variantsPageSize;

  const paginatedVariants = product.variants
    .slice(firstVariantIndex, lastVariantIndex)
    .map((variant, index) => {
      return {
        variant: variant,
        variantIndex: firstVariantIndex + index,
      };
    });

  if (!paginatedVariants.length) {
    // paginatedVariants can be empty if all items were deleted by user on the last page,
    // in that case product.variants.length will be greater than 0 and we can switch to the previous page
    if (product.variants.length) {
      setCurrentVariantsPage(currentVariantsPage - 1);
    }
    // if it's not that case - return
    return null;
  }

  return (
    <>
      {paginatedVariants.map(({ variantIndex, variant }) => (
        <VariantFormLine
          key={variantIndex}
          disable={disable}
          showChildren={showChildren}
          fields={fields}
          productFormFields={productFormFields}
          variantIndex={variantIndex}
          categoryLevels={categoryLevels}
          product={product}
          errors={errors}
          handleDeleteVariant={handleDeleteVariant}
          handleChangeProductValue={handleChangeProductValue}
          suppliers={suppliers}
          limitedMode={limitedMode}
          withCheckbox={withCheckbox}
          checkboxDisabled={checkboxesDisabled}
          selected={selectedVariants.has(variant.defaultVariant.id)}
          onSelect={() => onVariantSelect(variant.defaultVariant.id)}
        />
      ))}
      {product.variants.length > variantsPageSize && (
        <VariantPaginationFormLine
          total={product.variants.length}
          page={currentVariantsPage}
          pageSize={variantsPageSize}
          onPageChange={setCurrentVariantsPage}
        />
      )}
    </>
  );
};

export default VariantsSection;

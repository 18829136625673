import classNames from "classnames";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import styles from "./styles.module.scss";

type PropsType = {
  label?: string;
  error?: string;
  size?: "large" | "medium";
};

const DatePicker = (props: ReactDatePickerProps & PropsType) => {
  const { label, error, size = "medium" } = props;

  return (
    <div className={classNames(styles.wrapper, props.className)}>
      {label && <span className={styles.label}>{label}</span>}
      <ReactDatePicker
        {...props}
        className={classNames(styles.dateInput, styles[size], error && styles.error)}
      />
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

export default DatePicker;

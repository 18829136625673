import classNames from "classnames";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styles from "../styles.module.scss";

type PropsType = {
  index?: any;
  rowStyle: any;
  draggable?: boolean;
  className?: string;
  children?: React.ReactNode;
  draggableId?: any;
};

const TableRow = ({ rowStyle, draggable, children, ...props }: PropsType) => {
  const { index, className, draggableId } = props;

  if (!draggable) {
    return (
      <div className={classNames(styles.row, className)} style={rowStyle}>
        {children}
      </div>
    );
  }

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={classNames(styles.row, className, { [styles.dragging]: snapshot.isDragging })}
          style={{ ...provided.draggableProps.style, ...rowStyle }}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default TableRow;

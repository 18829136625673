import { ProductErrorType } from "types/errorsTypes";
import { LineFieldType } from "../types";
import { DefaultVariant, ProductVariant, VariantSaleChannel } from "../../../../types/catalogTypes";
import convert from "lodash/fp/convert";

export const getFieldError = (
  errors: Array<ProductErrorType> | null,
  lineField: LineFieldType | undefined,
): string | undefined => {
  return errors?.find(error => error.path === lineField?.formName)?.message;
};

export const convertProductErrors = (
  originalErrors: any,
  variantsMatches: any,
  channelsMatches: any = {},
  path = "",
  depth = 0,
): ProductErrorType[] => {
  let convertedErrors: ProductErrorType[] = [];

  depth++;
  if (depth >= 7) {
    return convertedErrors;
  }

  Object.keys(originalErrors).forEach((key: string) => {
    let newPath = key;
    let newKey = key;

    if (path === "variants") {
      // we need to change keys for variants to their real keys in array
      newKey = variantsMatches[key] ? variantsMatches[key].id : key;
      // we need to change channelsMatches to the sent ones for this variant
      channelsMatches = variantsMatches[key].channels;
    }

    if (path.endsWith("saleChannels")) {
      // we need to change keys for sale channels to their real keys in array
      newKey = channelsMatches[key] ?? key;
    }

    if (path) {
      if (Number(newKey) > 0 || newKey === "0") {
        // possibly it's an array index
        newPath = path + "[" + newKey + "]";
      } else {
        // it's object's property
        newPath = path + "." + newKey;
      }
    }

    if (typeof originalErrors[key] !== "object") {
      convertedErrors.push({ path: newPath, message: originalErrors[key] });
    } else {
      convertedErrors = [
        ...convertedErrors,
        ...convertProductErrors(
          originalErrors[key],
          variantsMatches,
          channelsMatches,
          newPath,
          depth,
        ),
      ];
    }
  });

  return convertedErrors;
};

export const getVariantsMatches = (
  sentVariants: ProductVariant[],
  productsDataVariants: ProductVariant[],
) => {
  const variantsMatches: any = {};

  sentVariants.forEach((sentVariant, sentVariantIndex) => {
    const index = productsDataVariants.findIndex(
      variant => variant.defaultVariant.id === sentVariant.defaultVariant.id,
    );
    if (index < 0) {
      return;
    }

    const sourceVariant = productsDataVariants[index];
    const sentChannels = sentVariant.defaultVariant.saleChannels;
    const sourceChannels = sourceVariant.defaultVariant.saleChannels;
    const channelsMatches = getChannelsMatches(sentChannels, sourceChannels);

    // we need channelsMatches because now we can get errors for saleChannels from back-end
    variantsMatches[sentVariantIndex] = {
      id: String(index),
      channels: channelsMatches,
    };
  });
  return variantsMatches;
};

export const getChannelsMatches = (
  sentChannels: VariantSaleChannel[],
  sourceChannels: VariantSaleChannel[],
) => {
  const channelsMatches: any = {};

  sentChannels.forEach((sentChannel, sentChannelIndex) => {
    const index = sourceChannels.findIndex(
      sourceChannel => sourceChannel.saleChannelId === sentChannel.saleChannelId,
    );
    if (index >= 0) {
      channelsMatches[sentChannelIndex] = String(index);
    }
  });

  return channelsMatches;
};

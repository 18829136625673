import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as UploadIcon } from "assets/icons/shared/import.svg";
import classNames from "classnames";

interface PropsType {
  onUpload: (files: File[]) => void;
  disabled?: boolean;
  className?: any;
  multiple?: boolean;
  acceptFiles?: string;
}

export type UploaderErrors = {
  [index: number]: {
    error?: boolean;
    errorMessage?: string;
  };
};

const Uploader = ({ onUpload, disabled, className, multiple, acceptFiles }: PropsType) => {
  const [enterCounter, setEnterCounter] = useState(0);
  const isDragging = enterCounter > 0;

  const onDrop = () => {
    setEnterCounter(0);
  };

  const onDragEnter = (e: any) => {
    e.preventDefault();
    setEnterCounter(enterCounter + 1);
  };

  const onDragLeave = (e: any) => {
    e.preventDefault();
    setEnterCounter(enterCounter - 1);
  };

  const onInputChange = (uploadedFiles: FileList | null) => {
    if (uploadedFiles && uploadedFiles.length) {
      onUpload(Array.from(uploadedFiles));
    }
  };

  return (
    <div
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={classNames(styles.uploader, className, {
        [styles.active]: isDragging,
      })}
    >
      <input
        type="file"
        multiple={multiple}
        id="file"
        name="file"
        disabled={disabled}
        {...(acceptFiles && { accept: acceptFiles })}
        onChange={e => onInputChange(e.target.files)}
      />
      <div className={styles.uploader__dndBlock}>
        <span className={styles.uploader__dndText}>Drag&Drop file here</span>
      </div>
      <div className={styles.uploader__uploadBlock}>
        <label
          htmlFor="file"
          className={classNames(styles.uploader__uploadButton, {
            [styles.disabled]: disabled,
          })}
        >
          <UploadIcon className={styles.uploader__uploadIcon} />
          <span className={styles.uploader__buttonText}>Browse</span>
        </label>
      </div>
    </div>
  );
};

export default Uploader;

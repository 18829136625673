import React from "react";
import styles from "./styles.module.scss";

type PropsType = {
  title?: string;
  children: any;
  pageAction?: any;
};

const Page = ({ title, children, pageAction }: PropsType) => {
  return (
    <div className={styles.content}>
      {(title || pageAction) && (
        <div className={styles.content__header}>
          {title && <div className={styles.content__title}>{title}</div>}
          {pageAction && <div className={styles.content__headerActions}>{pageAction}</div>}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

export default Page;

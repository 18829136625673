import React from "react";
import styles from "../styles.module.scss";

type PropsType = {
  children: React.ReactNode;
};

const Section = ({ children }: PropsType) => {
  return <div className={styles.section}>{children}</div>;
};

export default Section;

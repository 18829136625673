import React, { useState, InputHTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/icons/shared/close.svg";
import Button from "../Button";

type PropsType = {
  name: string;
  label?: string;
  onChange?: (value: any, name: string) => void;
  error?: string;
  marginOff?: boolean;
  icon?: React.ReactNode;
  size?: "large" | "medium";
  suffix?: React.ReactNode;
  clearButton?: boolean;
  onClick?: (e: any) => void;
};

export type InputPropsType = PropsType &
  Omit<InputHTMLAttributes<HTMLInputElement>, keyof PropsType>;

const InputText = React.forwardRef<HTMLInputElement, InputPropsType>(
  (
    {
      name,
      label,
      onChange,
      error,
      size = "medium",
      suffix,
      clearButton,
      icon,
      marginOff,
      ...props
    },
    ref,
  ) => {
    const { type = "text", value, className, disabled, step = "1", min, onClick } = props;
    const [fieldValue, setFieldValue] = useState<any>(value);

    const handleNumberChange = (e: any): void => {
      if (type === "number" && min !== undefined && Number(e.target.value) < min) {
        return;
      }
      setFieldValue(e.target.value.charAt(0) === "0" ? e.target.value.slice(1) : e.target.value);
      onChange && onChange(Number(e.target.value), name);
    };

    const getValue = () => {
      if (type === "number") {
        return fieldValue;
      }
      return onChange ? String(value) : fieldValue;
    };

    const suffixComponent = <div className={styles.field__suffix}>{suffix}</div>;
    const clearButtonComponent = (
      <div className={classNames(styles.field__suffix, { [styles.hidden]: !value })}>
        <Button
          onClick={() => (onChange ? onChange("", name) : setFieldValue(""))}
          type="transparent"
          size="medium"
          className={styles.closeButton}
          icon={<CloseIcon className={styles.closeButton__icon} />}
        />
      </div>
    );

    return (
      <div
        className={classNames(
          styles.field,
          styles[size],
          {
            [styles.error]: error,
            [styles.mb0]: marginOff,
            [styles.withIcon]: icon,
            [styles.withSuffix]: suffix || clearButton,
          },
          className,
        )}
      >
        {label && (
          <label htmlFor={name} className={styles.field__label}>
            {label}
          </label>
        )}
        <div className={styles.field__wrapper}>
          {icon && <div className={styles.field__icon}>{icon}</div>}
          <input
            ref={ref}
            id={name}
            {...props}
            type={type}
            className={classNames(styles.field__value, {
              [styles.field__disabled]: disabled,
            })}
            onChange={e => {
              if (type === "number") {
                handleNumberChange(e);
                return;
              }
              onChange ? onChange(e.target.value, name) : setFieldValue(value);
            }}
            value={getValue()}
            step={step}
            onClick={onClick}
          />
          {suffix ? suffixComponent : clearButton && clearButtonComponent}
        </div>
        {error && <p className={styles.field__error}>{error}</p>}
      </div>
    );
  },
);

export default InputText;

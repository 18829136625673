import React, { useEffect } from "react";
import { ToastType } from "./types";
import Button from "../Button";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "assets/icons/shared/close.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/shared/success.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/shared/info.svg";
import ReactDOM from "react-dom";

type ToastProps = {
  toast: ToastType;
  onClose: () => void;
};

const node = document.getElementById("toast-container");
node && node.classList.add(styles.container);

export const Toast = ({ toast, onClose }: ToastProps) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const icons = {
    success: <SuccessIcon className={styles.toast__successIcon} />,
    error: <ErrorIcon className={styles.toast__errorIcon} />,
  };

  return ReactDOM.createPortal(
    <div className={styles.toast}>
      <div className={styles.toast__content}>
        {icons[toast.type]}
        <span className={styles.toast__message}>{toast.message}</span>
      </div>
      <Button
        className={styles.toast__closeButton}
        onClick={onClose}
        type="transparent"
        icon={<CloseIcon className={styles.toast__closeIcon} />}
      />
    </div>,
    node as Element,
  );
};

import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as SuccessIcon } from "assets/icons/shared/success.svg";
import { ReactComponent as DotsIcon } from "assets/icons/shared/dotsHorizontal.svg";

interface PropsType {
  value: boolean;
  onChange?: (e: any, name: string) => void;
  name: string;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
  label?: string;
  description?: string;
}

const Toggler: React.FC<PropsType> = ({ disabled, className, readOnly, ...props }) => {
  const { name, value, onChange = () => undefined, label, description } = props;

  return (
    <div
      className={classNames(styles.toggler, className, {
        [styles.active]: value,
        [styles.disabled]: disabled || value === null,
        [styles.readOnly]: readOnly, // todo: delete for new read-only toggler release
      })}
    >
      <div className={styles.toggler__wrapper}>
        {label && <span className={styles.label}>{label}</span>}
        {/*todo: uncomment for new read-only toggler release*/}
        {/*{readOnly ? (*/}
        {/*  <>*/}
        {/*    {value ? (*/}
        {/*      <SuccessIcon className={styles.successIcon} />*/}
        {/*    ) : (*/}
        {/*      <DotsIcon className={styles.dotsIcon} />*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*) : (*/}
        <label className={styles.toggler__checkbox}>
          <input
            type="checkbox"
            id={name}
            onChange={e => onChange(e.target.checked, name)}
            checked={value ?? false}
          />
          <span className={styles.toggler__circle} />
        </label>
        {/*)}*/}
        {description && <span className={styles.description}>{description}</span>}
      </div>
    </div>
  );
};

export default Toggler;

import { CategoryLevel } from "../../../../../../../types/categoryLevelTypes";
import { LineFieldType } from "../../../../types";
import { Product } from "types/catalogTypes";
import Toggler from "components/common/Toggler";
import styles from "../../styles.module.scss";
import IconToggler from "components/common/IconToggler";
import { ReactComponent as CrossedShieldIcon } from "assets/icons/shared/shieldCrossed.svg";
import { ReactComponent as ShieldIcon } from "assets/icons/shared/shield.svg";
import Select, { SelectOption } from "components/common/Select";
import CategorySelect from "../CategorySelect";
import CatalogDatepicker from "../CatalogDatepicker";
import InputText from "components/common/InputText";
import { getNumberFieldStep } from "../../helpers";
import classNames from "classnames";
import React from "react";

type FieldPropsType = {
  categoryLevels: Array<CategoryLevel>;
  lineField: LineFieldType;
  product: Product;
  lineFieldName: string;
  value: any;
  onChange: (value: any, name: string) => void;
  fieldOptions: SelectOption[];
  active: boolean;
  setActive: (active: boolean) => void;
  error: string | undefined;
  productForCategoryLevels?: Product;
};

const Field = React.memo(
  ({
    lineField,
    value,
    onChange,
    fieldOptions,
    lineFieldName,
    active,
    setActive,
    error,
    categoryLevels,
    product,
    productForCategoryLevels,
  }: FieldPropsType) => {
    const formattedValue = lineField.formatter ? lineField.formatter(value, product.id) : value;
    switch (lineField.type) {
      case "toggle":
        return (
          <Toggler
            name={lineField.formName}
            value={value}
            onChange={onChange}
            disabled={lineField.disabled}
            readOnly={lineField.isReadOnly}
            className={styles.togglerField}
          />
        );

      case "holdIconToggle":
        return (
          <IconToggler
            name={lineField.formName}
            value={value}
            onChange={onChange}
            disabled={lineField.disabled || lineField.isReadOnly}
            className={classNames(styles.togglerField, styles.holdTogglerField, {
              [styles.active]: value,
              [styles.disabled]: lineField.disabled || lineField.isReadOnly,
            })}
            iconStatusTrue={<CrossedShieldIcon />}
            iconStatusFalse={<ShieldIcon />}
          />
        );

      case "select":
        return (
          <Select
            options={fieldOptions}
            onSelect={value => onChange(value, lineField.formName)}
            selected={value}
            disabled={lineField.disabled || lineField.isReadOnly}
            className={styles.selectField}
            fullWidth
          />
        );

      case "selectCategoryLevels":
        return (
          <CategorySelect
            lineFieldName={lineFieldName}
            lineField={lineField}
            categoryLevels={categoryLevels}
            product={productForCategoryLevels ?? product}
            value={value}
            onChange={(value, formName) => onChange(value, formName)}
          />
        );

      case "datepicker":
        return (
          <CatalogDatepicker
            lineField={lineField}
            value={value}
            onChange={value => onChange(value, lineField.formName)}
          />
        );

      case "number":
        return active ? (
          <InputText
            className={styles.textInput}
            type="number"
            name={lineField.formName}
            value={value}
            autoFocus={active}
            onBlur={() => setActive(false)}
            onChange={value => onChange(value, lineField.formName)}
            step={getNumberFieldStep(lineFieldName)}
            error={error}
            marginOff
            min={0}
          />
        ) : lineField.isReadOnly ? (
          <p className={styles.readOnlyValue} title={formattedValue}>
            {formattedValue}
          </p>
        ) : (
          <button
            className={styles.textValue}
            onDoubleClick={() => setActive(true)}
            disabled={lineField.disabled}
          >
            <p
              className={classNames(styles.textValue__text, {
                [styles.error]: error,
              })}
              title={formattedValue}
            >
              {formattedValue}
            </p>
            {error && <p className={styles.textValue__error}>{error}</p>}
          </button>
        );

      default:
        return active ? (
          <InputText
            className={styles.textInput}
            name={lineField.formName}
            value={value}
            autoFocus={active}
            onBlur={() => setActive(false)}
            onChange={value => onChange(value, lineField.formName)}
            marginOff
            error={error}
          />
        ) : lineField.isReadOnly ? (
          <p className={styles.readOnlyValue} title={formattedValue}>
            {formattedValue}
          </p>
        ) : (
          <button
            className={styles.textValue}
            onDoubleClick={() => setActive(true)}
            disabled={lineField.disabled}
          >
            <p
              className={classNames(styles.textValue__text, {
                [styles.error]: error,
              })}
              title={formattedValue}
            >
              {formattedValue}
            </p>
            {error && <p className={styles.textValue__error}>{error}</p>}
          </button>
        );
    }
  },
  (prev: FieldPropsType, next: FieldPropsType): boolean =>
    prev.value === next.value &&
    prev.active === next.active &&
    prev.product === next.product &&
    prev.lineField.formName === next.lineField.formName && // for cases when columns order is changed
    prev.error === next.error,
);

export default Field;

import { PermissionType } from "../types/authTypes";

export const getDisabledByPermissions = (permissions: PermissionType): boolean => {
  if (permissions.role === "admin") {
    return false;
  } else if (permissions.permissions.hasOwnProperty("masterCatalog")) {
    return permissions.permissions.masterCatalog === "read";
  } else if (permissions.permissions.hasOwnProperty("settings.categories")) {
    return permissions.permissions["settings.categories"] === "read";
  } else {
    return true;
  }
};

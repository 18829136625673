import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

const TableLoader = () => (
  <div className={styles.loader}>
    <div className={classNames(styles.loader__line, styles.header)} />
    <div className={styles.loader__line} style={{ width: "90%" }} />
    <div className={styles.loader__line} style={{ width: "100%" }} />
    <div className={styles.loader__line} style={{ width: "100%" }} />
    <div className={styles.loader__line} style={{ width: "70%" }} />
  </div>
);

export default TableLoader;

import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as CloseIcon } from "assets/icons/shared/close.svg";

type PropsType = {
  label: string;
  onClose: () => void;
  disabled?: boolean;
};

const Tag = ({ label, onClose, disabled }: PropsType) => {
  return (
    <span className={styles.tag}>
      {label}
      <button className={styles.btn} onClick={onClose} disabled={disabled}>
        <CloseIcon className={styles.icon} />
      </button>
    </span>
  );
};

export default Tag;

import { SavedFilterType } from "../../types/savedFilterType";
import { CancelToken } from "axios";
import customAxios from "../api";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_SERVICE_API_KEY
    : process.env.REACT_APP_DEV_SERVICE_API_KEY;

const getList = async (type: string, ctx: { token: CancelToken }): Promise<SavedFilterType[]> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/table-filter-sets/${type}`, {
    cancelToken: ctx.token,
  });
  return data.items ?? [];
};

const save = async (request: SavedFilterType): Promise<SavedFilterType> => {
  const { data } = await customAxios.put(
    `${apiUrl}/master-catalog/table-filter-sets/${request.id}`,
    request,
  );
  return data.item;
};

const deleteById = async (id: string) => {
  await customAxios.delete(`${apiUrl}/master-catalog/table-filter-sets/${id}`);
};

export const savedFiltersService = {
  getList,
  save,
  deleteById,
};

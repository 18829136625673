import Collapse from "../../../common/Collapse";
import { FilterComponentProps } from "../../../common/SearchFiltersPanel/components/FiltersPanel/types";
import CategoriesFilter from "../CategoriesFilter";
import { CategoryLevel } from "types/categoryLevelTypes";
import MultiOptionsFilter from "../../../common/SearchFiltersPanel/components/Filters/MultiOptionsFilter";
import { Supplier } from "types/catalogTypes";
import DateFilter from "../../../common/SearchFiltersPanel/components/Filters/DateFilter";
import styles from "./styles.module.scss";
import { itemFulfillmentTypeOptions } from "../../../../helpers/predefinedOptions";
import Section from "../../../common/Collapse/Section";

type PropsType = {
  params: FilterComponentProps;
  categoryLevels: CategoryLevel[];
  suppliers: Supplier[];
};

const abcOptions = [
  { value: "A", label: "A products" },
  { value: "B", label: "B products" },
  { value: "C", label: "C products" },
  { value: "D", label: "D products" },
  { value: "E", label: "E products" },
];

const BasicInfoFilters = ({ params, categoryLevels, suppliers }: PropsType) => {
  return (
    <Collapse title="Basic Info">
      <div className={styles.wrapper}>
        <Section>
          <MultiOptionsFilter params={params} paramName="abc" title="ABC" options={abcOptions} />
        </Section>
        <Section>
          <CategoriesFilter params={params} categoryLevels={categoryLevels} />
        </Section>
        <Section>
          <MultiOptionsFilter
            params={params}
            paramName="itemFulfillmentTypes"
            title="Item Fulfillment Type"
            options={itemFulfillmentTypeOptions.filter(option => option.value)}
          />
        </Section>
        <Section>
          <MultiOptionsFilter
            params={params}
            paramName="supplierIds"
            title="Suppliers"
            options={suppliers.map(supplier => ({
              label: supplier.name,
              value: supplier.id,
            }))}
            className={styles.suppliers}
            contentClassName={styles.content}
            checkboxClassName={styles.checkbox}
          />
        </Section>
        <Section>
          <DateFilter params={params} />
        </Section>
      </div>
    </Collapse>
  );
};

export default BasicInfoFilters;

import React from "react";
import rowStyles from "../../styles.module.scss";
import formStyles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as ArrowUp } from "../../../../../assets/icons/shared/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../../../../assets/icons/shared/arrowDown.svg";
import Button from "../../../../common/Button";

type PropsType = {
  total: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
};

const VariantPaginationFormLine = ({ total, page, pageSize, onPageChange }: PropsType) => {
  const pagesCount = total % pageSize ? Math.floor(total / pageSize) + 1 : total / pageSize;
  const firstItem = (page - 1) * pageSize + 1;
  const lastItem = page * pageSize < total ? page * pageSize : total;

  return (
    <div className={classNames(rowStyles.table__row, rowStyles.active)}>
      <div className={formStyles.pagination}>
        <div className={formStyles.pagination__label}>
          {firstItem} - {lastItem} of {total}
        </div>
        <div>
          <Button
            className={formStyles.pagination__button}
            type="transparent"
            icon={<ArrowUp />}
            onClick={() => onPageChange(page - 1)}
            disabled={page === 1}
          />
          <Button
            className={formStyles.pagination__button}
            type="transparent"
            icon={<ArrowDown />}
            onClick={() => onPageChange(page + 1)}
            disabled={page === pagesCount}
          />
        </div>
      </div>
    </div>
  );
};

export default VariantPaginationFormLine;

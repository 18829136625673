import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import {
  getInitialProductVariantWithSaleChannels,
  Product,
  ProductVariant,
  Supplier,
} from "types/catalogTypes";
import { getFieldWidth, getLineFieldByName, getLineFields } from "../../helpers/fields";
import ActionList, { ActionListOption } from "components/common/ActionList";
import LineField from "../LineField";
import { CategoryLevel } from "types/categoryLevelTypes";
import { ErrorType } from "types/errorsTypes";
import { ReactComponent as ActionsIcon } from "assets/icons/shared/actionsIcon.svg";
import rowStyles from "../../styles.module.scss";
import ProductVariantsModal from "../../../ProductVariantsModal";
import VariantsSection from "./VariantsSection";
import { TableColumnType } from "types/tableColumnType";
import { validateProduct } from "../../helpers/validation";
import { getFieldError } from "../../helpers/errors";
import { useHistory } from "react-router-dom";
import { SaleChannel } from "../../../../../types/saleChannelsTypes";
import CheckBox from "../../../../common/CheckBox";
import { SelectedProductsType } from "../../index";

type PropsType = {
  setProductsData: (products: (prev: Array<Product>) => Product[]) => void;
  categoryLevels: Array<CategoryLevel>;
  fields: Array<TableColumnType>;
  product: Product;
  disable: boolean;
  hasDifferences: boolean;
  showDeleteModal: (id: string, active: boolean) => void;
  showDeleteVariantModal: (productId: string, variantId: string) => void;
  productDataFields: Array<{ data: any; id: string }>;
  errors: ErrorType | null | undefined;
  setErrors: any;
  suppliers: Supplier[];
  saleChannels: SaleChannel[];
  limitedMode?: boolean;
  withCheckbox?: boolean;
  checkboxesDisabled?: boolean;
  selectedProducts: SelectedProductsType;
  onProductSelect: (id: string, value: boolean) => void;
  onVariantSelect: (productId: string, variantId: string) => void;
};

const ProductLineForm = ({ setProductsData, fields, product, ...props }: PropsType) => {
  const {
    categoryLevels,
    disable,
    showDeleteModal,
    hasDifferences,
    productDataFields,
    errors,
    setErrors,
    suppliers,
    showDeleteVariantModal,
    saleChannels,
    limitedMode,
    withCheckbox,
    selectedProducts,
    onProductSelect,
    onVariantSelect,
    checkboxesDisabled,
  } = props;
  const [showChildren, setShowChildren] = useState<boolean>(false);
  const [bundleModalActive, setBundleModalActive] = useState<boolean>(false);
  const [productFormFields, setProductFormFields] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    setProductFormFields(productDataFields.find(item => item.id === product.id));
  }, [productDataFields]);

  const handleDeleteVariant = (index: number, product: Product) => {
    const variant = product.variants[index];
    if (variant.defaultVariant.id && variant.defaultVariant.id !== "0") {
      showDeleteVariantModal(product.id, variant.defaultVariant.id);
      return;
    }

    const newVariants = product.variants.filter((variant, i) => i !== index);
    setProductsData(prev =>
      prev.map(prevProduct => {
        if (prevProduct.id === product.id) {
          product = { ...product, variants: newVariants };
          validateProduct(product, setErrors);
          return product;
        } else {
          return prevProduct;
        }
      }),
    );
  };

  const handleDeleteBundleVariant = (index: number, product: Product) => {
    const newBundleVariants = product.bundleVariants.filter((variant, i) => i !== index);
    setProductsData(prev =>
      prev.map(prevProduct =>
        prevProduct.id === product.id
          ? {
              ...product,
              bundleVariants: newBundleVariants,
              bundleVariantIds: newBundleVariants.map(
                bundleVariant => bundleVariant.defaultVariant.id,
              ),
            }
          : prevProduct,
      ),
    );
  };

  const handleSelectToBundle = (selected: ProductVariant[]) => {
    const newProduct = {
      ...product,
      bundleVariants: [...selected],
      bundleVariantIds: selected.map(item => item.defaultVariant.id),
    };
    setBundleModalActive(false);
    setProductFormFields({
      data: getLineFields(newProduct, fields),
      id: product.id,
    });
    setProductsData(prev =>
      prev.map(prevProduct => (prevProduct.id === product.id ? newProduct : prevProduct)),
    );
  };

  const handleAddNewVariant = (product: Product) => {
    const newVariants = [
      getInitialProductVariantWithSaleChannels(saleChannels),
      ...product.variants,
    ];
    setProductFormFields({
      data: getLineFields({ ...product, variants: newVariants }, fields),
      id: product.id,
    });
    validateProduct({ ...product, variants: newVariants }, setErrors);
    setProductsData(prev =>
      prev.map(prevProduct =>
        prevProduct.id === product.id ? { ...product, variants: newVariants } : prevProduct,
      ),
    );
    setShowChildren(true);
  };

  const handleChangeProductValue = (product: Product) => {
    validateProduct(product, setErrors);
    setProductsData((prev: Array<Product>) => {
      const changedProduct = prev.find(item => item.id === product.id);
      return changedProduct
        ? prev.map(item => (item.id === product.id ? product : item))
        : [...prev, product];
    });
  };

  const productActions = () => {
    const actions: (ActionListOption | null)[] = [
      {
        text: "Open product page",
        onAction: () => history.push(`/catalog/products/${product.id}`),
      },
    ];

    if (limitedMode) {
      return actions;
    }

    return [
      ...actions,
      {
        text: "Delete",
        onAction: () => showDeleteModal(product.id, true),
        disable: hasDifferences,
      },
      !!product.variants.length || !!product.bundleVariants.length
        ? {
            text: product.variants.length
              ? "Add variant"
              : product.bundleVariants.length
              ? "Add product"
              : "",
            onAction: () =>
              product.variants.length
                ? handleAddNewVariant(product)
                : product.bundleVariants.length
                ? setBundleModalActive(true)
                : () => undefined,
          }
        : null,
    ];
  };

  const isIndeterminate = useMemo(() => {
    const selectedProduct = selectedProducts.get(product.id);
    return (
      selectedProduct && !!product.variants.length && selectedProduct.size < product.variants.length
    );
  }, [selectedProducts]);

  return productFormFields ? (
    <>
      <div
        className={classNames(
          rowStyles.table__row,
          { [rowStyles.disable]: disable },
          { [rowStyles.active]: showChildren },
        )}
      >
        <>
          <div
            className={classNames(rowStyles.table__item, rowStyles.actions, {
              [rowStyles.big]: withCheckbox,
            })}
          >
            <ActionList className={rowStyles.actions__list} options={productActions()}>
              <ActionsIcon className={rowStyles.icon} />
            </ActionList>

            {withCheckbox && (
              <CheckBox
                onChange={() =>
                  onProductSelect(
                    product.id,
                    isIndeterminate ? true : !selectedProducts.has(product.id),
                  )
                }
                indeterminate={isIndeterminate}
                selected={selectedProducts.has(product.id)}
                value={product.id}
                className={rowStyles.checkbox}
                disabled={checkboxesDisabled}
              />
            )}

            <div className={rowStyles.actions__variants}>
              {(!!product.variants.length || !!product.bundleVariants.length) && (
                <>
                  <span className={rowStyles.actions__qty}>
                    (
                    {product.variants.length
                      ? product.variants.length
                      : product.bundleVariants.length}
                    )
                  </span>
                  <button
                    className={classNames(rowStyles.actions__showVariants, {
                      [rowStyles.active]: showChildren,
                    })}
                    onClick={() => {
                      setShowChildren(!showChildren);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </>

        {fields.map((field, idx) => (
          <div
            key={idx}
            style={{ width: getFieldWidth(field.name) }}
            className={classNames(
              rowStyles.table__item,
              withCheckbox && idx === 0 && rowStyles.leftOffset,
              field.alignment === "center" && rowStyles.alignCenter,
              field.alignment === "right" && rowStyles.alignRight,
            )}
          >
            {productFormFields.data.product.hasOwnProperty(field.name) && (
              <LineField
                lineField={getLineFieldByName(productFormFields.data, field)}
                lineFieldName={field.name}
                categoryLevels={categoryLevels}
                product={product}
                setProduct={product => handleChangeProductValue(product)}
                error={
                  errors
                    ? getFieldError(
                        errors.productErrors,
                        getLineFieldByName(productFormFields.data, field),
                      )
                    : ""
                }
                suppliers={suppliers}
              />
            )}
          </div>
        ))}
      </div>

      {showChildren && !!product.variants.length && (
        <VariantsSection
          disable={disable}
          showChildren={showChildren}
          fields={fields}
          productFormFields={productFormFields}
          categoryLevels={categoryLevels}
          product={product}
          errors={errors}
          handleDeleteVariant={handleDeleteVariant}
          handleChangeProductValue={handleChangeProductValue}
          suppliers={suppliers}
          limitedMode={limitedMode}
          selectedVariants={selectedProducts.get(product.id) ?? new Set()}
          withCheckbox={withCheckbox}
          checkboxesDisabled={checkboxesDisabled}
          onVariantSelect={variantId => onVariantSelect(product.id, variantId)}
        />
      )}

      {showChildren &&
        !!product.bundleVariants.length &&
        product.bundleVariants.map((bundleVariant, variantIndex) => (
          <div
            className={classNames(
              rowStyles.table__row,
              { [rowStyles.disable]: disable },
              { [rowStyles.active]: showChildren },
            )}
            key={variantIndex}
          >
            <div
              className={classNames(rowStyles.table__item, rowStyles.actions, {
                [rowStyles.big]: withCheckbox,
              })}
            >
              {!limitedMode && (
                <ActionList
                  className={rowStyles.actions__list}
                  options={[
                    {
                      text: "Delete",
                      onAction: () => handleDeleteBundleVariant(variantIndex, product),
                      disable: product.bundleVariants.length <= 1,
                    },
                  ]}
                >
                  <ActionsIcon className={rowStyles.icon} />
                </ActionList>
              )}
            </div>

            {fields.map((field, idx) => (
              <div
                key={idx}
                style={{ width: getFieldWidth(field.name) }}
                className={classNames(
                  rowStyles.table__item,
                  withCheckbox && idx === 0 && rowStyles.leftOffset,
                  field.alignment === "center" && rowStyles.alignCenter,
                  field.alignment === "right" && rowStyles.alignRight,
                )}
              >
                {productFormFields.data.bundleVariants[variantIndex].hasOwnProperty(field.name) && (
                  <LineField
                    lineField={getLineFieldByName(
                      productFormFields.data,
                      field,
                      variantIndex,
                      "bundle",
                    )}
                    categoryLevels={categoryLevels}
                    lineFieldName={field.name}
                    product={product}
                    setProduct={product => handleChangeProductValue(product)}
                    error=""
                    productForCategoryLevels={product.bundleVariants[variantIndex].product}
                    suppliers={suppliers}
                  />
                )}
              </div>
            ))}
          </div>
        ))}

      {bundleModalActive && (
        <ProductVariantsModal
          active={bundleModalActive}
          setActive={setBundleModalActive}
          onSelect={handleSelectToBundle}
          selected={product.bundleVariants}
        />
      )}
    </>
  ) : null;
};

const checkDiff = (p: PropsType, n: PropsType): boolean => {
  return (
    JSON.stringify(p.product) === JSON.stringify(n.product) &&
    p.disable === n.disable &&
    p.errors === n.errors &&
    JSON.stringify(p.fields) === JSON.stringify(n.fields) &&
    JSON.stringify(p.selectedProducts) === JSON.stringify(n.selectedProducts) &&
    p.onProductSelect === n.onProductSelect &&
    p.onVariantSelect === n.onVariantSelect &&
    p.checkboxesDisabled === n.checkboxesDisabled
  );
};

export default React.memo(ProductLineForm, checkDiff);

import { DefaultVariant, Product } from "types/catalogTypes";
import { difference } from "helpers/checkDifference";
import { cloneDeep } from "lodash";
import { ErrorType } from "types/errorsTypes";
import {
  convertProductErrors,
  getChannelsMatches,
  getVariantsMatches,
} from "components/product/ProductsTable/helpers/errors";

export const filterDataOnSubmit = (newProducts: Product[], oldProducts: Product[]) => {
  const differences = difference(oldProducts, newProducts);
  const diffKeys = Object.keys(differences);

  return diffKeys.map(key => {
    const difference = differences[key];
    const product = cloneDeep(newProducts[Number(key)]);

    // compare variants
    const diffVariants = difference["variants"] ? Object.keys(difference["variants"]) : [];

    product.variants = diffVariants.map(key => {
      const variantDiffs = difference["variants"][key];
      const variant = product.variants[Number(key)];

      const diffSaleChannelsVariant =
        variantDiffs["defaultVariant"] && variantDiffs["defaultVariant"]["saleChannels"]
          ? Object.keys(variantDiffs["defaultVariant"]["saleChannels"])
          : [];

      variant.defaultVariant.saleChannels = variant.defaultVariant.saleChannels.filter(
        (saleChannel, index) => diffSaleChannelsVariant.includes(String(index)),
      );
      return variant;
    });

    // compare product sale channels

    const diffSaleChannels = difference["saleChannels"]
      ? Object.keys(difference["saleChannels"])
      : Object.keys(product.saleChannels);
    product.saleChannels = product.saleChannels.filter((saleChannel, index) =>
      diffSaleChannels.includes(String(index)),
    );

    // compare default variant sale channels
    if (product.defaultVariant) {
      const diffSaleChannelsDefaultVariant =
        difference["defaultVariant"] && difference["defaultVariant"]["saleChannels"]
          ? Object.keys(difference["defaultVariant"]["saleChannels"])
          : Object.keys(product.defaultVariant.saleChannels);
      product.defaultVariant.saleChannels = product.defaultVariant.saleChannels.filter(
        (saleChannel, index) => diffSaleChannelsDefaultVariant.includes(String(index)),
      );
    }

    return product;
  });
};

export const processProductsErrors = (
  productsErrors: any,
  sentProducts: Product[],
  productsData: Product[],
): ErrorType[] => {
  return Object.keys(productsErrors)
    .filter((key: string) => sentProducts[Number(key)])
    .map((key: string) => {
      const sentProduct = sentProducts[Number(key)];
      const sourceProduct = productsData.find(product => product.id === sentProduct.id);
      const sourceProductVariants = sourceProduct?.variants ?? [];

      // channelsMatches there are only for default variant,
      // channels for array of variants will be placed in variantsMatches
      const variantsMatches = getVariantsMatches(sentProduct.variants, sourceProductVariants);
      let channelsMatches = {};
      if (sentProduct.defaultVariant && sourceProduct?.defaultVariant) {
        channelsMatches = getChannelsMatches(
          sentProduct.defaultVariant.saleChannels,
          sourceProduct.defaultVariant.saleChannels,
        );
      }

      return {
        productId: sentProducts[Number(key)].id,
        productErrors: convertProductErrors(productsErrors[key], variantsMatches, channelsMatches),
      };
    });
};

import React, { useMemo } from "react";
import classNames from "classnames";
import { Product, Supplier } from "types/catalogTypes";
import { SaleChannel } from "types/saleChannelsTypes";
import ProductLineForm from "./components/Form";
import CreateProductForm from "./components/CreateProductForm";
import { getFieldWidth } from "./helpers/fields";
import { CategoryLevel } from "types/categoryLevelTypes";
import { ErrorType } from "types/errorsTypes";
import styles from "./styles.module.scss";
import { TableColumnType } from "types/tableColumnType";
import rowStyles from "./styles.module.scss";
import CheckBox from "../../common/CheckBox";

export type SelectedProductsType = Map<string, Set<string>>;

type PropsType = {
  productsData: Array<Product>;
  saleChannels: Array<SaleChannel>;
  categoryLevels: Array<CategoryLevel>;
  fields: Array<TableColumnType>;
  setProductsData: (products: (prev: Array<Product>) => Product[]) => void;
  isCreating?: boolean;
  hasDifferences: boolean;
  errors: Array<ErrorType> | null;
  setErrors: (errors: Array<ErrorType> | null) => void;
  showDeleteModal: (id: string, active: boolean) => void;
  showDeleteVariantModal: (productId: string, variantId: string) => void;
  productDataFields: Array<{ data: any; id: string }>;
  onCreateProductDiscard: () => void;
  suppliers: Supplier[];
  limitedMode?: boolean;
  tableWrapperClassName?: string;
  withCheckboxes?: boolean;
  selectedProducts?: SelectedProductsType;
  setSelectedProducts?: (selectedProducts: SelectedProductsType) => void;
};

const ProductsTable = React.forwardRef<HTMLDivElement, PropsType>((props, ref) => {
  const {
    productsData,
    fields,
    categoryLevels,
    onCreateProductDiscard,
    suppliers,
    setProductsData,
    isCreating = false,
    hasDifferences,
    setErrors,
    showDeleteModal,
    productDataFields,
    errors,
    showDeleteVariantModal,
    saleChannels,
    limitedMode,
    tableWrapperClassName,
    selectedProducts = new Map(),
    withCheckboxes,
    setSelectedProducts = () => undefined,
  } = props;

  const onProductSelect = (productId: string, selected: boolean) => {
    const newSelectedProducts: SelectedProductsType = new Map(selectedProducts);

    if (!selected) {
      newSelectedProducts.delete(productId);
      setSelectedProducts(newSelectedProducts);
      return;
    }

    const product = productsData.find(product => product.id === productId);
    if (!product) {
      return;
    }

    const variantIds: Set<string> = new Set();
    if (product.defaultVariant) {
      variantIds.add(product.defaultVariant.id);
    } else {
      product.variants.forEach(variant => variantIds.add(variant.defaultVariant.id));
    }

    newSelectedProducts.set(productId, variantIds);
    setSelectedProducts(newSelectedProducts);
  };

  const onVariantSelect = (productId: string, variantId: string) => {
    const newSelectedProducts = new Map(selectedProducts);

    if (!selectedProducts.has(productId)) {
      const newVariants = new Set([variantId]);
      newSelectedProducts.set(productId, newVariants);
      setSelectedProducts(newSelectedProducts);
      return;
    }

    const selectedProduct = selectedProducts.get(productId);

    if (!selectedProduct.has(variantId)) {
      selectedProduct.add(variantId);
      newSelectedProducts.set(productId, selectedProduct);
      setSelectedProducts(newSelectedProducts);
      return;
    }

    const newSelectedProduct = new Set(selectedProduct);
    newSelectedProduct.delete(variantId);
    if (newSelectedProduct.size) {
      newSelectedProducts.set(productId, newSelectedProduct);
    } else {
      newSelectedProducts.delete(productId);
    }
    setSelectedProducts(newSelectedProducts);
  };

  const onHeaderSelect = (selected: boolean) => {
    const newSelectedProducts = new Map(selectedProducts);

    if (!selected) {
      // delete product ids from that page only
      productsData.forEach(product => newSelectedProducts.delete(product.id));
      setSelectedProducts(newSelectedProducts);
      return;
    }

    productsData.forEach(product => {
      const selectedVariants: Set<string> = new Set();
      if (product.defaultVariant) {
        selectedVariants.add(product.defaultVariant.id);
      } else {
        product.variants.forEach(variant => selectedVariants.add(variant.defaultVariant.id));
      }
      newSelectedProducts.set(product.id, selectedVariants);
    });

    setSelectedProducts(newSelectedProducts);
  };

  const status = useMemo(() => {
    const countAll = productsData.length;
    let countSelected = 0;
    let hasIndeterminateProducts = false;

    productsData.forEach(product => {
      if (selectedProducts.has(product.id)) {
        const selectedProduct = selectedProducts.get(product.id);
        const hasUnselectedVariants = product.variants.some(variant => {
          return !selectedProduct.has(variant.defaultVariant.id);
        });

        if (hasUnselectedVariants) {
          hasIndeterminateProducts = true;
        } else {
          countSelected++;
        }
      }
    });

    if (!countSelected && !hasIndeterminateProducts) {
      return "unselected";
    }

    if (countAll === countSelected) {
      return "selected";
    }

    return "indeterminate";
  }, [selectedProducts, productsData]);

  return (
    <div className={styles.table} ref={ref}>
      <div className={classNames(styles.table__wrapper, tableWrapperClassName)}>
        <div className={styles.table__container}>
          <div className={classNames(styles.table__row, styles.header)}>
            <p
              className={classNames(rowStyles.table__headerItem, rowStyles.headerActions, {
                [rowStyles.big]: withCheckboxes,
              })}
            >
              {withCheckboxes && fields && (
                <CheckBox
                  onChange={() =>
                    onHeaderSelect(status === "indeterminate" ? true : status !== "selected")
                  }
                  selected={status === "selected"}
                  value={""}
                  className={rowStyles.checkbox}
                  indeterminate={status === "indeterminate"}
                  disabled={isCreating}
                />
              )}
            </p>

            {fields.map((field, idx) => (
              <p
                key={idx}
                style={{ width: getFieldWidth(field.name) }}
                title={field.title}
                className={classNames(
                  rowStyles.table__headerItem,
                  withCheckboxes && idx === 0 && rowStyles.leftOffset,
                  field.alignment === "center" && rowStyles.alignCenter,
                  field.alignment === "right" && rowStyles.alignRight,
                )}
              >
                <span>{field.title}</span>
              </p>
            ))}
          </div>
          {isCreating && (
            <CreateProductForm
              categoryLevels={categoryLevels}
              fields={fields}
              setProductsData={setProductsData}
              setErrors={setErrors}
              errors={errors ? errors.find(errors => errors.productId === "0") : null}
              onDiscard={onCreateProductDiscard}
              suppliers={suppliers}
              saleChannels={saleChannels}
              withCheckbox={withCheckboxes}
            />
          )}
          {productsData.map(product => (
            <ProductLineForm
              categoryLevels={categoryLevels}
              setProductsData={setProductsData}
              product={product}
              fields={fields}
              disable={isCreating}
              hasDifferences={hasDifferences}
              showDeleteModal={showDeleteModal}
              showDeleteVariantModal={showDeleteVariantModal}
              productDataFields={productDataFields}
              key={product.id}
              setErrors={setErrors}
              errors={errors ? errors.find(errors => errors.productId === product.id) : null}
              suppliers={suppliers}
              saleChannels={saleChannels}
              limitedMode={limitedMode}
              withCheckbox={withCheckboxes}
              checkboxesDisabled={isCreating}
              selectedProducts={selectedProducts}
              onProductSelect={onProductSelect}
              onVariantSelect={onVariantSelect}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default ProductsTable;

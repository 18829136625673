import React, { useState } from "react";
import classNames from "classnames";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import CheckboxArray, { CheckboxOption } from "../../../common/CheckboxArray";
import { CategoryLevel } from "../../../../types/categoryLevelTypes";
import { getChildCategoryLevel } from "./helpers";
import styles from "./styles.module.scss";

type PropsType = {
  params: FilterComponentProps;
  categoryLevels: Array<CategoryLevel>;
};

const CategoriesFilter = ({ params, categoryLevels }: PropsType) => {
  const [showingCategories, setShowingCategories] = useState({
    1: true,
    2: false,
    3: false,
  });

  const catLevelTwo: Array<CheckboxOption> = getChildCategoryLevel(
    categoryLevels,
    params.getValue("categoryLevelIds1"),
  );
  const catLevelThree: Array<CheckboxOption> =
    params.getValue("categoryLevelIds2") && params.getValue("categoryLevelIds2").length
      ? getChildCategoryLevel(
          categoryLevels,
          params.getValue("categoryLevelIds1"),
          params.getValue("categoryLevelIds2"),
        )
      : [];

  const getSelectOptionByFieldName = (name: string): Array<CheckboxOption> => {
    const options = {
      categoryLevelIds1: categoryLevels.map(categoryLevel => ({
        label: categoryLevel.title,
        value: categoryLevel.id,
      })),
      categoryLevelIds2: catLevelTwo,
      categoryLevelIds3: catLevelThree,
    };
    return options[name as keyof typeof options];
  };

  const handleChangeCategories = async (name: string, selected: Array<any>) => {
    await params.handleChange(name, selected);
    if (name === "categoryLevelIds1") {
      await params.handleChange("categoryLevelIds2", undefined);
      await params.handleChange("categoryLevelIds3", undefined);
    } else if (name === "categoryLevelIds2") {
      await params.handleChange("categoryLevelIds3", undefined);
    }
  };

  const handleOpenChildCat = (num: number) => {
    setShowingCategories(prev => ({
      ...prev,
      [num]: !prev[num as keyof typeof showingCategories],
    }));
  };

  return (
    <div className={styles.categories}>
      <p className={styles.title}>Categories</p>

      <div className={styles.categories__wrapper}>
        <SlideDown className={styles.categories__content}>
          <button
            onClick={() => handleOpenChildCat(1)}
            className={classNames(styles.categories__mainBtn, styles.child, {
              [styles.active]: showingCategories[1],
            })}
          >
            Category Level 1
          </button>
          <SlideDown className={styles.categories__content}>
            {showingCategories[1] ? (
              <div className={styles.categories__childContent}>
                <CheckboxArray
                  selected={params.getValue("categoryLevelIds1") ?? []}
                  options={getSelectOptionByFieldName("categoryLevelIds1")}
                  onChange={selected => handleChangeCategories("categoryLevelIds1", selected)}
                  className={styles.checkboxes}
                  checkboxClassName={styles.checkbox}
                />
              </div>
            ) : null}
          </SlideDown>

          <button
            onClick={() => handleOpenChildCat(2)}
            className={classNames(styles.categories__mainBtn, styles.child, {
              [styles.active]: showingCategories[2],
            })}
            disabled={!catLevelTwo.length}
          >
            Category Level 2
          </button>
          <SlideDown className={styles.categories__content}>
            {showingCategories[2] ? (
              <div className={styles.categories__childContent}>
                <CheckboxArray
                  selected={params.getValue("categoryLevelIds2") ?? []}
                  options={getSelectOptionByFieldName("categoryLevelIds2")}
                  onChange={selected => handleChangeCategories("categoryLevelIds2", selected)}
                  className={styles.checkboxes}
                  checkboxClassName={styles.checkbox}
                />
              </div>
            ) : null}
          </SlideDown>

          <button
            onClick={() => handleOpenChildCat(3)}
            className={classNames(styles.categories__mainBtn, styles.child, {
              [styles.active]: showingCategories[3],
            })}
            disabled={!catLevelThree.length}
          >
            Category Level 3
          </button>
          <SlideDown className={styles.categories__content}>
            {showingCategories[3] ? (
              <div className={styles.categories__childContent}>
                <CheckboxArray
                  selected={params.getValue("categoryLevelIds3") ?? []}
                  options={getSelectOptionByFieldName("categoryLevelIds3")}
                  onChange={selected => handleChangeCategories("categoryLevelIds3", selected)}
                  className={styles.checkboxes}
                  checkboxClassName={styles.checkbox}
                />
              </div>
            ) : null}
          </SlideDown>
        </SlideDown>
      </div>
    </div>
  );
};

export default CategoriesFilter;

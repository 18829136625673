import React from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { ReactComponent as CalendarIcon } from "assets/icons/shared/calendar.svg";
import { FilterComponentProps } from "../../FiltersPanel/types";
import styles from "./styles.module.scss";

type PropsType = {
  params: FilterComponentProps;
};

const DateFilter = ({ params }: PropsType) => {
  return (
    <div className={styles.filterDate}>
      <p className={styles.filterDate__title}>Date Period</p>
      <div className={styles.filterDate__wrapper}>
        <DatePicker
          selected={
            params.getValue("createdAtMin") ? new Date(params.getValue("createdAtMin")) : null
          }
          maxDate={
            params.getValue("createdAtMax") ? new Date(params.getValue("createdAtMax")) : null
          }
          onChange={(date: Date) => params.handleChange("createdAtMin", moment(date).toISOString())}
          wrapperClassName={styles.filterDate__box}
          className={styles.filterDate__field}
          placeholderText="Start date"
          popperClassName="datePopper"
        />
        <DatePicker
          selected={
            params.getValue("createdAtMax") ? new Date(params.getValue("createdAtMax")) : null
          }
          minDate={
            params.getValue("createdAtMin") ? new Date(params.getValue("createdAtMin")) : null
          }
          onChange={(date: Date) =>
            params.handleChange("createdAtMax", moment(date.setHours(23, 59, 59)).toISOString())
          }
          wrapperClassName={styles.filterDate__box}
          className={styles.filterDate__field}
          placeholderText="End date"
          popperClassName="datePopper"
        />
        <CalendarIcon className={styles.filterDate__icon} />
      </div>
    </div>
  );
};

export default DateFilter;

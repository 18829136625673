import React from "react";
import styles from "./styles.module.scss";

const SpinnerLoader = () => (
  <div className={styles.spinner}>
    <svg
      className={styles.spinner__icon}
      viewBox="0 0 42 42"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#fff"
    >
      <g transform="translate(1 1)" strokeWidth="5" fill="none" fillRule="evenodd">
        <circle strokeOpacity=".5" cx="20" cy="20" r="18" />
        <path d="M38 20c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </svg>
  </div>
);

export default SpinnerLoader;

import classNames from "classnames";
import React, { CSSProperties } from "react";
import styles from "../styles.module.scss";

type PropsType = {
  className?: string;
  rowStyle?: CSSProperties;
  children?: React.ReactNode;
};

const TableHeader = ({ rowStyle, className, children }: PropsType) => {
  return (
    <div className={classNames(styles.row, styles.header, className)} style={rowStyle}>
      {children}
    </div>
  );
};

export default TableHeader;

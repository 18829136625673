import DiscontinuedFilter from "../DiscontinuedFilter";
import HoldFilter from "../../../common/SearchFiltersPanel/components/Filters/HoldFilter";
import Collapse from "../../../common/Collapse";
import { FilterComponentProps } from "../../../common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import { SaleChannel } from "types/saleChannelsTypes";
import ActivityFilter from "../ActivityFilter";
import Section from "../../../common/Collapse/Section";

type PropsType = {
  params: FilterComponentProps;
  salesChannels: SaleChannel[];
};

const AvailabilitySetFilters = ({ params, salesChannels }: PropsType) => {
  // const [isAllAvailableSelected, setIsAllAvailableSelected] = useState(false);
  // todo: uncomment when actions in collapse will be available

  return (
    <Collapse
      title="Availability"
      // actions={
      //   <CheckBox
      //     label="All Available"
      //     onChange={selectAllAvailable}
      //     selected={isAllAvailableSelected}
      //     value="all"
      //     className={styles.checkbox}
      //   />
      // }
    >
      <div className={styles.wrapper}>
        <Section>
          <ActivityFilter params={params} salesChannels={salesChannels} />
        </Section>
        <Section>
          <DiscontinuedFilter params={params} />
        </Section>
        <Section>
          <HoldFilter params={params} />
        </Section>
      </div>
    </Collapse>
  );
};

export default AvailabilitySetFilters;

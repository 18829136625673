import React, { useContext } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { ReactComponent as CatalogIcon } from "assets/icons/navigation/catalog.svg";
import { ReactComponent as HistoryIcon } from "assets/icons/navigation/history.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/shared/settings.svg";
import { ReactComponent as MCostIcon } from "assets/icons/shared/mcost.svg";
import { ReactComponent as SalesChannelFeedIcon } from "assets/icons/shared/fileOutlined.svg";
import { ReactComponent as UserIcon } from "assets/icons/shared/user.svg";
import styles from "./styles.module.scss";
import IrisLink from "../ContentHeader/irisLink";
import ItemWithChildren from "./ItemWithChildren";
import AppContext from "../../common/Context";
import LogoutLink from "./LogoutLink";

const isStaging = process.env.REACT_APP_ENV === "staging";

export type SidebarLinkType = {
  title: string;
  url: string;
  icon?: any;
  children?: SidebarLinkType[];
};

const links: SidebarLinkType[] = [
  {
    title: "Master Catalog",
    url: "/catalog",
    icon: CatalogIcon,
  },
  {
    title: "Master Costs",
    url: "/master-costs",
    icon: MCostIcon,
    children: [
      {
        title: "Master Costs",
        url: "/master-costs/costs",
      },
      {
        title: "Price Scheduler",
        url: "/master-costs/price-schedules",
      },
    ],
  },
  {
    title: "SC Feeds",
    url: "/sales-channel-feeds",
    icon: SalesChannelFeedIcon,
  },
  {
    title: "History",
    url: "/history",
    icon: HistoryIcon,
  },

  {
    title: "Permissions",
    url: "/permissions/users",
    icon: UserIcon,
    children: [
      {
        title: "Users",
        url: "/permissions/users",
      },
      {
        title: "Groups",
        url: "/permissions/groups",
      },
    ],
  },
  {
    title: "Settings",
    url: "/settings/currency",
    icon: SettingsIcon,
    children: [
      {
        title: "Currency",
        url: "/settings/currency",
      },
      {
        title: "Categories",
        url: "/settings/categories",
      },
      {
        title: "Suppliers",
        url: "/settings/suppliers",
      },
    ],
  },
];

const Sidebar = () => {
  const { sidebarExpanded, setSidebarExpanded } = useContext(AppContext);

  return (
    <div className={classNames(styles.sidebar, { [styles.active]: sidebarExpanded })}>
      <div
        className={classNames(styles.sidebar__wrapper, {
          [styles.staging]: isStaging,
        })}
      >
        <button
          className={classNames(styles.sidebar__button, {
            [styles.active]: sidebarExpanded,
          })}
          onClick={() => setSidebarExpanded(!sidebarExpanded)}
        >
          <p className={styles.sidebar__title}>{sidebarExpanded ? "Master Catalog" : "MC"}</p>
        </button>
      </div>
      <div className={styles.sidebar__navigation}>
        {links.map((item, index) =>
          item.children ? (
            <ItemWithChildren key={index} item={item} sidebarCollapsed={!sidebarExpanded} />
          ) : (
            <NavLink
              key={index}
              className={styles.navItem}
              to={item.url}
              activeClassName={styles.active}
            >
              {item.icon && <item.icon className={styles.navItem__icon} />}
              {sidebarExpanded && <span className={styles.navItem__text}>{item.title}</span>}
            </NavLink>
          ),
        )}
      </div>
      <div className={styles.sidebar__footer}>
        <IrisLink className={styles.footerLink} />
        <LogoutLink className={styles.footerLink} />
      </div>
    </div>
  );
};

export default Sidebar;

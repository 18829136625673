import { SelectOption } from "../components/common/Select";

export const discontinuedEuOptions: SelectOption[] = [
  { label: "Select", value: "" },
  { label: "Y", value: "Y" },
  { label: "N", value: "N" },
  { label: "T", value: "T" },
];

export const discontinuedNaOptions: SelectOption[] = [
  { label: "Select", value: "" },
  { label: "Y", value: "Y" },
  { label: "N", value: "N" },
  { label: "T", value: "T" },
];

export const newOldOptions: SelectOption[] = [
  { label: "New", value: true },
  { label: "Old", value: false },
];

export const oversizedOptions: SelectOption[] = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const itemFulfillmentTypeOptions: SelectOption[] = [
  { label: "Select", value: "" },
  { label: "Component", value: "Component" },
  { label: "Core", value: "Core" },
  { label: "One-time buy", value: "One-time buy" },
  { label: "Open Stock", value: "Open Stock" },
  { label: "Physical Bundle", value: "Physical Bundle" },
  { label: "SET", value: "SET" },
  { label: "Virtual Bundle", value: "Virtual Bundle" },
];

export const paymentTermsOptions: SelectOption[] = [{ label: "", value: "" }];

export const cooOptions: SelectOption[] = [
  { label: "Select", value: "" },
  { label: "China", value: "China" },
  { label: "Vietnam", value: "Vietnam" },
  { label: "Thailand", value: "Thailand" },
  { label: "South Korea", value: "South Korea" },
  { label: "Myanmar", value: "Myanmar" },
  { label: "Taiwan", value: "Taiwan" },
  { label: "Turkey", value: "Turkey" },
  { label: "Mexico", value: "Mexico" },
  { label: "India", value: "India" },
];

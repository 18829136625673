import React, { useState } from "react";
import styles from "./styles.module.scss";
import Modal from "components/common/Modal";
import CategorySelect from "components/product/ProductsTable/components/LineField/components/CategorySelect";
import { LineFieldType } from "components/product/ProductsTable/types";
import { getInitialProduct, Product } from "types/catalogTypes";
import { CategoryLevel } from "types/categoryLevelTypes";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit: (categoryId1: string, categoryId2: string, categoryId3: string) => void;
  categoryLevels: CategoryLevel[];
  isLoading?: boolean;
};

const CategoriesModal = ({ active, setActive, onSubmit, ...props }: PropsType) => {
  const { categoryLevels, isLoading } = props;
  const [product, setProduct] = useState<Product>(getInitialProduct());

  const defaultLine: LineFieldType = {
    productId: "",
    type: "selectCategoryLevels",
    formName: "",
    saleChannelId: "0",
    alignment: "left",
  };

  const categoryLineFields = {
    categoryLevel1: { ...defaultLine, formName: "categoryLevelId1" },
    categoryLevel2: { ...defaultLine, formName: "categoryLevelId2" },
    categoryLevel3: { ...defaultLine, formName: "categoryLevelId3" },
  };

  const changeCategoryLevel = (value: any, name: string) => {
    if (name === "categoryLevelId1") {
      setProduct(prev => ({ ...prev, categoryLevelId2: "0", categoryLevelId3: "0" }));
    } else if (name === "categoryLevelId2") {
      setProduct(prev => ({ ...prev, categoryLevelId3: "0" }));
    }
    setProduct(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    onSubmit(product.categoryLevelId1, product.categoryLevelId2, product.categoryLevelId3);
  };

  return (
    <Modal
      title="Change Categories"
      active={active}
      setActive={setActive}
      size="small"
      onSubmit={handleSubmit}
      saveButtonText="Apply"
      saveButtonClassName={styles.saveButton}
      loading={isLoading}
    >
      <div className={styles.modal}>
        <div className={styles.title}>Add every category level for chosen products</div>

        <div className={styles.selects}>
          <CategorySelect
            lineField={categoryLineFields.categoryLevel1}
            categoryLevels={categoryLevels}
            product={product}
            lineFieldName="categoryLevel1"
            value={product.categoryLevelId1}
            onChange={changeCategoryLevel}
            className={styles.select}
            size="large"
            label="Category Level 1"
            parentClassName={styles.fieldMedium}
          />
          <CategorySelect
            lineField={categoryLineFields.categoryLevel2}
            categoryLevels={categoryLevels}
            product={product}
            lineFieldName="categoryLevel2"
            value={product.categoryLevelId2}
            onChange={changeCategoryLevel}
            className={styles.select}
            size="large"
            label="Category Level 2"
            parentClassName={styles.fieldMedium}
          />
          <CategorySelect
            lineField={categoryLineFields.categoryLevel3}
            categoryLevels={categoryLevels}
            product={product}
            lineFieldName="categoryLevel3"
            value={product.categoryLevelId3}
            onChange={changeCategoryLevel}
            className={styles.select}
            size="large"
            label="Category Level 3"
            parentClassName={styles.fieldMedium}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CategoriesModal;

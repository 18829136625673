import Collapse from "../../../common/Collapse";
import { FilterComponentProps } from "../../../common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import PriceFilter from "../PriceFilter";
import { SaleChannel } from "types/saleChannelsTypes";

type PropsType = {
  params: FilterComponentProps;
  salesChannels: SaleChannel[];
};

const PriceSetFilters = ({ params, salesChannels }: PropsType) => {
  return (
    <Collapse title="Price & Margin">
      <div className={styles.wrapper}>
        <PriceFilter params={params} salesChannels={salesChannels} />
      </div>
    </Collapse>
  );
};

export default PriceSetFilters;

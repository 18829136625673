import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Modal from "components/common/Modal";
import Table from "components/common/Table";
import { TableColumnType } from "types/tableColumnType";
import Toggler from "components/common/Toggler";
import Select from "components/common/Select";
import { DropResult } from "react-beautiful-dnd";
import Button from "components/common/Button";
import { ReactComponent as DndIcon } from "assets/icons/shared/dndIcon.svg";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit: (columns: TableColumnType[]) => void;
  columns: TableColumnType[];
  onResetToDefault: () => void;
};

const alignmentOptions = [
  { label: "Left", value: "left" },
  { label: "Center", value: "center" },
  { label: "Right", value: "right" },
];

const reorder = (list: TableColumnType[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, index) => {
    item.position = index + 1;
    return item;
  });
};

const ColumnsModal = ({ active, setActive, onSubmit, columns, onResetToDefault }: PropsType) => {
  const [localColumns, setLocalColumns] = useState<TableColumnType[]>([...columns]);

  useEffect(() => {
    setLocalColumns([...columns]);
  }, [columns]);

  const handleFieldChanged = (value: any, index: number, field: string) => {
    const newLocalColumns = localColumns.map((column, columnIndex) => {
      if (columnIndex === index) {
        return { ...column, [field]: value };
      }
      return column;
    });
    setLocalColumns(newLocalColumns);
  };

  const handleCloseModal = () => {
    setActive(false);
    setLocalColumns([...columns]);
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(localColumns, result.source.index, result.destination.index);

    setLocalColumns(items);
  };

  return (
    <Modal
      title="Columns management"
      active={active}
      setActive={setActive}
      size="medium"
      onSubmit={() => onSubmit(localColumns)}
      saveButtonText="Apply Changes"
      cancelButtonText="Cancel"
      className={styles.modal__wrapper}
      onClose={handleCloseModal}
      secondaryButton={
        <Button onClick={onResetToDefault} size="large">
          Reset To Default
        </Button>
      }
    >
      <div className={styles.modal__header}>
        <span className={styles.modal__description}>
          Use activity switchers to define the columns that you would like to be shown, update the
          order and alignment
        </span>
      </div>

      <div className={styles.modal__content}>
        <Table
          items={localColumns}
          fixedHeader
          contentClassName={styles.modal__tableContent}
          draggable={true}
          onDragEnd={onDragEnd}
          columns={[
            { name: "", columnWidth: "60px" },
            { name: "Column Name", columnWidth: "2fr" },
            { name: "Activity", columnWidth: "1fr" },
            { name: "Alignment", columnWidth: "1fr" },
          ]}
          rows={(item: TableColumnType, index: number) => ({
            key: `key_${item.title}`,
            fields: [
              <Button
                className={styles.dndButton}
                icon={<DndIcon className={styles.dndIcon} />}
                onClick={() => undefined}
                type="transparent"
              />,
              item.title,
              <Toggler
                value={item.isActive}
                onChange={value => handleFieldChanged(value, index, "isActive")}
                name="isActive"
                disabled={item.isRequired}
              />,
              <Select
                options={alignmentOptions}
                onSelect={value => handleFieldChanged(value, index, "alignment")}
                selected={item.alignment}
              />,
            ],
          })}
        />
      </div>
    </Modal>
  );
};

export default ColumnsModal;

import React, { useCallback } from "react";
import { useField } from "formik";
import InputText, { InputPropsType } from "../../common/InputText";

type PropsType = {
  name: string;
};

const FormInput = ({ name, ...props }: PropsType & InputPropsType) => {
  const [field, meta] = useField(name);
  const onChange = useCallback(
    (value: any, name: string) => {
      value = props.type === "number" ? Number(value) : value;
      field.onChange({ target: { value, name } });
    },
    [field],
  );
  return (
    <InputText
      {...props}
      onChange={onChange}
      name={name}
      value={String(field.value)}
      error={meta.error ?? ""}
    />
  );
};

export default FormInput;

import { ErrorType, ProductErrorType } from "../types/errorsTypes";
import styles from "../pages/MasterCostPage/MasterCosts/styles.module.scss";
import React from "react";

export const getProductErrorsString = (errors: ErrorType[]) => {
  return (
    <>
      <div className={styles.productError}>
        {errors &&
          errors.map((error: ErrorType) => {
            return [
              <ul key={error.productId}>
                <h4>Product Id: {error.productId}</h4>
                {error.productErrors &&
                  error.productErrors.map((error: ProductErrorType) => {
                    return [
                      <li key={error.path}>
                        Field name: {error.path}, <br />
                        message: {error.message}
                      </li>,
                    ];
                  })}
              </ul>,
            ];
          })}
      </div>
    </>
  );
};

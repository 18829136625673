export const getNumberFieldStep = (name: string): string => {
  return name === "cartonLength" ||
    name === "cartonWidth" ||
    name === "cartonHeight" ||
    name === "cartonWeight" ||
    name === "eachLength" ||
    name === "eachWidth" ||
    name === "eachHeight" ||
    name === "eachWeight"
    ? "0.01"
    : "1";
};

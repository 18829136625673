import React, { useState } from "react";
import styles from "./styles.module.scss";
import Modal from "components/common/Modal";
import InputText from "components/common/InputText";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit: () => void;
  productsCount: number;
  isLimitReached: boolean;
  isLoading?: boolean;
};

const textExample = "Yes, I am sure";

const ApplyChangesModal = ({ active, setActive, onSubmit, ...props }: PropsType) => {
  const { productsCount, isLimitReached, isLoading } = props;
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = () => {
    if (inputValue === textExample) {
      setError("");
      onSubmit();
    } else {
      setError(`string is not equal to ${textExample}`);
    }
  };

  return (
    <Modal
      title="Apply changes"
      active={active}
      setActive={setActive}
      size="small"
      onSubmit={handleSubmit}
      saveButtonText="Apply"
      disabled={!inputValue}
      saveButtonClassName={styles.saveButton}
      loading={isLoading}
    >
      <div className={styles.modal}>
        <div>You selected {productsCount} products for update.</div>

        {isLimitReached && (
          <div>
            This scope of products will be cut to 1000 (as it is maximum for update at once).
          </div>
        )}

        <div className={styles.title}>Are You sure about applying this products?</div>

        <div className={styles.description}>
          Send <strong>{textExample}</strong> to confirm you really want to update.
        </div>

        <InputText
          name="apply"
          value={inputValue}
          size="large"
          placeholder={textExample}
          onChange={setInputValue}
          error={error}
        />
      </div>
    </Modal>
  );
};

export default ApplyChangesModal;

import React, { useState } from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ReactComponent as ArrowIcon } from "assets/icons/shared/arrowDown.svg";

type PropsType = {
  children: React.ReactNode;
  className?: string;
  title?: string;
  actions?: React.ReactElement;
};

const Collapse = ({ children, className, title, actions }: PropsType) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={classNames(styles.collapse, className, isOpened && styles.opened)}>
      <div className={styles.header} onClick={() => setIsOpened(!isOpened)}>
        <div className={styles.wrapper}>
          <div>{title}</div>
          <div onClick={e => e.stopPropagation()}>{actions}</div>
        </div>
        <ArrowIcon className={classNames(styles.chevron, isOpened && styles.active)} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Collapse;

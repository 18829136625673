import React, { useState } from "react";
import styles from "./styles.module.scss";
import Modal from "components/common/Modal";
import Select from "components/common/Select";
import { itemFulfillmentTypeOptions } from "helpers/predefinedOptions";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit: (itemFulfillmentType: string) => void;
  isLoading?: boolean;
};

const ItemFulfillmentTypeModal = ({ active, setActive, onSubmit, isLoading }: PropsType) => {
  const [itemFulfillmentType, setItemFulfillmentType] = useState("");

  const handleSubmit = () => {
    onSubmit(itemFulfillmentType);
  };

  return (
    <Modal
      title="Change Item Fulfillment type"
      active={active}
      setActive={setActive}
      size="small"
      disabled={!itemFulfillmentType}
      onSubmit={handleSubmit}
      saveButtonText="Apply"
      saveButtonClassName={styles.saveButton}
      loading={isLoading}
    >
      <div className={styles.modal}>
        <div className={styles.title}>Add item fulfillment type for chosen products</div>

        <Select
          options={itemFulfillmentTypeOptions}
          selected={itemFulfillmentType}
          onSelect={setItemFulfillmentType}
          label="Item Fulfillment type"
          fullWidth
          size="large"
        />
      </div>
    </Modal>
  );
};

export default ItemFulfillmentTypeModal;

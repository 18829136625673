import React, { useContext } from "react";
import classNames from "classnames";
import { Route } from "react-router-dom";
import styles from "./styles.module.scss";
import AppContext from "../../common/Context";

const TemplateRoute = ({ component, ...props }: any) => {
  const { sidebarExpanded } = useContext(AppContext);

  return (
    <Route {...props}>
      <div className={classNames(styles.app__content, { [styles.active]: sidebarExpanded })}>
        {component}
      </div>
    </Route>
  );
};

export default TemplateRoute;

import React from "react";
import classNames from "classnames";
import styles from "./styles.module.scss";

export interface IconTogglerPropsType {
  value: boolean;
  onChange: (e: any, name: string) => void;
  name: string;
  disabled?: boolean;
  className?: string;
  iconStatusTrue: React.ReactNode;
  iconStatusFalse: React.ReactNode;
  label?: string;
}

const IconToggler: React.FC<IconTogglerPropsType> = ({
  value,
  onChange,
  name,
  disabled,
  className,
  ...props
}) => {
  const { iconStatusTrue, iconStatusFalse, label } = props;
  return (
    <div
      className={classNames(styles.toggler, className, {
        [styles.disabled]: disabled || value === null,
      })}
    >
      {label && <label className={styles.toggler__label}>{label}</label>}
      <div className={styles.toggler__wrapper}>
        <label className={styles.toggler__checkbox}>
          <input
            type="checkbox"
            id={name}
            onChange={e => onChange(e.target.checked, name)}
            checked={value ?? false}
          />
          {value ? iconStatusTrue : iconStatusFalse}
        </label>
      </div>
    </div>
  );
};

export default IconToggler;

import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";

type PropsType = {
  className?: any;
};

const siteLink =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_SITE_LINK
    : process.env.REACT_APP_DEV_SITE_LINK;

const IrisLink = ({ className }: PropsType) => (
  <a
    className={classNames(styles.header__link, { [className]: !!className })}
    href={siteLink}
    target="_blank"
  >
    <span>Go Arteza</span>
    <span className={styles.header__linkText}>IRIS</span>
  </a>
);

export default IrisLink;

import _ from "lodash";

export const difference = (object: any, base: any) => {
  const changes = (object: any, base: any) => {
    return _.transform(object, (result: any, value: any, key: any) => {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  };
  return changes(object, base);
};

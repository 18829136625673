import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import AppContext from "components/common/Context";
import { catalogService } from "api/catalog";
import {
  ProductsBulkActionType,
  Product,
  ProductVariant,
  Supplier,
  getInitialProductIdsData,
  ProductsBulkUpdateRequest,
} from "types/catalogTypes";
import { SaleChannel } from "types/saleChannelsTypes";
import useDebounce from "helpers/useDebounce";
import useDelayedState from "helpers/useDelayedState";
import SearchFiltersPanel from "components/common/SearchFiltersPanel";
import Page from "components/common/Page";
import Card from "components/common/Card";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import PageFooter from "components/common/PageFooter";
import ProductsTable, { SelectedProductsType } from "components/product/ProductsTable";
import ChangesBanner from "components/common/ChangesBanner";
import { CategoryLevel } from "types/categoryLevelTypes";
import { ReactComponent as Plus } from "assets/icons/shared/plus.svg";
import { ReactComponent as ExportIcon } from "assets/icons/shared/export.svg";
import { difference } from "helpers/checkDifference";
import {
  FilterComponentProps,
  FilterType,
} from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import { ErrorType, ProductErrorType } from "types/errorsTypes";
import TableLoader from "components/common/TableLoader";
import { getLineFields } from "components/product/ProductsTable/helpers/fields";
import styles from "./styles.module.scss";
import { TableColumnType } from "types/tableColumnType";
import { ReactComponent as ImportIcon } from "assets/icons/shared/import.svg";
import ImportModal, { SubmitImportMethods } from "./components/ImportModal";
import { getBase64 } from "helpers/files";
import { ToastType } from "components/common/Toast/types";
import { Toast } from "components/common/Toast";
import CreateProductModal from "./components/CreateProductModal";
import { defaultErrorMessage } from "helpers/toastMessages";
import { filterDataOnSubmit, processProductsErrors } from "./helpers";
import { normalizeFilterParams } from "../../helpers/filters";
import TableActionsPanel from "../../components/common/TableActionsPanel";
import classNames from "classnames";
import ApplyChangesModal from "./components/ApplyChangesModal";
import CategoriesModal from "./components/CategoriesModal";
import ItemFulfillmentTypeModal from "./components/ItemFulfillmentTypeModal";
import SalesChannelsModal from "./components/SalesChannelsModal";
import AvailabilitySetFilters from "components/product/CatalogFilters/AvailabilitySetFilters";
import BasicInfoFilters from "components/product/CatalogFilters/BasicInfoFilters";
import InventorySetFilters from "components/product/CatalogFilters/InventorySetFilters";
import { SelectOption } from "components/common/Select";
import PriceSetFilters from "components/product/CatalogFilters/PriceSetFilters";
import { getProductErrorsString } from "../../helpers/errors";

const TableType = "main";

const bulkActionTypesMatches = {
  activate: "saleChannelActivity",
  deactivate: "saleChannelActivity",
  categories: "categories",
  itemFulfillmentType: "itemFulfillmentType",
};

const templateOptions: SelectOption[] = [
  { label: "Activities", value: "activities" },
  { label: "NPL", value: "npl" },
  { label: "Master costs", value: "masterCosts" },
  { label: "Retail prices", value: "prices" },
];

const CatalogPage = () => {
  const { disabled } = useContext(AppContext);
  const [mounted, setMounted] = useState<boolean>(false);
  const [delayedLoading, setDelayedLoading] = useDelayedState(false);
  const [products, setProducts] = useState<Array<Product>>([]);
  const [productsData, setProductsData] = useState<Array<Product>>([]);
  const [saleChannels, setSaleChannels] = useState<Array<SaleChannel>>([]);
  const [fields, setFields] = useState<Array<TableColumnType>>([]);
  const productDataFields = useMemo(
    () =>
      products.length && fields.length
        ? products.map(product => ({
            data: getLineFields(product, fields),
            id: product.id,
          }))
        : [],
    [products, fields],
  );
  const [categoryLevels, setCategoryLevels] = useState<Array<CategoryLevel>>([]);
  const [deleteModalActive, setDeleteModalActive] = useState<boolean>(false);
  const [deleteVariantModalActive, setDeleteVariantModalActive] = useState(false);
  const [deletedProduct, setDeletedProduct] = useState<Product | null>(null);
  const [deletedVariant, setDeletedVariant] = useState<ProductVariant | null>(null);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<ErrorType> | null>(null);
  const [activeFilters, setActiveFilters] = useState<boolean>(false);
  const hasDifferences = !!difference(products, productsData).length;
  const source: CancelTokenSource = axios.CancelToken.source();
  const history = useHistory();
  const location = useLocation();
  const debouncedLocation = useDebounce(location, 500);
  const sortOptions = [
    { label: "Date (newest first)", value: "newesttooldest" },
    { label: "Date (oldest first)", value: "oldesttonewest" },
    { label: "Product (A-Z)", value: "a-z" },
    { label: "Product (Z-A)", value: "z-a" },
  ];
  const tableRef = useRef<HTMLDivElement>(null);
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [showImportModal, setShowImportModal] = useState(false);
  const isEmptyBundleCreating =
    isCreating && productsData[0].type === "bundle" && !productsData[0].bundleVariantIds.length;
  const [toast, setToast] = useState<ToastType | null>(null);
  const [createModalActive, setCreateModalActive] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<SelectedProductsType>(new Map());
  const [bulkAction, setBulkAction] = useState<ProductsBulkActionType>("activate");
  const [bulkActionModalActive, setBulkActionModalActive] = useState(false);
  const [applyChangesModalActive, setApplyChangesModalActive] = useState(false);
  const [bulkUpdateRequest, setBulkUpdateRequest] = useState<ProductsBulkUpdateRequest>({});
  const [isBulkLimitReached, setIsBulkLimitReached] = useState(false);
  const [productIdsData, setProductIdsData] = useState(getInitialProductIdsData());
  const [isModalLoading, setIsModalLoading] = useState(false);

  const filterParams = useMemo(() => {
    const urlParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return {
      ...urlParams,
      sort: urlParams.hasOwnProperty("sort") ? urlParams.sort : "newesttooldest",
    };
  }, [debouncedLocation]);

  const page = useMemo(() => {
    const urlParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return urlParams.page ? Number(urlParams.page) : 1;
  }, [debouncedLocation]);

  const pageSize = useMemo(() => {
    const urlParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return urlParams.pageSize ? Number(urlParams.pageSize) : 50;
  }, [debouncedLocation]);

  const filters = (params: FilterComponentProps): Array<FilterType> => [
    {
      component: <AvailabilitySetFilters params={params} salesChannels={saleChannels} />,
    },
    {
      component: (
        <BasicInfoFilters params={params} categoryLevels={categoryLevels} suppliers={suppliers} />
      ),
    },
    {
      component: <InventorySetFilters params={params} />,
    },
    {
      component: <PriceSetFilters params={params} salesChannels={saleChannels} />,
    },
  ];

  const fetchData = async () => {
    const normalizedParams = normalizeFilterParams(filterParams);

    const params = { page, pageSize, ...normalizedParams };
    const idsParams = { ...normalizedParams, page: undefined, pageSize: undefined };

    try {
      setDelayedLoading(true, 300);
      if (!mounted) {
        const [
          productsData,
          saleChannelsData,
          fieldsData,
          categoryLevelsData,
          suppliersData,
          productIds,
        ] = await Promise.all([
          catalogService.getProducts(params, { token: source.token }),
          catalogService.getSaleChannels(),
          catalogService.getTableColumnSettings(TableType),
          catalogService.getCategoryLevels(),
          catalogService.getSuppliers({ sort: "a-z" }),
          catalogService.getProductIds(idsParams),
        ]);
        setCategoryLevels(categoryLevelsData);
        setSuppliers(suppliersData);
        setProducts(productsData.items);
        setProductsData(productsData.items);
        setProductsCount(productsData.totalCount);
        setFields(fieldsData.length ? fieldsData : []);
        setSaleChannels(saleChannelsData.items);
        setProductIdsData(productIds);
        setMounted(true);
      } else {
        const [productsData, productIds] = await Promise.all([
          catalogService.getProducts(params, { token: source.token }),
          catalogService.getProductIds(idsParams),
        ]);
        setProducts(productsData.items);
        setProductsData(productsData.items);
        setProductsCount(productsData.totalCount);
        setProductIdsData(productIds);
      }
    } catch (e: any) {
      if (e.message !== "Request cancelled") {
        console.error(e);
        setToast(defaultErrorMessage);
      }
    } finally {
      setDelayedLoading(false, 300);
    }
  };

  useEffect(() => {
    fetchData();
    return () => source && source.cancel("Request cancelled");
  }, [page, pageSize, filterParams]);

  const handleSubmit = async (submitProductsData: Array<Product>) => {
    if (errors) {
      return;
    }

    let filteredData = [submitProductsData[0]];
    if (!isCreating) {
      filteredData = filterDataOnSubmit(submitProductsData, products);
    }

    try {
      setDelayedLoading(true, 300);
      const data = await catalogService.updateProducts(filteredData);
      const newProducts = isCreating
        ? [...data, ...products.filter((item, index) => index + 1 !== products.length)]
        : products.map(product => {
            const changedProduct = data.find(dataProduct => dataProduct.id === product.id);
            return changedProduct ?? product;
          });
      setProducts(newProducts);
      setProductsData(newProducts);
      setIsCreating(false);
    } catch (e: any) {
      if (e?.response?.data?.details) {
        console.log(e?.response);
        const productsErrors = e?.response?.data?.details[0]?.messages?.items;
        const newErrors = processProductsErrors(productsErrors, filteredData, productsData);
        setToast({ type: "error", message: getProductErrorsString(newErrors) });
        setErrors(newErrors);
      }
    } finally {
      setDelayedLoading(false, 300);
    }
  };

  const handleDiscard = () => {
    if (isCreating) {
      setIsCreating(false);
    }
    setProductsData(products);
    setErrors(null);
  };

  const showDeleteModal = (id: string, active: boolean) => {
    setDeleteModalActive(active);
    setDeletedProduct(products.find(product => product.id === id) || null);
  };

  const deleteProduct = async (id: string | null) => {
    if (id) {
      try {
        const params = { page, pageSize, ...filterParams };
        await catalogService.deleteProduct(id);
        const { items, totalCount } = await catalogService.getProducts(params, {
          token: source.token,
        });
        setProducts(items);
        setProductsData(items);
        setProductsCount(totalCount);
        setDeleteModalActive(false);
      } catch (e) {
        console.error(e);
        setToast(defaultErrorMessage);
      }
    }
  };

  const showDeleteVariantModal = (productId: string, variantId: string) => {
    setDeleteVariantModalActive(true);
    const product = products.find(product => product.id === productId) || null;
    if (product) {
      setDeletedVariant(
        product.variants.find(variant => variant.defaultVariant.id === variantId) || null,
      );
    } else {
      setDeletedVariant(null);
    }
  };

  const deleteProductVariant = async (variantId: string) => {
    if (!variantId) {
      return;
    }
    try {
      await catalogService.deleteProductVariant(variantId);
      const params = { page, pageSize, ...filterParams };
      const { items } = await catalogService.getProducts(params, {
        token: source.token,
      });
      setProducts(items);
      setProductsData(items);
      setDeleteVariantModalActive(false);
    } catch (e) {
      console.error(e);
      setToast(defaultErrorMessage);
    }
  };

  const handleExport = async (productVariantIds: string[] = []) => {
    try {
      const content = await catalogService.exportProducts(productVariantIds);
      const csvContent = atob(content);
      const blob = new Blob([csvContent], {
        type: "data:application/octet-stream;base64",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const currentTime = new Date().toLocaleDateString();
      link.href = url;
      link.setAttribute("download", `products_${currentTime}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      setToast(defaultErrorMessage);
    }
  };

  const handleImport = async (uploadedFile: File, type: string, methods: SubmitImportMethods) => {
    const { setIsLoading, setValidationErrors, setProcessingError } = methods;
    const requests: Record<string, Function> = {
      activities: catalogService.importProductActivities,
      npl: catalogService.importNpl,
      masterCosts: catalogService.importMasterCosts,
      prices: catalogService.importPrices,
    };

    try {
      setIsLoading(true);
      const encodedString = await getBase64(uploadedFile);
      await requests[type](encodedString.split("base64,")[1]);
      setIsLoading(false);
      setShowImportModal(false);
      setToast({ type: "success", message: "Import was successful" });
      fetchData();
    } catch (e: any) {
      setIsLoading(false);
      if (e?.response?.data?.details) {
        const errors = e?.response?.data?.details[0]?.messages;
        setValidationErrors(errors);
      } else {
        setProcessingError(e?.response?.data?.message);
      }
    }
  };

  const handleTemplateDownload = async (type: string) => {
    const requests: Record<string, Function> = {
      activities: catalogService.exportProductActivities,
      npl: catalogService.exportNpl,
      masterCosts: catalogService.exportMasterCosts,
      prices: catalogService.exportPrices,
    };
    try {
      const content = await requests[type]();
      const csvContent = atob(content);
      const blob = new Blob([csvContent], {
        type: "data:application/octet-stream;base64",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const currentTime = new Date().toLocaleDateString();
      link.href = url;
      link.setAttribute("download", `template_${type}_${currentTime}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setToast(defaultErrorMessage);
    }
  };

  const onColumnsChanged = async (columns: TableColumnType[]) => {
    try {
      setDelayedLoading(true, 300);
      const newColumns = await catalogService.saveTableColumnSettings(TableType, columns);
      setFields(newColumns);
    } catch (e) {
      console.error(e);
    } finally {
      setDelayedLoading(false, 300);
    }
  };

  const onColumnsReset = async () => {
    try {
      setDelayedLoading(true, 300);
      const newColumns = await catalogService.resetTableColumnSettings(TableType);
      setFields(newColumns);
    } catch (e) {
      console.error(e);
    } finally {
      setDelayedLoading(false, 300);
    }
  };

  const onCreateLine = () => {
    setIsCreating(true);
    setSelectedProducts(new Map());
    if (tableRef.current?.children[0]) {
      tableRef.current.children[0].scrollTop = 0;
      tableRef.current.children[0].scrollLeft = 0;
    }
  };

  const onCreateProduct = (type: string) => {
    setCreateModalActive(false);
    history.push(`/catalog/products/0?type=${type}`);
  };

  const onSelectionCancel = () => {
    setSelectedProducts(new Map());
  };

  const onSelectAll = () => {
    const newSelectedProducts = new Map();
    productIdsData.items.forEach(productIdData => {
      const variantIds: Set<string> = new Set(productIdData.productVariantIds);
      newSelectedProducts.set(productIdData.productId, variantIds);
    });
    setSelectedProducts(newSelectedProducts);
  };

  const onBulkAction = (type: ProductsBulkActionType) => {
    setBulkAction(type);
    setBulkActionModalActive(true);
  };

  const applyBulkChanges = async (request: ProductsBulkUpdateRequest | null = null) => {
    setIsModalLoading(true);
    request = request ?? bulkUpdateRequest;

    if (isBulkLimitReached) {
      if (bulkAction === "categories") {
        request.categories!.productIds = request.categories!.productIds.slice(0, 1000);
      } else if (bulkAction === "itemFulfillmentType") {
        request.itemFulfillmentType!.productVariantIds =
          request.itemFulfillmentType!.productVariantIds.slice(0, 1000);
      } else {
        request.saleChannelActivity!.productVariantIds =
          request.saleChannelActivity!.productVariantIds.slice(0, 1000);
      }
    }

    try {
      await catalogService.bulkUpdate(
        bulkActionTypesMatches[bulkAction],
        request ?? bulkUpdateRequest,
      );
      setSelectedProducts(new Map());
      setToast({
        message: "Successfully updated",
        type: "success",
      });
      fetchData();
      setBulkActionModalActive(false);
      setApplyChangesModalActive(false);
    } catch (e) {
      setToast({
        message: "Products have not been updated",
        type: "error",
      });
    } finally {
      setIsModalLoading(false);
    }
  };

  const onBulkSubmit = (ids: string[], request: ProductsBulkUpdateRequest) => {
    if (ids.length >= 1000) {
      setIsBulkLimitReached(true);
      setBulkActionModalActive(false);
      setApplyChangesModalActive(true);
    } else if (ids.length >= 500) {
      setIsBulkLimitReached(false);
      setBulkActionModalActive(false);
      setApplyChangesModalActive(true);
    } else {
      setIsBulkLimitReached(false);
      applyBulkChanges(request);
    }
  };

  const handleCategoriesBulkUpdate = (
    categoryId1: string,
    categoryId2: string,
    categoryId3: string,
  ) => {
    const productIds: string[] = Array.from(selectedProducts.keys());

    const request = {
      categories: {
        productIds: productIds,
        categoryLevel1: categoryId1,
        categoryLevel2: categoryId2,
        categoryLevel3: categoryId3,
      },
    };

    setBulkUpdateRequest(request);
    onBulkSubmit(productIds, request);
  };

  const handleFulfillmentTypeBulkUpdate = (itemFulfillmentType: string) => {
    const variantIds = getSelectedVariantIds();

    const request = {
      itemFulfillmentType: {
        productVariantIds: variantIds,
        itemFulfillmentType: itemFulfillmentType,
      },
    };

    setBulkUpdateRequest(request);
    onBulkSubmit(variantIds, request);
  };

  const handleActivityBulkUpdate = (salesChannelIds: string[], isActive: boolean) => {
    const variantIds = getSelectedVariantIds();

    const request = {
      saleChannelActivity: {
        productVariantIds: variantIds,
        saleChannelIds: salesChannelIds,
        isActive: isActive,
      },
    };

    setBulkUpdateRequest(request);
    onBulkSubmit(variantIds, request);
  };

  const getSelectedVariantIds = () => {
    let variantIds: string[] = [];
    selectedProducts.forEach(value => {
      variantIds = [...variantIds, ...Array.from(value)];
    });
    return variantIds;
  };

  const getSelectedVariantsCount = () => {
    let count = 0;
    selectedProducts.forEach(value => {
      count = count + value.size;
    });
    return count;
  };

  const productsCountBulkRequest =
    bulkAction === "itemFulfillmentType" && bulkUpdateRequest.itemFulfillmentType
      ? bulkUpdateRequest.itemFulfillmentType.productVariantIds.length
      : bulkAction === "categories" && bulkUpdateRequest.categories
      ? bulkUpdateRequest.categories.productIds.length
      : bulkUpdateRequest.saleChannelActivity
      ? bulkUpdateRequest.saleChannelActivity.productVariantIds.length
      : 0;

  return (
    <Page
      title="Master Catalog"
      pageAction={
        <>
          <Button
            type="transparent"
            size="medium"
            className={styles.exportButton}
            icon={<ExportIcon className={styles.exportIcon} />}
            onClick={handleExport}
          >
            Export
          </Button>
          <Button
            type="transparent"
            size="medium"
            className={styles.exportButton}
            icon={<ImportIcon className={styles.exportIcon} />}
            onClick={() => setShowImportModal(true)}
            disabled={disabled}
          >
            Import
          </Button>
          <Button
            icon={<Plus className={styles.plusIconLine} />}
            onClick={onCreateLine}
            disabled={hasDifferences || disabled}
            type="default"
            className={styles.createLineButton}
          >
            Create Line
          </Button>
          <Button
            icon={<Plus className={styles.plusIcon} />}
            onClick={() => setCreateModalActive(true)}
            disabled={hasDifferences || disabled}
            type="primary"
          >
            Create Product
          </Button>
        </>
      }
    >
      <ChangesBanner
        onSubmit={() => handleSubmit(productsData)}
        active={hasDifferences || isCreating}
        onDiscard={handleDiscard}
        disabled={disabled || isEmptyBundleCreating}
      />
      <Card className={styles.card}>
        {delayedLoading && <TableLoader />}

        {!!selectedProducts.size && (
          <TableActionsPanel
            selectedCount={getSelectedVariantsCount()}
            totalCount={productIdsData.totalCountProductVariants}
            onSelectAll={onSelectAll}
            onCancel={onSelectionCancel}
            bulkOptions={[
              { onAction: () => onBulkAction("activate"), text: "Activate Sales Channels" },
              { onAction: () => onBulkAction("deactivate"), text: "Deactivate Sales Channels" },
              { onAction: () => onBulkAction("categories"), text: "Change Categories" },
              {
                onAction: () => onBulkAction("itemFulfillmentType"),
                text: "Change Item Fulfillment Type",
              },
            ]}
            actions={
              <Button
                type="transparent"
                size="medium"
                className={classNames(styles.exportButton, styles.panel)}
                icon={<ExportIcon className={styles.exportIcon} />}
                onClick={() => handleExport(getSelectedVariantIds())}
              >
                Export
              </Button>
            }
          />
        )}

        <SearchFiltersPanel
          searchParam="query"
          setActiveFilters={setActiveFilters}
          activeFilters={activeFilters}
          initFilters={filters}
          filterParams={filterParams}
          disabled={hasDifferences}
          sortOptions={sortOptions}
          columns={fields}
          onColumnsChanged={onColumnsChanged}
          onColumnsReset={onColumnsReset}
          pageId="catalog"
        />
        <ProductsTable
          ref={tableRef}
          productsData={productsData}
          saleChannels={saleChannels}
          fields={fields.filter(field => field.isActive)}
          categoryLevels={categoryLevels}
          isCreating={isCreating}
          hasDifferences={hasDifferences}
          setProductsData={setProductsData}
          errors={errors}
          setErrors={setErrors}
          showDeleteModal={showDeleteModal}
          showDeleteVariantModal={showDeleteVariantModal}
          productDataFields={productDataFields}
          onCreateProductDiscard={() => setIsCreating(false)}
          suppliers={suppliers}
          withCheckboxes
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
        <PageFooter
          page={page}
          itemsCount={productsCount}
          pageSize={pageSize}
          disabled={hasDifferences}
        />
      </Card>

      <Modal
        active={deleteModalActive}
        setActive={setDeleteModalActive}
        title="Delete Product"
        onSubmit={() => deleteProduct(deletedProduct && deletedProduct.id)}
        saveButtonText="Delete"
      >
        <div className={styles.deleteModal}>
          <p className={styles.deleteModal__title}>Are You sure about deleting this product?</p>
          <p className={styles.deleteModal__text}>
            {deletedProduct &&
              `${deletedProduct.defaultVariant ? deletedProduct.defaultVariant.sku : ""} ${
                deletedProduct.title
              }`}
          </p>
        </div>
      </Modal>

      <Modal
        active={deleteVariantModalActive}
        setActive={setDeleteVariantModalActive}
        title="Delete Product Variant"
        onSubmit={() =>
          deletedVariant ? deleteProductVariant(deletedVariant.defaultVariant.id) : {}
        }
        saveButtonText="Delete"
      >
        <div className={styles.deleteModal}>
          <p className={styles.deleteModal__title}>
            Are You sure about deleting this product variant?
          </p>
          <p className={styles.deleteModal__text}>
            {deletedVariant &&
              `${deletedVariant.defaultVariant ? deletedVariant.defaultVariant.sku : ""} ${
                deletedVariant.fullTitle
              }`}
          </p>
        </div>
      </Modal>

      {showImportModal && (
        <ImportModal
          active={showImportModal}
          setActive={setShowImportModal}
          onSubmit={handleImport}
          onTemplateDownload={handleTemplateDownload}
          templateOptions={templateOptions}
        />
      )}

      {toast && <Toast toast={toast} onClose={() => setToast(null)} />}

      <CreateProductModal
        active={createModalActive}
        setActive={setCreateModalActive}
        onSubmit={onCreateProduct}
      />

      {applyChangesModalActive && (
        <ApplyChangesModal
          active={true}
          setActive={setApplyChangesModalActive}
          onSubmit={applyBulkChanges}
          productsCount={productsCountBulkRequest}
          isLimitReached={isBulkLimitReached}
          isLoading={isModalLoading}
        />
      )}

      {bulkActionModalActive && bulkAction === "categories" && (
        <CategoriesModal
          active={true}
          setActive={setBulkActionModalActive}
          onSubmit={handleCategoriesBulkUpdate}
          categoryLevels={categoryLevels}
          isLoading={isModalLoading}
        />
      )}

      {bulkAction === "itemFulfillmentType" && bulkActionModalActive && (
        <ItemFulfillmentTypeModal
          active={true}
          setActive={setBulkActionModalActive}
          onSubmit={handleFulfillmentTypeBulkUpdate}
          isLoading={isModalLoading}
        />
      )}

      {(bulkAction === "activate" || bulkAction === "deactivate") && bulkActionModalActive && (
        <SalesChannelsModal
          active={true}
          setActive={setBulkActionModalActive}
          onSubmit={handleActivityBulkUpdate}
          salesChannels={saleChannels}
          type={bulkAction}
          isLoading={isModalLoading}
        />
      )}
    </Page>
  );
};

export default CatalogPage;

import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

type PropsType = {
  onChange: (value: any) => void;
  checked: boolean;
  value: any;
  label?: string;
  disabled?: boolean;
  name: string;
  className?: string;
};

const Radio = ({ onChange, checked, value, label, disabled, name, className }: PropsType) => {
  return (
    <div className={classNames(styles.radio, { [styles.disabled]: disabled }, className)}>
      <label>
        <input
          type="radio"
          value={value}
          checked={checked}
          onChange={() => onChange(value)}
          disabled={disabled}
          name={name}
        />
        <div className={classNames(styles.circle, { [styles.checked]: checked })}>
          <div className={styles.bullet}></div>
        </div>
        {label}
      </label>
    </div>
  );
};

export default Radio;

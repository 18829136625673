import React, { useState } from "react";
import styles from "./styles.module.scss";
import ClickAwayListener from "../../../helpers/ClickAwayListener";
import { Popover } from "react-tiny-popover";
import classNames from "classnames";

type PropsType = {
  disabled?: boolean;
  options: Array<ActionListOption | null>;
  children: React.ReactNode;
  fullWidth?: boolean;
  className?: string;
};

export type ActionListOption = {
  onAction: () => void;
  text: string | React.ReactElement;
  disable?: boolean;
};

const ActionList = ({ disabled, options, children, fullWidth = false, className }: PropsType) => {
  const [active, setActive] = useState<boolean>(false);
  const handleClick = (action: () => void) => {
    action();
    setActive(false);
  };

  const buttonProps = {
    className: styles.actions__button,
    onClick: () => setActive(!active),
    disabled: disabled,
  };

  return (
    <div
      className={classNames(
        styles.actions,
        { [styles.active]: active },
        { [styles.disabled]: disabled },
        { [styles.fullWidth]: fullWidth },
        className,
      )}
    >
      <ClickAwayListener onClickAway={() => setActive(false)}>
        <Popover
          isOpen={active}
          positions={["bottom", "left"]}
          align="start"
          padding={2}
          containerClassName={styles.actions__options}
          content={
            <>
              {options.map((option, index) =>
                option ? (
                  <button
                    className={styles.actions__option}
                    onClick={() => handleClick(option.onAction)}
                    key={index}
                    disabled={option.disable}
                  >
                    {option.text}
                  </button>
                ) : null,
              )}
            </>
          }
        >
          {typeof children === "function" ? (
            children(buttonProps)
          ) : (
            <button {...buttonProps}>
              <span className={styles.actions__selectedValue}>{children}</span>
            </button>
          )}
        </Popover>
      </ClickAwayListener>
    </div>
  );
};

export default ActionList;

import React from "react";
import Pagination from "../Pagination";
import Select from "../Select";
import { getShowingProductsCount } from "./helpers";
import styles from "./styles.module.scss";
import qs from "qs";
import { useHistory } from "react-router-dom";

type PropsType = {
  page: number;
  pageSize: number;
  itemsCount: number;
  pageSizeOptions?: Array<{ value: number; label: string }>;
  disabled?: boolean;
};
const defaultPageSizeOptions = [
  { value: 5, label: "50/page" },
  { value: 10, label: "100/page" },
  { value: 150, label: "150/page" },
];

const PageFooter = ({
  page,
  itemsCount,
  pageSize,
  pageSizeOptions = defaultPageSizeOptions,
  disabled,
}: PropsType) => {
  const history = useHistory();

  const handleChanges = (value: number, field: string) => {
    const parsedParams = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    history.replace(
      history.location.pathname + "?" + qs.stringify({ ...parsedParams, [field]: value }),
    );
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footer__items}>
        {!!itemsCount && (
          <p className={styles.footer__count}>
            Showing {getShowingProductsCount(page, pageSize, itemsCount)} of {itemsCount}
          </p>
        )}
        <Select
          onSelect={pageSize => handleChanges(pageSize, "pageSize")}
          options={pageSizeOptions}
          selected={pageSize}
          disabled={disabled}
        />
      </div>
      <Pagination
        currentPage={page}
        pageSize={pageSize}
        onPageChanged={page => handleChanges(page, "page")}
        totalItemsCount={itemsCount}
        disabled={disabled}
      />
    </div>
  );
};

export default PageFooter;

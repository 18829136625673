import axios from "axios";

const getJwtFromStorage = (storageItem: string | null): string => {
  const user = storageItem ? JSON.parse(storageItem) : null;
  return user ? user.jwt : "";
};

const customAxios = axios.create({
  headers: {
    Authorization: getJwtFromStorage(localStorage.getItem("user"))
      ? "Bearer " + getJwtFromStorage(localStorage.getItem("user"))
      : "",
  },
});

customAxios.interceptors.request.use(config => {
  config.headers["Authorization"] = getJwtFromStorage(localStorage.getItem("user"))
    ? "Bearer " + getJwtFromStorage(localStorage.getItem("user"))
    : "";
  return config;
});

export default customAxios;

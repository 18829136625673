import { CancelToken } from "axios";
import customAxios from "../api";
import {
  Collection,
  Product,
  ProductImage,
  Supplier,
  ProductsBulkUpdateRequest,
  ProductIdsData,
  ProductVariant,
} from "../../types/catalogTypes";
import { SaleChannel } from "../../types/saleChannelsTypes";
import { FieldColumnType } from "../../types/fieldColumnType";
import { CategoryLevel, ExportCategories, ExportCategory } from "../../types/categoryLevelTypes";
import { TableColumnTableType, TableColumnType } from "../../types/tableColumnType";
import qs from "qs";
import { SaveCategoryRequest, UpdateCategoryRequest } from "../../types/categories";
import { SaveSupplierRequest, UpdateSupplierRequest } from "../../types/suppliers";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_SERVICE_API_KEY
    : process.env.REACT_APP_DEV_SERVICE_API_KEY;

const getProducts = async (
  params: any,
  ctx: { token: CancelToken },
): Promise<{ items: Array<Product>; totalCount: number }> => {
  const { data } = await customAxios.post(
    `${apiUrl}/master-catalog/products`,
    {
      relations: {
        hasVariants: true,
        hasCategoryLevels: true,
        hasDimensions: true,
        hasSaleChannels: true,
        hasBundleVariants: true,
        hasSupplier: true,
        hasCosts: true,
        hasInventory: true,
        hasMargin: true,
      },
      params,
    },
    { cancelToken: ctx.token },
  );
  return data.items
    ? { items: data.items, totalCount: data.pagination.totalCount }
    : { items: [], totalCount: 0 };
};

const getVariants = async (
  params: any,
  ctx?: { token: CancelToken },
): Promise<{ items: Array<ProductVariant>; totalCount: number }> => {
  const { data } = await customAxios.post(
    `${apiUrl}/master-catalog/product-variants`,
    {
      relations: { hasProduct: true, hasDimensions: true, hasSaleChannels: true },
      params,
    },
    { cancelToken: ctx && ctx.token },
  );
  return data.items
    ? { items: data.items, totalCount: data.pagination.totalCount }
    : { items: [], totalCount: 0 };
};

const getSaleChannels = async (): Promise<{ items: Array<SaleChannel>; totalCount: number }> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/sale-channels`);
  return data.items
    ? { items: data.items, totalCount: data.pagination.totalCount }
    : { items: [], totalCount: 0 };
};

const getFields = async (): Promise<Array<FieldColumnType>> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/table-columns`);
  return data.items ?? [];
};

const getCategoryLevels = async (): Promise<Array<CategoryLevel>> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/category-levels/full`);
  return data.items ?? [];
};

const saveCategories = async (request: SaveCategoryRequest): Promise<Array<CategoryLevel>> => {
  const { data } = await customAxios.post(`${apiUrl}/master-catalog/categories`, request);
  return data.items ?? [];
};
const updateCategory = async (request: UpdateCategoryRequest): Promise<Array<CategoryLevel>> => {
  const { data } = await customAxios.put(`${apiUrl}/master-catalog/categories`, request);
  return data.items ?? [];
};

const exportCategories = async (): Promise<ExportCategory[]> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/categories/export`);
  return data.categories ?? [];
};

const updateProducts = async (items: Array<Product>): Promise<Array<Product>> => {
  const { data } = await customAxios.put(`${apiUrl}/master-catalog/products-tbl`, { items });
  return data.items ? data.items : [];
};

const deleteProduct = async (id: string) => {
  await customAxios.delete(`${apiUrl}/master-catalog/products/${id}`);
};

const exportProducts = async (productVariantIds: string[] = []): Promise<string> => {
  const { data } = await customAxios.post(
    `${apiUrl}/master-catalog/products/export`,
    productVariantIds.length && { productVariantIds: productVariantIds },
  );
  return data.content ? data.content : "";
};

const getTableColumnSettings = async (
  type: TableColumnTableType,
): Promise<Array<TableColumnType>> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/table-column-settings/${type}`);
  return data.items ?? [];
};

const saveTableColumnSettings = async (
  type: TableColumnTableType,
  items: Array<TableColumnType>,
): Promise<Array<TableColumnType>> => {
  const { data } = await customAxios.put(`${apiUrl}/master-catalog/table-column-settings/${type}`, {
    items,
  });
  return data.items ?? [];
};

const resetTableColumnSettings = async (
  type: TableColumnTableType,
): Promise<Array<TableColumnType>> => {
  const { data } = await customAxios.put(
    `${apiUrl}/master-catalog/table-column-settings/${type}/reset`,
  );
  return data.items ?? [];
};

const getSuppliers = async (params?: any): Promise<Array<Supplier>> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/suppliers`, {
    params,
  });
  return data.items ?? [];
};

const saveSuppliers = async (request: SaveSupplierRequest): Promise<Array<Supplier>> => {
  const { data } = await customAxios.post(`${apiUrl}/master-catalog/suppliers`, request);
  return data.items ?? [];
};

const updateSupplier = async (request: UpdateSupplierRequest): Promise<Array<Supplier>> => {
  const { data } = await customAxios.put(`${apiUrl}/master-catalog/suppliers`, request);
  return data.items ?? [];
};

const deleteProductVariant = async (id: string) => {
  await customAxios.delete(`${apiUrl}/master-catalog/variants/${id}`);
};

const exportProductActivities = async (): Promise<string> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/product-activities/export`);
  return data.content ? data.content : "";
};

const importProductActivities = async (content: string) => {
  await customAxios.put(`${apiUrl}/master-catalog/product-activities/import`, {
    content,
  });
};

const exportNpl = async (): Promise<string> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/products-npl/export`);
  return data.content ? data.content : "";
};

const importNpl = async (content: string) => {
  await customAxios.put(`${apiUrl}/master-catalog/products-npl/import`, {
    content,
  });
};

const exportMasterCosts = async (): Promise<string> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/product-costs/export`);
  return data.content ? data.content : "";
};

const importMasterCosts = async (content: string) => {
  await customAxios.put(`${apiUrl}/master-catalog/product-costs/import`, {
    content,
  });
};

const getProduct = async (id: number | string): Promise<Product> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/products/${id}`, {
    params: {
      relations: {
        hasVariants: true,
        hasCategoryLevels: true,
        hasDimensions: true,
        hasSaleChannels: true,
        hasBundleVariants: true,
        hasSupplier: true,
        hasCosts: true,
        hasBrief: true,
        hasImages: true,
        hasCollections: true,
      },
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "brackets", allowDots: true });
    },
  });
  return data.item ? data.item : null;
};

const updateProduct = async (item: Product): Promise<Product> => {
  const { data } = await customAxios.put(`${apiUrl}/master-catalog/products/${item.id}`, { item });
  return data.item ? data.item : null;
};

const getProductImages = async (productId: string): Promise<ProductImage[]> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/products/${productId}/images`);
  return data.items ? data.items : "";
};

const getCollections = async (): Promise<{ items: Collection[]; totalCount: number }> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/collections`);
  return { items: data.items ?? [], totalCount: data.pagination.totalCount };
};

const exportPrices = async (): Promise<string> => {
  const { data } = await customAxios.get(`${apiUrl}/master-catalog/product-prices/export`);
  return data.content ? data.content : "";
};

const importPrices = async (content: string) => {
  await customAxios.put(`${apiUrl}/master-catalog/product-prices/import`, {
    content,
  });
};

const getProductIds = async (params: any): Promise<ProductIdsData> => {
  const { data } = await customAxios.post(`${apiUrl}/master-catalog/product-ids`, { params });
  return data;
};

const bulkUpdate = async (type: string, data: ProductsBulkUpdateRequest) => {
  return await customAxios.put(`${apiUrl}/master-catalog/products/${type}/bulk-update`, data);
};

export const catalogService = {
  getProducts,
  getVariants,
  getSaleChannels,
  getFields,
  getCategoryLevels,
  saveCategories,
  updateCategory,
  exportCategories,
  updateProducts,
  deleteProduct,
  exportProducts,
  getTableColumnSettings,
  saveTableColumnSettings,
  resetTableColumnSettings,
  getSuppliers,
  saveSuppliers,
  updateSupplier,
  deleteProductVariant,
  exportProductActivities,
  importProductActivities,
  exportNpl,
  importNpl,
  exportMasterCosts,
  importMasterCosts,
  getProduct,
  updateProduct,
  getProductImages,
  getCollections,
  exportPrices,
  importPrices,
  getProductIds,
  bulkUpdate,
};

import React, { useMemo, useState } from "react";
import classNames from "classnames";
import ClickAwayListener from "../../../helpers/ClickAwayListener";
import { Popover } from "react-tiny-popover";
import styles from "./styles.module.scss";

export type SelectOption = { label: string; value: any; disabled?: boolean };

export type SelectStatusTypes = "empty" | "progress" | "success";

export type CloseSelect = () => void;

export type SelectPropsType = {
  id?: string;
  options: Array<SelectOption>;
  onSelect: (value: any) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  selected?: any;
  fullWidth?: boolean;
  size?: "large" | "medium";
  status?: SelectStatusTypes;
  label?: React.ReactNode;
  parentClassName?: any;
  error?: string;
  optionLabelRenderer?: (option: SelectOption, closeSelect: CloseSelect) => React.ReactNode;
  contentRenderer?: (options: React.ReactElement) => React.ReactElement;
};

const Select = ({
  options,
  placeholder,
  disabled,
  onSelect,
  className,
  selected,
  fullWidth,
  ...props
}: SelectPropsType) => {
  const {
    size = "medium",
    status,
    label,
    parentClassName,
    error,
    id,
    optionLabelRenderer,
    contentRenderer,
  } = props;

  const value: SelectOption | undefined = useMemo(
    () => (selected !== undefined ? options.find(option => option.value === selected) : undefined),
    [selected, options],
  );
  const [active, setActive] = useState<boolean>(false);
  const handleSelect = (option: SelectOption) => {
    setActive(false);
    onSelect(option.value);
  };

  const optionsBlock = (
    <>
      {options.map((option, index) => (
        <div
          className={classNames(styles.select__option, {
            [styles.disabled]: option.disabled,
          })}
          onClick={e => {
            e.stopPropagation();
            handleSelect(option);
          }}
          key={index}
        >
          {optionLabelRenderer ? optionLabelRenderer(option, () => setActive(false)) : option.label}
        </div>
      ))}
    </>
  );

  return (
    <div className={classNames(styles.wrapper, parentClassName, { [styles.fullWidth]: fullWidth })}>
      {label && <label className={styles.select__label}>{label}</label>}
      <div
        className={classNames(
          styles.select,
          {
            [styles.active]: active,
            [styles.disabled]: disabled || (!options.length && !selected),
            [styles.fullWidth]: fullWidth,
            [styles[status!]]: status,
            [styles.error]: error,
          },
          styles[size],
          className,
        )}
        id={id}
      >
        <ClickAwayListener onClickAway={() => setActive(false)}>
          <Popover
            isOpen={active}
            positions={["bottom", "left"]}
            align="start"
            padding={2}
            containerClassName={styles.select__options}
            content={contentRenderer ? contentRenderer(optionsBlock) : optionsBlock}
          >
            <button
              className={styles.select__button}
              disabled={disabled}
              onClick={() => setActive(!active)}
            >
              <span className={styles.select__selectedValue}>
                {value?.label ? value.label : placeholder ? placeholder : "Select"}
              </span>
            </button>
          </Popover>
        </ClickAwayListener>
      </div>
      {error && <p className={styles.select__error}>{error}</p>}
    </div>
  );
};

export default Select;

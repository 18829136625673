import React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

type PropsType = {
  className?: any;
};

const LogoutLink = ({ className }: PropsType) => {
  const history = useHistory();
  const logOut = (e: React.MouseEvent) => {
    e.preventDefault();
    localStorage.removeItem("user");
    history.push("/login");
  };
  return (
    <a
      className={classNames(styles.header__link, { [className]: !!className })}
      onClick={logOut}
      href="#"
    >
      Logout
    </a>
  );
};

export default LogoutLink;

import React from "react";
import "react-slidedown/lib/slidedown.css";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import CheckboxArray, { CheckboxOption } from "components/common/CheckboxArray";
import classNames from "classnames";

type PropsType = {
  params: FilterComponentProps;
  options: CheckboxOption[];
  title: string;
  paramName: string;
  className?: string;
  contentClassName?: string;
  checkboxClassName?: string;
};

const MultiOptionsFilter = ({ params, options, title, ...props }: PropsType) => {
  const { paramName, className, contentClassName, checkboxClassName } = props;

  return (
    <div className={classNames(styles.filter, className)}>
      <p className={styles.title}>{title}</p>
      <CheckboxArray
        selected={params.getValue(paramName) ?? []}
        options={options}
        onChange={selected => params.handleChange(paramName, selected)}
        className={classNames(styles.checkboxes, contentClassName)}
        checkboxClassName={classNames(styles.checkbox, checkboxClassName)}
      />
    </div>
  );
};

export default MultiOptionsFilter;

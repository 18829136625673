import React from "react";
import { CategoryLevel } from "types/categoryLevelTypes";
import { Product, Supplier } from "types/catalogTypes";
import { ErrorType } from "types/errorsTypes";
import rowStyles from "../../styles.module.scss";
import { getFieldWidth, getLineFieldByName } from "../../helpers/fields";
import ActionList from "components/common/ActionList";
import { ReactComponent as ActionsIcon } from "assets/icons/shared/actionsIcon.svg";
import LineField from "../LineField";
import classNames from "classnames";
import { TableColumnType } from "types/tableColumnType";
import { getFieldError } from "../../helpers/errors";
import CheckBox from "../../../../common/CheckBox";

type PropsType = {
  disable: boolean;
  showChildren: boolean;
  fields: Array<TableColumnType>;
  productFormFields: any;
  variantIndex: number;
  categoryLevels: Array<CategoryLevel>;
  product: Product;
  errors: ErrorType | null | undefined;
  handleDeleteVariant: (index: number, product: Product) => void;
  handleChangeProductValue: (product: Product) => void;
  suppliers: Supplier[];
  limitedMode?: boolean;
  withCheckbox?: boolean;
  selected: boolean;
  onSelect: () => void;
  checkboxDisabled?: boolean;
};

const VariantFormLine = ({
  disable,
  showChildren,
  fields,
  productFormFields,
  variantIndex,
  categoryLevels,
  product,
  ...props
}: PropsType) => {
  const {
    errors,
    handleDeleteVariant,
    handleChangeProductValue,
    suppliers,
    limitedMode,
    withCheckbox,
    selected,
    onSelect,
    checkboxDisabled,
  } = props;

  return (
    <div
      className={classNames(
        rowStyles.table__row,
        { [rowStyles.disable]: disable },
        { [rowStyles.active]: showChildren },
      )}
    >
      <div
        className={classNames(rowStyles.table__item, rowStyles.actions, {
          [rowStyles.big]: withCheckbox,
        })}
      >
        {!limitedMode && (
          <ActionList
            className={rowStyles.actions__list}
            options={[
              {
                text: "Delete",
                onAction: () => handleDeleteVariant(variantIndex, product),
                disable: product.variants.length <= 2,
              },
            ]}
          >
            <ActionsIcon className={rowStyles.icon} />
          </ActionList>
        )}

        {withCheckbox && (
          <CheckBox
            onChange={onSelect}
            selected={selected}
            value={product.variants[variantIndex].defaultVariant.id}
            className={rowStyles.checkbox}
            disabled={checkboxDisabled}
          />
        )}
      </div>

      {fields.map((field, idx) => (
        <div
          key={idx}
          style={{ width: getFieldWidth(field.name) }}
          className={classNames(
            rowStyles.table__item,
            withCheckbox && idx === 0 && rowStyles.leftOffset,
            field.alignment === "center" && rowStyles.alignCenter,
            field.alignment === "right" && rowStyles.alignRight,
          )}
        >
          {productFormFields.data.variants[variantIndex]?.hasOwnProperty(field.name) && (
            <LineField
              lineField={getLineFieldByName(productFormFields.data, field, variantIndex, "variant")}
              categoryLevels={categoryLevels}
              lineFieldName={field.name}
              product={product}
              setProduct={product => handleChangeProductValue(product)}
              error={
                errors
                  ? getFieldError(
                      errors.productErrors,
                      getLineFieldByName(productFormFields.data, field, variantIndex, "variant"),
                    )
                  : ""
              }
              suppliers={suppliers}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default React.memo(VariantFormLine, (p: PropsType, n: PropsType): boolean => {
  return (
    JSON.stringify(p.product) === JSON.stringify(n.product) &&
    p.errors === n.errors &&
    p.variantIndex === n.variantIndex &&
    p.selected === n.selected &&
    p.onSelect === n.onSelect &&
    p.checkboxDisabled === n.checkboxDisabled
  );
});

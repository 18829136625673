import { mergeWith } from "lodash";

const keyToObject = (key: string, value: any) => {
  const tempObject = {};
  let container: any = tempObject;
  const keyParts = key.split(".");
  const lastIndex = keyParts.length - 1;

  keyParts.map((keyPart, index) => {
    container[keyPart] = index === lastIndex ? value : {};
    container = container[keyPart];
  });

  return tempObject;
};

export const normalizeFilterParams = (filterParams: any) => {
  let newFilterParams: any = {};

  Object.keys(filterParams).map(key => {
    const splits = key.split(".");

    if (splits.length > 1) {
      newFilterParams = mergeWith(newFilterParams, keyToObject(key, filterParams[key]));
    } else {
      newFilterParams[key] = filterParams[key];
    }
  });

  return newFilterParams;
};

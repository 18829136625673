import React from "react";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import CheckboxArray from "components/common/CheckboxArray";
import Radio from "../../../common/Radio";
import { SaleChannel } from "types/saleChannelsTypes";

type PropsType = {
  params: FilterComponentProps;
  salesChannels: SaleChannel[];
};

const channelsParamName = "activity.saleChannelIds";
const valueParamName = "activity.value";

const ActivityFilter = ({ params, salesChannels }: PropsType) => {
  const onRadioChange = (value: string) => {
    params.handleChange(valueParamName, value);
  };

  const radioValue = params.getValue(valueParamName) ?? "";

  return (
    <div className={styles.container}>
      <p className={styles.title}>Activity</p>
      <div className={styles.wrapper}>
        <Radio
          className={styles.radio}
          onChange={onRadioChange}
          label="All"
          checked={radioValue == ""}
          value=""
          name="all"
        />
        <Radio
          className={styles.radio}
          onChange={onRadioChange}
          label="Active"
          checked={radioValue === "active"}
          value="active"
          name="active"
        />
        <Radio
          className={styles.radio}
          onChange={onRadioChange}
          label="Not active"
          checked={radioValue === "inactive"}
          value="inactive"
          name="inactive"
        />
      </div>
      {(radioValue === "active" || radioValue === "inactive") && (
        <CheckboxArray
          options={salesChannels.map(channel => ({
            value: channel.id,
            label: channel.title,
            disabled: channel.type === "сhannelAdvisor",
          }))}
          selected={params.getValue(channelsParamName) ?? []}
          className={styles.checkboxes}
          onChange={values => params.handleChange(channelsParamName, values)}
          checkboxClassName={styles.checkbox}
        />
      )}
    </div>
  );
};

export default ActivityFilter;

import { CategoryLevel } from "./categoryLevelTypes";
import { SaleChannel } from "./saleChannelsTypes";

export type Product = {
  id: string;
  type: string;
  title: string;
  description: string;
  categoryLevelId1: string;
  categoryLevelId2: string;
  categoryLevelId3: string;
  comments: string;
  categoryLevel1: CategoryLevel;
  categoryLevel2: CategoryLevel;
  categoryLevel3: CategoryLevel;
  defaultVariant: DefaultVariant | null;
  variants: Array<ProductVariant>;
  saleChannels: Array<ProductSaleChannel>;
  bundleVariants: Array<ProductVariant>;
  bundleVariantIds: Array<string>;
  brief: any;
};

export type Supplier = {
  id: number;
  key: string;
  name: string;
};

export type DefaultVariant = {
  id: string;
  productId: string;
  sku: string;
  asin: string;
  upc: string;
  holdNA: boolean;
  holdEU: boolean;
  holdUK: boolean;
  itemFulfillmentType: string;
  replacementSku: string;
  firstSaleDate: string;
  moq: string;
  htsUS: string;
  unitsCarton: number;
  oversized: boolean;
  msrp: number;
  asp: number;
  map: number;
  newOld: string;
  supplier: Supplier;
  supplierId: number;
  discontinuedReason: string;
  productionLeadTime: number;
  paymentTerms: string;
  discontinuedNA: string;
  discontinuedEU: string;
  fbaStatusMX: string;
  fbaStatusCA: string;
  rfCA: string;
  rfMX: string;
  costTrans: number;
  inventory: DefaultVariantInventory;
  gpw: number;
  ckw: number;
  publishDateUS: string;
  publishDateUK: string;
  removeFromNewArrivalsDateUS: string;
  removeFromNewArrivalsDateUK: string;
  removedUS: boolean;
  removedUK: boolean;
  dimensions: Dimensions;
  saleChannels: Array<VariantSaleChannel>;
  costs: DefaultVariantCosts;
};

type DefaultVariantInventory = {
  invAmzUS: number;
  invAmzCA: number;
  invAmzMX: number;
  invAmzEU: number;
  invAmzUK: number;
  invDeliverr: number;
  invCloudfulfillment: number;
};

type DefaultVariantCosts = {
  landedCostNsUS: number;
  costTrans: number;
  fees: number;
  unitCostUS: number;
  unitCostUK: number;
  unitCostEU: number;
  taxRateUS: number;
  taxRateUK: number;
  taxRateEU: number;
  customDutiesUS: number;
  customDutiesUK: number;
  customDutiesEU: number;
  dutiesUnitUS: number;
  dutiesUnitUK: number;
  dutiesUnitEU: number;
  internationalUnitUS: number;
  internationalUnitUK: number;
  internationalUnitEU: number;
  domesticUnitUS: number;
  domesticUnitUK: number;
  domesticUnitEU: number;
  totalTransUS: number;
  totalTransUK: number;
  totalTransEU: number;
  totalItemCostUS: number;
  totalItemCostUK: number;
  totalItemCostEU: number;
};

export type ProductVariant = {
  defaultVariant: DefaultVariant;
  title: string;
  fullTitle: string;
  comments: string;
  product?: Product;
};

export type ProductSaleChannel = {
  saleChannelId: string;
  isActive: boolean;
  productId: string;
  title: string;
  shortTitle: string;
  description: string;
  seoTitle: string;
  seoDescription: string;
  seoKeywords: string;
  handle: string;
  isCollectionActive: boolean;
  isSearchActive: boolean;
  images: any[];
  primaryCollectionId: string | null;
  collections: Collection[];
  collectionIds: string[];
  primaryCollection: PrimaryCollection | null;
};

export type VariantSaleChannel = {
  productVariantId: string;
  saleChannelId: string;
  fba: number;
  isActive: boolean;
  title: string;
  imageFileId: string | null;
  barcode: string;
  taxable: boolean;
  taxCode: string;
  requiresShipping: boolean;
  weight: number;
  weightUnit: string;
  autoshipAvailable: boolean;
  image: any;
  fee: number;
  price: number;
  compareAtPrice: number;
  competitivePrice: number;
  recommendedPrice: number;
  recommendedCompareAtPrice: number;
  marginPrice: number;
  marginCompareAtPrice: number;
  marginRecommendedPrice: number;
  marginRecommendedCompareAtPrice: number;
};

export const InitialVariantSaleChannel: VariantSaleChannel = {
  productVariantId: "",
  saleChannelId: "",
  price: 0,
  compareAtPrice: 0,
  fba: 0,
  isActive: false,
  title: "",
  imageFileId: null,
  barcode: "",
  taxable: false,
  taxCode: "",
  requiresShipping: false,
  weight: 0,
  weightUnit: "",
  autoshipAvailable: false,
  image: null,
  competitivePrice: 0,
  fee: 0,
  recommendedPrice: 0,
  recommendedCompareAtPrice: 0,
  marginPrice: 0,
  marginCompareAtPrice: 0,
  marginRecommendedPrice: 0,
  marginRecommendedCompareAtPrice: 0,
};

export type Dimensions = {
  cartonHeight: number;
  cartonLength: number;
  cartonWeight: number;
  cartonWidth: number;
  eachHeight: number;
  eachLength: number;
  eachWeight: number;
  eachWidth: number;
  productVariantId: string;
  unitsInner: number;
};

export const InitialDefaultVariant = {
  id: "0",
  productId: "0",
  sku: "",
  asin: "",
  upc: "",
  holdNA: false,
  holdUK: false,
  holdEU: false,
  itemFulfillmentType: "",
  replacementSku: "",
  firstSaleDate: "",
  moq: "0",
  htsUS: "",
  unitsCarton: 0,
  oversized: false,
  msrp: 0,
  asp: 0,
  map: 0,
  newOld: "",
  discontinuedReason: "",
  supplierId: 0,
  supplier: {
    id: 0,
    key: "",
    name: "",
  },
  productionLeadTime: 0,
  paymentTerms: "",
  discontinuedNA: "",
  discontinuedEU: "",
  fbaStatusMX: "",
  fbaStatusCA: "",
  rfCA: "",
  rfMX: "",
  costTrans: 0,
  inventory: {
    invAmzUS: 0,
    invAmzCA: 0,
    invAmzMX: 0,
    invAmzEU: 0,
    invAmzUK: 0,
    invDeliverr: 0,
    invCloudfulfillment: 0,
  },
  gpw: 0,
  ckw: 0,
  publishDateUS: "",
  publishDateUK: "",
  removeFromNewArrivalsDateUS: "",
  removeFromNewArrivalsDateUK: "",
  removedUS: false,
  removedUK: false,
  dimensions: {
    cartonHeight: 0,
    cartonLength: 0,
    cartonWeight: 0,
    cartonWidth: 0,
    eachHeight: 0,
    eachLength: 0,
    eachWeight: 0,
    eachWidth: 0,
    productVariantId: "0",
    unitsInner: 0,
  },
  saleChannels: [],
  costs: {
    landedCostNsUS: 0,
    costTrans: 0,
    fees: 0,
    unitCostUS: 0,
    unitCostUK: 0,
    unitCostEU: 0,
    totalTransUS: 0,
    totalTransUK: 0,
    totalTransEU: 0,
    taxRateUS: 0,
    taxRateUK: 0,
    taxRateEU: 0,
    customDutiesUS: 0,
    customDutiesUK: 0,
    customDutiesEU: 0,
    internationalUnitUS: 0,
    internationalUnitUK: 0,
    internationalUnitEU: 0,
    domesticUnitUS: 0,
    domesticUnitUK: 0,
    domesticUnitEU: 0,
    dutiesUnitUS: 0,
    dutiesUnitUK: 0,
    dutiesUnitEU: 0,
    totalItemCostUS: 0,
    totalItemCostUK: 0,
    totalItemCostEU: 0,
  },
};

export const InitialVariant = {
  defaultVariant: InitialDefaultVariant,
  title: "",
  fullTitle: "",
  comments: "",
};

export const InitialProduct = {
  id: "0",
  type: "simple",
  title: "",
  description: "",
  categoryLevelId1: "0",
  categoryLevelId2: "0",
  categoryLevelId3: "0",
  comments: "",
  categoryLevel1: { id: "0", title: "" },
  categoryLevel2: { id: "0", title: "" },
  categoryLevel3: { id: "0", title: "" },
  defaultVariant: InitialDefaultVariant,
  variants: [],
  saleChannels: [],
  bundleVariants: [],
  bundleVariantIds: [],
  brief: null,
};

export const InitialSaleChannel: ProductSaleChannel = {
  saleChannelId: "",
  productId: "",
  isActive: false,
  title: "",
  shortTitle: "",
  description: "",
  seoTitle: "",
  seoDescription: "",
  seoKeywords: "",
  handle: "",
  isCollectionActive: false,
  isSearchActive: false,
  images: [],
  primaryCollectionId: null,
  collections: [],
  collectionIds: [],
  primaryCollection: null,
};

export interface ProductImage {
  id: number;
  position: number;
  src: string;
}

export interface UpdateProductImage {
  position: number;
  content: string;
  originalName: string;
}

export const getInitialProduct = (): Product => ({
  ...InitialProduct,
  saleChannels: [],
  defaultVariant: {
    ...InitialDefaultVariant,
    saleChannels: [],
  },
});

export const getInitialProductVariant = (): ProductVariant => ({
  ...InitialVariant,
  defaultVariant: {
    ...InitialVariant.defaultVariant,
    saleChannels: [],
  },
});

export const getInitialProductWithSaleChannels = (saleChannels: Array<SaleChannel>): Product => ({
  ...InitialProduct,
  saleChannels: saleChannels.map(sale => ({
    ...InitialSaleChannel,
    saleChannelId: sale.id,
  })),
  defaultVariant: {
    ...InitialDefaultVariant,
    saleChannels: saleChannels.map(sale => ({
      ...InitialVariantSaleChannel,
      saleChannelId: sale.id,
    })),
  },
});

export const getInitialProductVariantWithSaleChannels = (
  saleChannels: Array<SaleChannel>,
): ProductVariant => ({
  ...InitialVariant,
  defaultVariant: {
    ...InitialVariant.defaultVariant,
    saleChannels: saleChannels.map(sale => ({
      ...InitialVariantSaleChannel,
      saleChannelId: sale.id,
    })),
  },
});

export type Collection = {
  id: string;
  title: string;
  isActive: boolean;
};

export type PrimaryCollection = {
  id: string;
  title: string;
};

export type ProductsBulkActionType =
  | "activate"
  | "deactivate"
  | "itemFulfillmentType"
  | "categories";

export type ProductsBulkUpdateRequest = {
  categories?: {
    productIds: string[];
    categoryLevel1: string;
    categoryLevel2: string;
    categoryLevel3: string;
  };
  itemFulfillmentType?: {
    productVariantIds: string[];
    itemFulfillmentType: string;
  };
  saleChannelActivity?: {
    productVariantIds: string[];
    saleChannelIds: string[];
    isActive: boolean;
  };
};

export type ProductIdData = {
  productId: string;
  productVariantIds: string[];
};

export type ProductIdsData = {
  items: ProductIdData[];
  totalCountProducts: number;
  totalCountProductVariants: number;
};

export const getInitialProductIdsData = (): ProductIdsData => {
  return {
    items: [],
    totalCountProducts: 0,
    totalCountProductVariants: 0,
  };
};

import React, { useState } from "react";
import styles from "./styles.module.scss";
import Modal from "components/common/Modal";
import Select, { SelectOption } from "components/common/Select";

type PropsType = {
  active: boolean;
  setActive: (active: boolean) => void;
  onSubmit: (type: string) => void;
};

const typeOptions: SelectOption[] = [
  { label: "Simple product", value: "simple" },
  { label: "Group of products", value: "simpleWithVariants" },
  { label: "Bundle", value: "bundle" },
  { label: "Gift Card", value: "giftCard" },
];

const CreateProductModal = ({ active, setActive, onSubmit }: PropsType) => {
  const [selectedType, setSelectedType] = useState("simple");

  return (
    <Modal
      title="Create Product"
      active={active}
      setActive={setActive}
      size="small"
      onSubmit={() => onSubmit(selectedType)}
      saveButtonText="Create Product"
      disabled={!selectedType}
    >
      <div className={styles.modal}>
        <div className={styles.wrapper}>
          <span className={styles.wrapper__title}>Product Type</span>
          <div className={styles.wrapper__content}>
            <div className={styles.wrapper__select}>
              <span className={styles.wrapper__label}>Select</span>
              <Select
                options={typeOptions}
                onSelect={setSelectedType}
                selected={selectedType}
                size="large"
                fullWidth
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CreateProductModal;

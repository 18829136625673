import Collapse from "../../../common/Collapse";
import { FilterComponentProps } from "../../../common/SearchFiltersPanel/components/FiltersPanel/types";
import InventoryFilter from "../InventoryFilter";
import styles from "./styles.module.scss";

type PropsType = {
  params: FilterComponentProps;
};

const InventorySetFilters = ({ params }: PropsType) => {
  return (
    <Collapse title="Inventory">
      <div className={styles.wrapper}>
        <InventoryFilter params={params} />
      </div>
    </Collapse>
  );
};

export default InventorySetFilters;

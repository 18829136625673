import React, { useState } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "components/common/DatePicker";
import styles from "../../styles.module.scss";
import { LineFieldType } from "../../../../types";

type PropsType = {
  lineField: LineFieldType;
  value: any;
  onChange: (value: any) => void;
};

const CatalogDatepicker = ({ lineField, onChange, value }: PropsType) => {
  const [active, setActive] = useState<boolean>(false);

  const handleChangeDatePicker = (value: any) => {
    onChange(value);
    setActive(false);
  };

  return active ? (
    <DatePicker
      selected={value ? new Date(value) : new Date()}
      onChange={date => handleChangeDatePicker(date)}
      autoFocus={true}
      onBlur={() => setActive(false)}
    />
  ) : (
    <button
      className={styles.textValue}
      onDoubleClick={() => setActive(true)}
      disabled={lineField.disabled || lineField.isReadOnly}
    >
      <p className={styles.textValue__text} title={value}>
        {value ? moment(value).format("MM/DD/YYYY") : "Select date"}
      </p>
    </button>
  );
};

export default CatalogDatepicker;

import React, { useMemo } from "react";
import classNames from "classnames";
import styles from "../CheckboxArray/styles.module.scss";

type PropsType = {
  onChange: (value: any) => void;
  selected: boolean;
  value: any;
  label?: string;
  marginOff?: boolean;
  disabled?: boolean;
  className?: string;
  indeterminate?: boolean;
};

const CheckBox = ({ onChange, selected, value, label, marginOff, ...props }: PropsType) => {
  const { disabled, className, indeterminate } = props;
  const active = useMemo(() => selected, [selected]);

  return (
    <button
      onClick={() => onChange(value)}
      className={classNames(
        styles.checkbox,
        { [styles.active]: active },
        { [styles.marginOff]: marginOff },
        { [styles.disabled]: disabled },
        className,
      )}
    >
      <span
        className={classNames(styles.checkbox__field, { [styles.indeterminate]: indeterminate })}
      />
      {label && <span className={styles.checkbox__text}>{label}</span>}
    </button>
  );
};

export default CheckBox;

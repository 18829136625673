import React, { useMemo } from "react";
import { FilterComponentProps } from "components/common/SearchFiltersPanel/components/FiltersPanel/types";
import styles from "./styles.module.scss";
import Radio from "components/common/Radio";
import CheckboxArray from "components/common/CheckboxArray";

type PropsType = {
  params: FilterComponentProps;
};

const regionOptions = [
  { label: "NA", value: "discontinuedNA" },
  { label: "EU", value: "discontinuedEU" },
];

const DiscontinuedFilter = ({ params }: PropsType) => {
  const radioValue = useMemo(() => {
    if (params.getValue("discontinuedNA")) {
      return params.getValue("discontinuedNA");
    } else if (params.getValue("discontinuedEU")) {
      return params.getValue("discontinuedEU");
    }
    return "";
  }, [params.getValue("discontinuedNA"), params.getValue("discontinuedEU")]);

  const selectedRegions = useMemo(() => {
    const regions = [];
    if (params.getValue("discontinuedNA")) {
      regions.push("discontinuedNA");
    }
    if (params.getValue("discontinuedEU")) {
      regions.push("discontinuedEU");
    }
    return regions;
  }, [params.getValue("discontinuedNA"), params.getValue("discontinuedEU")]);

  const onRadioChange = (value: any) => {
    params.handleChange("discontinuedNA", value);
    params.handleChange("discontinuedEU", value);
  };

  const onRegionsChange = (value: any[]) => {
    regionOptions.forEach(option => {
      if (value.includes(option.value)) {
        params.handleChange(option.value, radioValue);
      } else {
        params.handleChange(option.value, null);
      }
    });
  };

  return (
    <div className={styles.filter}>
      <p className={styles.filter__title}>Discontinued</p>
      <div className={styles.filter__wrapper}>
        <Radio
          className={styles.filter__radio}
          onChange={onRadioChange}
          label="All"
          checked={radioValue === ""}
          value=""
          name="all"
        />
        <Radio
          className={styles.filter__radio}
          onChange={onRadioChange}
          label="No"
          checked={radioValue === "N"}
          value="N"
          name="N"
        />
        <Radio
          className={styles.filter__radio}
          onChange={onRadioChange}
          label="Yes"
          checked={radioValue === "Y"}
          value="Y"
          name="Y"
        />
        <Radio
          className={styles.filter__radio}
          onChange={onRadioChange}
          label="Temporary"
          checked={radioValue === "T"}
          value="T"
          name="T"
        />
      </div>

      {(radioValue == "N" || radioValue == "Y" || radioValue == "T") && (
        <CheckboxArray
          options={regionOptions}
          selected={selectedRegions}
          className={styles.filter__checkboxes}
          onChange={onRegionsChange}
          checkboxClassName={styles.filter__checkbox}
        />
      )}
    </div>
  );
};

export default DiscontinuedFilter;

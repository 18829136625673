import React, { forwardRef, Ref } from "react";
import classNames from "classnames";
import { ReactComponent as Spinner } from "../../../assets/icons/shared/buttonSpinner.svg";
import styles from "./styles.module.scss";

type PropsType = {
  children?: React.ReactNode;
  disabled?: boolean;
  onClick: (e: any) => void;
  loading?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
  size?: "large" | "medium";
  className?: string;
  type?: "primary" | "transparent" | "default" | "link";
};

const Button = forwardRef((props: PropsType, ref: Ref<HTMLButtonElement>) => {
  const {
    children,
    disabled = false,
    onClick,
    loading,
    icon,
    size = "large",
    className,
    type = "default",
    iconPosition = "left",
  } = props;
  const getContent = () => {
    if (loading) {
      return <Spinner className={classNames(styles.spinner, { [styles[type]]: type })} />;
    }

    return (
      <>
        {iconPosition === "left" && icon}
        {children}
        {iconPosition === "right" && icon}
      </>
    );
  };

  return (
    <button
      ref={ref}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        styles.button,
        styles[type],
        { [styles.disabled]: disabled },
        styles[size],
        className,
      )}
    >
      {getContent()}
    </button>
  );
});

export default Button;

import React, { useState } from "react";
import { get, set, cloneDeep } from "lodash";
import { LineFieldType } from "../../types";
import { Supplier, Product } from "types/catalogTypes";
import { SelectOption } from "components/common/Select";
import { CategoryLevel } from "types/categoryLevelTypes";
import {
  cooOptions,
  discontinuedEuOptions,
  discontinuedNaOptions,
  itemFulfillmentTypeOptions,
  newOldOptions,
  oversizedOptions,
} from "helpers/predefinedOptions";
import Field from "./components/Field";

type PropsType = {
  categoryLevels: Array<CategoryLevel>;
  lineField: LineFieldType | undefined;
  product: Product;
  setProduct: (product: Product) => void;
  lineFieldName: string;
  error: string | undefined;
  productForCategoryLevels?: Product;
  suppliers: Supplier[];
};

const LineField: React.FC<PropsType> = ({
  lineField,
  categoryLevels,
  lineFieldName,
  product,
  setProduct,
  error,
  ...props
}) => {
  const { productForCategoryLevels, suppliers } = props;

  if (lineField === undefined || !lineField.formName) {
    return null;
  }

  const [active, setActive] = useState<boolean>(false);

  const onChange = (value: any, path: string) => {
    const newObj = cloneDeep(product);
    set(newObj, path, value);
    if (path === "categoryLevelId1") {
      set(newObj, "categoryLevelId2", "0");
      set(newObj, "categoryLevelId3", "0");
    } else if (path === "categoryLevelId2") {
      set(newObj, "categoryLevelId3", "0");
    }
    setProduct(newObj);
  };

  const getSelectOptionByFieldName = (name: string): Array<SelectOption> => {
    const options = {
      discontinuedEU: discontinuedEuOptions,
      discontinuedNA: discontinuedNaOptions,
      newOld: newOldOptions,
      oversized: oversizedOptions,
      supplierName: [
        { label: "Select", value: "0" },
        ...suppliers.map(supplier => {
          return { label: supplier.name, value: supplier.id };
        }),
      ],
      itemFulfillmentType: itemFulfillmentTypeOptions,
      coo: cooOptions,
    };
    return options[name as keyof typeof options];
  };

  return (
    <Field
      lineField={lineField}
      value={get(product, lineField.formName)}
      onChange={(value, formName) =>
        onChange(lineField.type === "number" ? Number(value) : value, formName)
      }
      lineFieldName={lineFieldName}
      categoryLevels={categoryLevels}
      product={product}
      active={active}
      setActive={setActive}
      error={error}
      fieldOptions={getSelectOptionByFieldName(lineFieldName)}
      productForCategoryLevels={productForCategoryLevels}
    />
  );
};
export default LineField;

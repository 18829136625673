import React from "react";
import styles from "./styles.module.scss";
import ActionList, { ActionListOption } from "../ActionList";
import Button from "../Button";
import { ReactComponent as ArrowIcon } from "assets/icons/shared/arrowDown.svg";

type PropsType = {
  selectedCount: number;
  totalCount: number;
  onSelectAll: () => void;
  onCancel: () => void;
  bulkOptions: ActionListOption[];
  actions?: React.ReactElement;
};

const TableActionsPanel = ({ selectedCount, totalCount, ...props }: PropsType) => {
  const { onSelectAll, onCancel, bulkOptions, actions } = props;

  return (
    <div className={styles.container}>
      <div className={styles.actions}>
        <div className={styles.selected}>{selectedCount} selected</div>
        <Button onClick={onSelectAll} type="link">
          select all {totalCount} SKUs
        </Button>
        {bulkOptions && (
          <ActionList options={bulkOptions} className={styles.bulkActions}>
            {(renderProps: any) => (
              <Button
                {...renderProps}
                className={styles.button}
                icon={<ArrowIcon />}
                iconPosition="right"
              >
                Actions for selected
              </Button>
            )}
          </ActionList>
        )}
        {actions}

        <Button onClick={onCancel} type="link">
          clear selection
        </Button>
      </div>
    </div>
  );
};

export default TableActionsPanel;
